import { Box, BoxProps, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { NoData } from "../../svg";

interface Props extends BoxProps {
  label?: string;
}
const NotFoundBox = (props: Props) => {
  const { label, sx, last, ...rest } = props as any;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 240,
        flex: 1,
        gap: 2,
        ...sx,
      }}
      {...rest}
    >
      <NoData />
      <Typography color={"text.secondary"}>
        <FormattedMessage id={label || "data_not_found"} />
      </Typography>
    </Box>
  );
};
export default NotFoundBox;
