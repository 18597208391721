import {
  CameraAlt,
  EmojiEmotionsOutlined,
  InsertDriveFile,
  MicOutlined,
  OpenInNew,
  PhoneRounded,
  PhotoSizeSelectActualOutlined,
  PlayCircle,
  WhatsApp,
} from "@mui/icons-material";
import { Box, BoxProps, Paper, Typography } from "@mui/material";
import { amber, green } from "@mui/material/colors";
import shadows from "@mui/material/styles/shadows";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import ImageDialog from "../../../../common/components/ImageDialog";
import MyLink from "../../../../common/components/MyLink";
import { getWhatsappText } from "../../../../common/components/elements";
import { PARAMS_TEMPLATE_REGEX } from "../../../../config/regex";
import { AppState } from "../../../../redux/store";
import { ListLanguageMSB, SchemaFormTemplate } from "../utils";

interface Props {
  data: SchemaFormTemplate;
  shortMode?: boolean;
  sx?: BoxProps["sx"];
}
const PreviewTemplateBox = (props: Props) => {
  const { data, shortMode, sx } = props;
  const { leadDetail } = useSelector(
    (state: AppState) => state.lead,
    shallowEqual
  );
  const generateMessage = (value) => {
    if (data?.message) {
      let tmp = value || "";
      let match;
      while ((match = PARAMS_TEMPLATE_REGEX.exec(tmp))) {
        let text = "";
        switch (match[0]) {
          case "{{name}}":
            text = leadDetail?.name;
            break;
          case "{{email}}":
            text = leadDetail?.email;
            break;
          case "{{mobile_number}}":
            text = leadDetail?.mobile_number;
            break;
          case "{{address}}":
            text = leadDetail?.address;
            break;
          default:
            text = "";
            break;
        }
        if (text) {
          tmp =
            tmp?.slice(0, match.index) +
            text +
            tmp?.slice(PARAMS_TEMPLATE_REGEX.lastIndex);
        }
      }
      return tmp;
    }
    return null;
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        width: "100%",
        minHeight: shortMode ? "auto" : 620,
        bgcolor: amber[50],
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      {!shortMode && (
        <Box
          sx={{
            bgcolor: green[400],
            color: "common.white",
            display: "flex",
            gap: 1,
            p: 2,
            alignItems: "center",
          }}
        >
          <WhatsApp />
          <Typography color="inherit" variant="h5">
            <FormattedMessage id="whatsapp" />
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          p: 2,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "80%",
            flex: 1,
          }}
        >
          <Box
            sx={{
              borderRadius: 1,
              bgcolor: "info.main",
              px: 1,
              width: "fit-content",
              color: "white",
            }}
          >
            {ListLanguageMSB.find((item) => item.id === data?.language)?.name}
          </Box>
          <Paper
            sx={{
              px: 2,
              py: 1,
              mt: 2,
              bgcolor: "common.white",
              color: "common.black",
            }}
          >
            {data?.headerType === "TEXT" ? (
              <Typography color="inherit" variant="h6">
                {generateMessage(data?.header)}
              </Typography>
            ) : data?.headerType === "IMAGE" ? (
              <ImageDialog
                src={data.header_url}
                variant="rounded"
                sx={{ height: 160, width: "100%" }}
                className=" my-2 text-white flex items-center justify-center rounded-lg"
              >
                <PhotoSizeSelectActualOutlined style={{ fontSize: 80 }} />
              </ImageDialog>
            ) : data?.headerType === "DOCUMENT" ? (
              <MyLink
                href={data.header_url}
                target="_blank"
                rel="noreferrer noopener"
              >
                <ImageDialog
                  variant="rounded"
                  sx={{ height: 160, width: "100%" }}
                  className=" my-2 text-white flex items-center justify-center rounded-lg"
                >
                  <InsertDriveFile style={{ fontSize: 80 }} />
                </ImageDialog>
              </MyLink>
            ) : data?.headerType === "VIDEO" ? (
              <MyLink
                href={data.header_url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {data.header_url ? (
                  <video controls style={{ height: 160, width: "100%" }}>
                    <source src={data.header_url} type="video/mp4" />
                  </video>
                ) : (
                  <ImageDialog
                    variant="rounded"
                    sx={{ height: 160, width: "100%" }}
                    className=" my-2 text-white flex items-center justify-center rounded-lg"
                  >
                    <PlayCircle style={{ fontSize: 80 }} />
                  </ImageDialog>
                )}
              </MyLink>
            ) : null}
            <Typography
              color="inherit"
              variant="body1"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-all",
              }}
            >
              {data?.message ? (
                getWhatsappText(generateMessage(data?.message))
              ) : (
                <FormattedMessage id="template.messageLabel" />
              )}
            </Typography>
            {data?.footer && (
              <Typography variant="body2" color={"textSecondary"}>
                {getWhatsappText(data?.footer)}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                textAlign: "end",
              }}
              color="textSecondary"
            >
              <FormattedMessage id="now" />
            </Typography>
          </Paper>
          {data?.buttonType === "QUICK_REPLY" ? (
            <>
              {data?.quickReply
                ?.filter((item) => item.text)
                ?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        bgcolor: "common.white",
                        color: "info.main",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 2,
                        py: 1,
                        boxShadow: shadows[1],
                        mt: 1,
                      }}
                    >
                      {item.text}&nbsp;
                    </Box>
                  );
                })}
            </>
          ) : data?.buttonType === "ACTION" ? (
            <>
              {data?.btnCallToAction
                ?.filter((item) => item.text)
                ?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        bgcolor: "common.white",
                        color: "info.main",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 2,
                        py: 1,
                        boxShadow: shadows[1],
                        mt: 1,
                      }}
                    >
                      {item.type === "URL" ? <OpenInNew /> : <PhoneRounded />}
                      &emsp;
                      {item.text}
                    </Box>
                  );
                })}
            </>
          ) : null}
        </Box>
        {!shortMode && (
          <Box display={"flex"} alignItems="center" gap={2} mt={4}>
            <Box
              sx={{
                bgcolor: "common.white",
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                p: 1,
                boxShadow: shadows[1],
                width: "80%",
                gap: 2,
                color: "text.secondary",
              }}
            >
              <EmojiEmotionsOutlined />
              <Typography color={"inherit"} flex={1}>
                <FormattedMessage id="template.typeMessage" />
              </Typography>
              <CameraAlt />
            </Box>
            <Box
              sx={{
                height: 40,
                width: 40,
                borderRadius: 40,
                bgcolor: green[400],
                color: "common.white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MicOutlined />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default PreviewTemplateBox;
