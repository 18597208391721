import { Box } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { EmailForm, setEmailForm } from "../../../../../redux/leadReducer";
import { AppState } from "../../../../../redux/store";
import CreateEmailCard from "./CreateEmailCard";

interface Props {}
const PopperSendEmailBox = (props: Props) => {
  const { dispatch } = useGeneralHook();

  const { createEmail } = useSelector(
    (state: AppState) => state.lead,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      dispatch(setEmailForm([]));
    };
  }, [dispatch]);

  return (
    <>
      <Box
        className="fixed bottom-0 flex gap-4 items-end"
        sx={{ right: 16 }}
        zIndex={200}
      >
        {createEmail?.map((formData: EmailForm, index) => {
          return (
            <CreateEmailCard
              key={formData.id}
              formData={formData}
              hidden={index >= 2}
            />
          );
        })}
      </Box>
    </>
  );
};
export default PopperSendEmailBox;
