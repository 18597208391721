import { some } from "../../../common/constants";
export const PARAMS_WHATSAPP = ["name", "email", "mobile_number", "address"];
export const ListLanguageMSB = [
  { id: "af", name: "Afrikaans" },
  { id: "sq", name: "Albanian" },
  { id: "ar", name: "Arabic" },
  { id: "az", name: "Azerbaijani" },
  { id: "bn", name: "Bengali" },
  { id: "bg", name: "Bulgarian" },
  { id: "ca", name: "Catalan" },
  { id: "zh_CN", name: "Chinese (CHN)" },
  { id: "zh_HK", name: "Chinese (HKG)" },
  { id: "zh_TW", name: "Chinese (TAI)" },
  { id: "hr", name: "Croatian" },
  { id: "cs", name: "Czech" },
  { id: "da", name: "Danish" },
  { id: "nl", name: "Dutch" },
  { id: "en", name: "English" },
  { id: "en_GB", name: "English (UK)" },
  { id: "en_US", name: "English (US)" },
  { id: "et", name: "Estonian" },
  { id: "fil", name: "Filipino" },
  { id: "fi", name: "Finnish" },
  { id: "fr", name: "French" },
  { id: "de", name: "German" },
  { id: "el", name: "Greek" },
  { id: "gu", name: "Gujarati" },
  { id: "he", name: "Hebrew" },
  { id: "hi", name: "Hindi" },
  { id: "hu", name: "Hungarian" },
  { id: "id", name: "Indonesian" },
  { id: "ga", name: "Irish" },
  { id: "it", name: "Italian" },
  { id: "ja", name: "Japanese" },
  { id: "kn", name: "Kannada" },
  { id: "kk", name: "Kazakh" },
  { id: "ko", name: "Korean" },
  { id: "lo", name: "Lao" },
  { id: "lv", name: "Latvian" },
  { id: "lt", name: "Lithuanian" },
  { id: "mk", name: "Macedonian" },
  { id: "ms", name: "Malay" },
  { id: "mr", name: "Marathi" },
  { id: "nb", name: "Norwegian" },
  { id: "fa", name: "Persian" },
  { id: "pl", name: "Polish" },
  { id: "pt_BR", name: "Portuguese (BR)" },
  { id: "pt_PT", name: "Portuguese (POR)" },
  { id: "pa", name: "Punjabi" },
  { id: "ro", name: "Romanian" },
  { id: "ru", name: "Russian" },
  { id: "sr", name: "Serbian" },
  { id: "sk", name: "Slovak" },
  { id: "sl", name: "Slovenian" },
  { id: "es", name: "Spanish" },
  { id: "es_AR", name: "Spanish (ARG)" },
  { id: "es_ES", name: "Spanish (SPA)" },
  { id: "es_MX", name: "Spanish (MEX)" },
  { id: "sw", name: "Swahili" },
  { id: "sv", name: "Swedish" },
  { id: "ta", name: "Tamil" },
  { id: "te", name: "Telugu" },
  { id: "th", name: "Thai" },
  { id: "tr", name: "Turkish" },
  { id: "uk", name: "Ukrainian" },
  { id: "ur", name: "Urdu" },
  { id: "uz", name: "Uzbek" },
  { id: "vi", name: "Vietnamese" },
];
export const CategoriesTemplate = [
  { id: "UTILITY", name: "Utility" },
  { id: "MARKETING", name: "Marketing" },
  { id: "AUTHENTICATION", name: "Authentication" },
];
export const ButtonTypesTemplate = [
  { id: "none", name: "None" },
  { id: "QUICK_REPLY", name: "Quick Reply" },
  { id: "ACTION", name: "Call to Action" },
];
export const HeaderTypesTemplate = [
  { id: "none", name: "None" },
  { id: "TEXT", name: "Text" },
  { id: "IMAGE", name: "Image" },
  { id: "DOCUMENT", name: "Document" },
  { id: "VIDEO", name: "Video" },
];
export const ButtonActionTemplate = [
  { id: "URL", name: "Visit website" },
  { id: "PHONE_NUMBER", name: "Phone number" },
];
export const UrlType = [
  { id: "static", name: "Static" },
  { id: "dynamic", name: "Dynamic" },
];

type TemplateComponentType = "BODY" | "HEADER" | "FOOTER" | "BUTTONS";
type TemplateComponentFormat = "TEXT" | "IMAGE" | "DOCUMENT" | "VIDEO" | "none";
type TemplateComponentButtonType =
  | "PHONE_NUMBER"
  | "URL"
  | "QUICK_REPLY"
  | "none";

interface TemplateComponentButton {
  type: TemplateComponentButtonType;
  text: string;
  url?: string;
  phone_number?: string;
  example?: string[];
}
interface TemplateExample {
  header_text?: string[];
  body_text?: string[][];
  header_url?: string[];
}

export interface TemplateComponent {
  type: TemplateComponentType;
  format?: TemplateComponentFormat;
  text?: string;
  buttons?: TemplateComponentButton[];
  example?: TemplateExample;
}

type TemplateCategory = "OTP" | "TRANSACTIONAL" | "MARKETING";

export interface FormTemplate {
  name: string;
  language: string;
  components: TemplateComponent[];
  category: TemplateCategory;
  wabaId?: string;
  allowCategoryChange?: boolean;
}

export interface SchemaFormTemplate {
  buttonType: "QUICK_REPLY" | "ACTION" | "none";
  headerType: TemplateComponentFormat;
  header?: string;
  message?: string;
  status?: string;
  rejectedReason?: string;
  quickReply?: {
    text: string;
  }[];
  btnCallToAction?: {
    type: TemplateComponentButtonType;
    text: string;
    urlType?: string;
    url?: string;
    phone_number?: string;
  }[];
  header_text?: string;
  header_url?: string;
  button_example?: string;
  body_text?: string[];
  footer?: string;
  namespace?: string;
  templateName?: string;
  language?: string;
}
export const initialFormTemplate: SchemaFormTemplate = {
  headerType: "none",
  buttonType: "none",
};

interface SchemaFormTemplateTmp {
  [key: string]: SchemaFormTemplate;
}

export type GroupFormTemplate = SchemaFormTemplateTmp & {
  name: string;
  category: string;
};

export const mappedTemplateForm = (
  value: some,
  mode?: "customized" | "create" | "view"
): SchemaFormTemplate => {
  const btnObj = value.components?.find((v) => v.type === "BUTTONS");
  const buttonType =
    btnObj?.buttons?.[0]?.type === "QUICK_REPLY"
      ? "QUICK_REPLY"
      : !!btnObj?.buttons?.[0]?.type
      ? "ACTION"
      : "none";
  const header = value.components?.find((v) => v.type === "HEADER");
  const body = value.components?.find((v) => v.type === "BODY");

  return {
    ...value,
    templateName: value.name,
    message: body?.text,
    body_text: body?.example?.body_text,
    footer: value.components?.find((v) => v.type === "FOOTER")?.text,
    headerType: header?.format || "none",
    header: header?.text,
    header_text: header?.example?.header_text?.[0],
    buttonType: buttonType,
    quickReply: buttonType === "QUICK_REPLY" ? btnObj?.buttons : undefined,
    btnCallToAction: buttonType !== "QUICK_REPLY" ? btnObj?.buttons : undefined,
    header_url: header?.example?.header_url?.[0],
    status: mode === "customized" ? undefined : value.status,
    button_example: btnObj?.buttons?.[0]?.example?.[0],
    rejectedReason: value.rejectedReason,
  };
};
