import { Button, ButtonProps, Tooltip, TooltipProps } from "@mui/material";

interface Props extends ButtonProps {
  tooltipProps?: Omit<TooltipProps, "children">;
}
const ButtonTooltip = (props: Props) => {
  const { tooltipProps, ...rest } = props;
  if (tooltipProps)
    return (
      <Tooltip {...tooltipProps}>
        <Button {...rest} />
      </Tooltip>
    );
  return <Button {...rest} />;
};
export default ButtonTooltip;
