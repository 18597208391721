import { Close, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  IconButton,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/vi";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import query from "query-string";
import { useCallback, useEffect, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import withDragAndDrop, {
  withDragAndDropProps,
} from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import API_GOOGLE from "../../api/google";
import { API_PATHS } from "../../api/gomedia";
import { localeObject, some } from "../../common/constants";
import fetchGoogle from "../../common/fetchGoogle";
import useCheckMobile from "../../common/hook/useCheckMobile";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { AppState } from "../../redux/store";
import DetailAppointment from "./DetailAppointment";
import EventForm from "./EventForm";
import {
  IEvent,
  mappedEventFormData,
  mappedUpdateEventParams,
} from "./constants";
import "./styles.css";
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale("en", localeObject);
dayjs.updateLocale("vi", localeObject);

const DnDCalendar = withDragAndDrop(Calendar);

const CustomToolbar = (props) => {
  const { toolbar, setView } = props;
  const [view, setViewLocal] = useState("week");
  useEffect(() => {
    setView(view);
  }, [setView, view]);

  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate("next");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
  };
  const goToDayView = () => {
    setViewLocal("day");
  };
  const goToWeekView = () => {
    setViewLocal("week");
  };
  const goToMonthView = () => {
    setViewLocal("month");
  };

  const label = () => {
    const date = dayjs(toolbar.date);
    return (
      <Typography variant="h6" className="flex-1 text-center">
        <b>{date.format("MMMM")}</b>
        <span className="font-normal"> {date.format("YYYY")}</span>
      </Typography>
    );
  };

  return (
    <Box className="flex items-center gap-2 mb-4 dura">
      <ButtonGroup variant="outlined" size="small">
        <Button onClick={goToCurrent}>Today</Button>
        <Button onClick={goToBack}>Back</Button>
        <Button onClick={goToNext}>Next</Button>
      </ButtonGroup>
      {label()}
      {/* <Button variant="outlined" component="label" size="small">
        <Upload />
        <input
          accept={".ics"}
          hidden
          type="file"
          multiple
          onChange={(event) => {
            if (event.target.files) {
              var reader = new FileReader();
              reader.onload = function (a: any) {
                console.log("File content:", parseICS(a.target.result));
              };
              reader.readAsText(event.target.files[0]);
            }
          }}
        />
      </Button> */}
      <ButtonGroup variant="outlined" size="small">
        <Button
          variant={view === "month" ? "contained" : "outlined"}
          onClick={goToMonthView}
        >
          Month
        </Button>
        <Button
          variant={view === "week" ? "contained" : "outlined"}
          onClick={goToWeekView}
        >
          Week
        </Button>

        <Button
          variant={view === "day" ? "contained" : "outlined"}
          onClick={goToDayView}
        >
          Day
        </Button>
      </ButtonGroup>
    </Box>
  );
};

const CalendarGooglePage = () => {
  const { dispatch, enqueueSnackbar, intl, confirmDialog, fetchThunk } =
    useGeneralHook();
  const { promptConfirmation } = confirmDialog;
  const { isMobile } = useCheckMobile();
  const agentData = useSelector(
    (state: AppState) => state.auth.agentData,
    shallowEqual
  );
  const [openDetailEvent, setOpenDetailEvent] = useState<boolean>(false);
  const [detail, setDetail] = useState<IEvent | undefined>();
  const [openUpdateEvent, setOpenUpdateEvent] = useState(false);

  const [view, setView] = useState("week");
  const [createForm, setCreateForm] = useState<IEvent | undefined>();
  const [popperCreate, setPopperCreate] = useState<any | undefined>();
  const [data, setData] = useState<some[]>([]);
  const { data: rawData, refetch } = useQuery(
    ["list-events"],
    async () => {
      const json = await dispatch(
        fetchGoogle({
          url: API_GOOGLE.getEvents({}),
        })
      );
      return json.data;
    }
    // { refetchInterval: 5000 }
  );

  const mappedData = useCallback(
    (one) => {
      return [
        ...(one || [])
          ?.filter((val) => val.status === "confirmed")
          ?.map((val) => {
            const tmp = {
              startTime: val.start?.dateTime
                ? dayjs(val.start?.dateTime).tz(val.start?.timeZone)
                : dayjs(val.start?.date),
              endTime: val.end?.dateTime
                ? dayjs(val.end?.dateTime).tz(val.end?.timeZone)
                : dayjs(val.end?.date),
            };
            return {
              start: tmp.startTime.toDate(),
              end: tmp.endTime.toDate(),
              data: mappedEventFormData(val),
              allDay: !!val.start?.date,
              title: val.summary || "(No title)",
              id: val.id,
            };
          }),
        createForm && {
          start: createForm.start?.toDate(),
          end: createForm.end?.toDate(),
          title: createForm?.summary || "New Event",
        },
      ].filter(Boolean);
    },
    [createForm]
  );

  useEffect(() => {
    if (rawData) {
      const tmp = mappedData(rawData.items || []);
      setData(tmp);
    }
  }, [mappedData, rawData]);

  const onCreate = async (value: IEvent) => {
    (window as any)
      .open(
        `https://calendar.google.com/calendar/u/0/r/eventedit?${query.stringify(
          {
            dates: value.allDay
              ? (
                  value.start.format("YYYYMMDD") +
                  "/" +
                  value.end.format("YYYYMMDD")
                )
                  .replaceAll("-", "")
                  .replaceAll(":", "")
              : (value.start.toISOString() + "/" + value.end.toISOString())
                  .replaceAll("-", "")
                  .replaceAll(":", ""),
            location: value.location,
            text: value.summary,
            details: value.description,
            add: value.attendees?.map((v) => v.email).join(","),
            crm: value.transparency === "opaque" ? "BUSY" : "AVAILABLE",
          },
          {
            arrayFormat: "comma",
            skipEmptyString: true,
            skipNull: true,
          }
        )}`,
        "_blank"
      )
      .focus();
    await Promise.all(
      (value.attendees || [])?.map((val) => {
        return dispatch(
          fetchThunk({
            url: API_PATHS.calendar,
            data: {
              agent_lead_id: val.id,
              attendee: val.email,
              created: dayjs().toISOString(),
              date_end: value.end.toISOString(),
              // date_stamp: value.end.toISOString(),
              date_start: value.start.toISOString(),
              location: value.location,
              organizer: agentData?.personal_email,
              summary: value.summary,
              transp: value.summary,
            },
            method: "POST",
          })
        );
      })
    );

    // try {
    //   await dispatch(
    //     fetchGoogle({
    //       url: API_GOOGLE.insert,
    //       method: "post",
    //       data: mappedUpdateEventParams(value),
    //     })
    //   );
    //   refetch();
    //   setPopperCreate(undefined);
    //   setCreateForm(undefined);
    // } catch (e) {
    //   enqueueSnackbar({ message: "Error", variant: "error" });
    // }
  };

  const onUpdate = async (value: IEvent, noRefresh?: boolean) => {
    try {
      await dispatch(
        fetchGoogle({
          url: API_GOOGLE.update(value.id),
          method: "put",
          data: mappedUpdateEventParams(value),
        })
      );
      if (!noRefresh) {
        refetch();
      }
      setOpenUpdateEvent(false);
      setDetail(undefined);
      enqueueSnackbar({ message: "Update success", variant: "success" });
    } catch (e) {
      enqueueSnackbar({ message: "Error", variant: "error" });
    }
  };

  const onDelete = async (item) => {
    await promptConfirmation({
      title: intl.formatMessage({ id: "confirm" }),
      content: intl.formatMessage(
        { id: "deleteConfirm" },
        { name: item?.summary }
      ),
      okId: "delete",
      warning: true,
      onAccept: async () => {
        try {
          await dispatch(
            fetchGoogle({
              url: API_GOOGLE.update(item?.id),
              method: "DELETE",
            })
          );
          enqueueSnackbar({
            message: intl.formatMessage({ id: "deleteSuccess" }),
            variant: "success",
          });
          refetch();
          setOpenDetailEvent(false);
        } catch (e) {
          enqueueSnackbar({
            message: intl.formatMessage({ id: "deleteFail" }),
            variant: "error",
          });
        }
      },
    });
  };

  const localizer = () => {
    // dayjs.tz.setDefault("Europe/Andorra");
    return dayjsLocalizer(dayjs);
  };
  const onEventResize: withDragAndDropProps["onEventResize"] = (data) => {
    const newData = {
      ...data.event.data,
      start: dayjs(data.start),
      end: dayjs(data.end),
    };
    setData((old) =>
      old.map((v) => {
        if (v.id === data.event?.id) {
          return { ...v, start: data.start, end: data.end, data: newData };
        }
        return v;
      })
    );
    onUpdate(newData, true);
  };

  const onEventDrop: withDragAndDropProps["onEventDrop"] = (data) => {
    const newData = {
      ...data.event.data,
      start: dayjs(data.start),
      end: dayjs(data.end),
    };
    setData((old) =>
      old.map((v) => {
        if (v.id === data.event?.id) {
          return { ...v, start: data.start, end: data.end, data: newData };
        }
        return v;
      })
    );
    onUpdate(newData, true);
  };

  return (
    <Box className="p-2 sm:p-4 overflow-hidden h-full">
      <Paper sx={{ p: 2, height: "100%" }}>
        <DnDCalendar
          localizer={localizer()}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          resizable={true}
          events={data}
          components={{
            toolbar: (toolbar) => (
              <CustomToolbar toolbar={toolbar} setView={setView} />
            ),
          }}
          titleAccessor={(val) => {
            const dataTmp = val.data;
            return `${val?.title} \n${dataTmp?.location || ""}
            `;
          }}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={(e, b) => {
            if (e.data) {
              setOpenDetailEvent(true);
              setDetail(e.data);
            }
          }}
          selectable
          onSelectSlot={(slotInfo: any) => {
            setCreateForm({
              start: dayjs(slotInfo.start),
              end: dayjs(slotInfo.end),
              allDay:
                dayjs(slotInfo.end).diff(dayjs(slotInfo.start), "day") >= 1,
              reminders: {
                useDefault: false,
                overrides: [{ method: "popup", value: 1, type: "hours" }],
              },
            });
            setPopperCreate(
              slotInfo.box ||
                slotInfo.bounds || {
                  x: isMobile ? 0 : window.innerWidth / 2,
                  y: isMobile ? 0 : 100,
                }
            );
          }}
          view={view}
          onView={setView}
        />
      </Paper>
      {/* ----Create------ */}
      {isMobile ? (
        <Dialog
          open={!!popperCreate}
          onClose={() => {
            setPopperCreate(undefined);
            setCreateForm(undefined);
          }}
          fullScreen
        >
          {createForm && (
            <EventForm formData={createForm} onSubmit={onCreate} />
          )}
        </Dialog>
      ) : (
        <Popover
          anchorReference="anchorPosition"
          open={!!popperCreate}
          onClose={() => {
            setPopperCreate(undefined);
            setCreateForm(undefined);
          }}
          anchorPosition={{
            top: popperCreate?.y || 0,
            left: popperCreate?.x || 0,
          }}
          PaperProps={{
            sx: {
              display: "flex",
              flexDirection: "column",
              width: 600,
              maxHeight: 750,
              position: "fixed",
            },
          }}
        >
          {createForm && (
            <EventForm formData={createForm} onSubmit={onCreate} />
          )}
        </Popover>
      )}
      {/* ----Detail------ */}
      <Dialog
        open={!!openDetailEvent}
        onClose={() => setOpenDetailEvent(false)}
        PaperProps={{
          sx: {
            p: 1,
            display: "flex",
            flexDirection: "column",
            width: 448,
          },
        }}
        fullScreen={isMobile}
      >
        {detail && (
          <>
            <Box className="flex gap-2 justify-end">
              <IconButton
                onClick={() => {
                  setOpenUpdateEvent(true);
                  setOpenDetailEvent(false);
                }}
              >
                <EditOutlined />
              </IconButton>
              <IconButton
                onClick={() => {
                  onDelete(detail);
                }}
              >
                <DeleteOutlined />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpenDetailEvent(false);
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <DetailAppointment data={detail} />
          </>
        )}
      </Dialog>

      {/* ----Detail------ */}
      <Dialog
        open={openUpdateEvent}
        onClose={() => {
          setOpenUpdateEvent(false);
          setDetail(undefined);
        }}
        fullScreen
        PaperProps={{
          sx: {
            p: 0,
            position: "relative",
          },
        }}
        scroll="paper"
        TransitionProps={{
          onEnter: () => {},
        }}
        keepMounted
      >
        <IconButton
          sx={{ position: "absolute", top: 24, right: 24 }}
          onClick={() => {
            setOpenUpdateEvent(false);
            setDetail(undefined);
          }}
        >
          <Close />
        </IconButton>
        {detail && (
          <Container maxWidth="sm" sx={{ px: 0, flex: 1 }}>
            <EventForm formData={detail} onSubmit={onUpdate} />
          </Container>
        )}
      </Dialog>
    </Box>
  );
};
export default CalendarGooglePage;
