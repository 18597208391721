export const TEMPLATE_PROVIDER = [
  { id: "MESSAGEBIRD", name: "messagebird" },
  { id: "MAILGUN", name: "mailgun" },
];

export const TEMPLATE_TYPE = {
  system: "SYSTEM",
  customized: "CUSTOMIZED",
  personal: "PERSONAL",
};

export const TEMPLATE_TYPE_OPTIONS = [
  { id: TEMPLATE_TYPE.system, name: "system" },
  { id: TEMPLATE_TYPE.customized, name: "customized" },
  { id: TEMPLATE_TYPE.personal, name: "personal" },
];
