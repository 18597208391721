import { Box, BoxProps, GridProps } from "@mui/material";
import React, { useMemo } from "react";
import {
  DefaultValues,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { unstable_usePrompt } from "react-router-dom";
import { some } from "../constants";
import useGeneralHook from "../hook/useGeneralHook";
import SchemaView from "./SchemaView";
import { ISchemaForm } from "./utils";

interface Props<T extends some | undefined = some> {
  schema?: ISchemaForm<T>;
  formId?: string;
  fieldName?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  onSubmit?: (data: any, methods: UseFormReturn) => void | Promise<void>;
  onCancel?: (methods: UseFormReturn) => void;
  onReset?: (methods: UseFormReturn) => void | boolean;
  formData?: T;
  getDirtyData?: boolean;
  initialData?: ((payload?: any) => Promise<T>) | DefaultValues<T>;
  hiddenField?: some;
  onChange?: (value: some, methods: UseFormReturn) => void;
  hideSubmitButton?: boolean;
  notForm?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  methods?: UseFormReturn;
  formProps?: BoxProps;
  showConfirmBeforeLeave?: boolean | React.ReactNode;
  propsGridContainer?: GridProps;
  viewMode?: boolean;
  globalProps?: some;
  formHookProps?: UseFormProps;
  [key: string]: any;
}
export const FormMain = <T extends some>(props: Props<T>) => {
  const {
    schema,
    formId,
    fieldName = "",
    onSubmit,
    formData,
    hiddenField,
    hideSubmitButton = false,
    methods: methodsProps,
    formProps,
    initialData,
    notForm,
    showConfirmBeforeLeave,
    propsGridContainer,
    readOnly,
    viewMode,
    formHookProps,
    getDirtyData,
  } = props;
  const mainProps = useGeneralHook();
  const { setState } = mainProps;
  const defaultMethods = useForm({
    defaultValues: initialData,
    values: formData,
    reValidateMode: "onChange",
    mode: "onSubmit",
    ...formHookProps,
  });

  const methods = useMemo((): UseFormReturn<any, object> => {
    return methodsProps || defaultMethods;
  }, [methodsProps, defaultMethods]);

  const onSubmitForm = async (data: any) => {
    if (readOnly) {
      return;
    }
    setState({ loading: true });
    try {
      let dataFilter = { ...hiddenField, ...data };
      let dirtyValues = {};
      Object.entries(methods.control._fields).forEach(([key, field]) => {
        if (field?._f.required || !!field?._f.validate) {
          dirtyValues[field?._f.name] = field._f.value;
        } else if (field?._f.name) {
          if (data?.id) {
            if (methods.getFieldState(field?._f.name).isDirty)
              dirtyValues[field?._f.name] = field._f.value;
          } else dirtyValues[field?._f.name] = field._f.value;
        }
      });

      if (data?.id) {
        dirtyValues["id"] = data?.id;
      }

      let dataMerge = schema?.changeDataBeforeSubmit
        ? schema?.changeDataBeforeSubmit(dataFilter, dirtyValues, {
            ...props,
            ...mainProps,
          })
        : getDirtyData
        ? { ...hiddenField, ...dirtyValues }
        : dataFilter;

      onSubmit && (await onSubmit(dataMerge, methods));
    } finally {
      setState({ loading: false });
    }
  };

  const content = useMemo(() => {
    return (
      schema && (
        <SchemaView<T>
          schema={schema}
          fieldName={fieldName}
          showSubmitButton={!hideSubmitButton && !viewMode}
          propsGridContainer={propsGridContainer}
          formProps={{
            loading: mainProps.state.loading,
            ...mainProps,
            ...props,
            viewMode,
          }}
        />
      )
    );
  }, [
    fieldName,
    hideSubmitButton,
    mainProps,
    props,
    propsGridContainer,
    schema,
    viewMode,
  ]);

  unstable_usePrompt({
    message:
      typeof showConfirmBeforeLeave === "string"
        ? showConfirmBeforeLeave
        : mainProps.intl.formatMessage({ id: "youHaveUnsavedData" }),
    when:
      methods?.formState.isDirty &&
      Object.keys(methods?.formState.dirtyFields)?.length > 0 &&
      !!showConfirmBeforeLeave,
  });

  if (notForm) {
    return content || null;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Box
          component={"form"}
          {...formProps}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            ...formProps?.style,
          }}
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            // this part is for stopping parent forms to trigger their submit
            if (event) {
              // sometimes not true, e.g. React Native
              if (typeof event.preventDefault === "function") {
                event.preventDefault();
              }
              if (typeof event.stopPropagation === "function") {
                // prevent any outer forms from receiving the event too
                event.stopPropagation();
              }
            }

            return methods.handleSubmit(onSubmitForm)(event);
          }}
        >
          {content}
          <input type="submit" id={formId} style={{ display: "none" }} />
        </Box>
      </FormProvider>
    </>
  );
};

export default FormMain;
