import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { ROUTES_PATH } from "../../layout/constants";
import { AppState } from "../../redux/store";

function ProtectedRoute({ children }: { children?: JSX.Element }) {
  const { isLogin } = useSelector((state: AppState) => state.auth);
  let location = useLocation();

  if (!isLogin) {
    const from = (location && `${location.pathname}${location.search}`) || "/";
    return (
      <Navigate
        to={{
          pathname: ROUTES_PATH.login,
          search: `?from=${encodeURIComponent(from)}`,
        }}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
}

export default ProtectedRoute;
