import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { KEYS_QUERY } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../../../common/hook/usePaginationHook";
import ListEmailBox from "./ListEmailBox";

interface Props {}
const EmailTab = (props: Props) => {
  const { API_PATHS, dispatch, fetchThunk } = useGeneralHook();

  const { leadId } = useParams<{ leadId: string }>();

  const pagination = usePaginationHook({
    defaultFilter: {
      q: "",
    },
    disableLink: true,
  });

  const {
    object: { params },
  } = pagination;

  const { data, isLoading, refetch } = useQuery(
    [KEYS_QUERY.emails],
    async () => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.communications.email({
            q: params.q,
            direction: params.direction,
            timestamp_from: params.rage?.[0],
            timestamp_to: params.rage?.[1],
            agent_lead_id: leadId,
          }),
        })
      );
      return json;
    }
  );

  return (
    <>
      <Box className="overflow-hidden flex flex-col">
        <ListEmailBox
          data={data}
          loading={isLoading}
          pagination={pagination}
          refetch={refetch}
        />
      </Box>
    </>
  );
};
export default EmailTab;
