import { Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, InputBase } from "@mui/material";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import EmojiPicker from "./EmojiPicker";
interface Props {
  value?: string;
  onSubmitText: (value: string) => void;
  actionCustomize?: React.ReactNode;
  disabled?: boolean;
  children?: React.ReactNode;
  inputRef?: (e: any) => void;
}
const TextAreaEmoji = (props: Props) => {
  const {
    value = "",
    onSubmitText,
    children,
    actionCustomize,
    disabled,
    inputRef,
  } = props;
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>(value || "");

  const [boxRef, setBoxRef] = useState<HTMLDivElement | null>(null);
  const refInput = useRef<HTMLTextAreaElement | null>(null);

  const onSubmit = debounce(async () => {
    if (text.trim()) {
      setLoading(true);
      try {
        onSubmitText(text.trim());
      } finally {
        setText("");
        refInput.current && refInput.current.focus();
        setLoading(false);
      }
    }
  }, 50);

  return (
    <>
      <Box
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
        }}
        ref={setBoxRef}
      >
        {children}
        <InputBase
          maxRows={3}
          minRows={3}
          multiline
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          disabled={disabled}
          inputRef={(e) => {
            refInput.current = e;
            if (inputRef) {
              inputRef(e);
            }
          }}
          style={{ backgroundColor: "inherit" }}
          inputProps={{
            style: { overflow: "auto" },
          }}
          onKeyDown={async (e) => {
            if (e.code === "Enter" && !e.shiftKey && !loading) {
              e.preventDefault();
              onSubmit();
            }
          }}
        />
        <Box className="flex justify-between items-center p-3">
          <div className="flex gap-1 items-center">
            <EmojiPicker
              disabled={disabled}
              containerRef={boxRef}
              onSelectEmoji={(emoji) => {
                const caretPosition =
                  refInput.current?.selectionStart || text?.length;
                setText(
                  text.slice(0, caretPosition) +
                    emoji +
                    text.slice(caretPosition)
                );
                refInput.current?.focus();
                setTimeout(() => {
                  refInput.current?.setSelectionRange(
                    caretPosition + 1,
                    caretPosition + 1
                  );
                });
              }}
            />
            {actionCustomize}
          </div>
          <LoadingButton
            loading={loading}
            endIcon={<Send />}
            disabled={disabled}
            onClick={onSubmit}
          >
            <FormattedMessage id="send" />
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
export default TextAreaEmoji;
