import query, { StringifyOptions } from "query-string";
import { useCallback, useMemo, useState } from "react";
import { NavigateOptions, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_MOBILE,
  some,
} from "../constants";
interface IParams {
  pagination: {
    page_no: number;
    page_size: number;
  };
  filter: some;
  params: some;
  tab: string;
}
export interface HookPaginationProps
  extends ReturnType<typeof usePaginationHook> {}
interface Props {
  defaultFilter?: some;
  disableLink?: boolean;
  disablePagination?: boolean;
  options?: StringifyOptions;
  parseOptions?: query.ParseOptions;
  rawFields?: string[];
}
const usePaginationHook = (props?: Props) => {
  const {
    defaultFilter = {},
    disableLink,
    disablePagination,
    options,
    parseOptions,
    rawFields,
  } = props || {};
  let [searchParams, setSearchParams] = useSearchParams();
  const optionsTmp = useMemo(
    () =>
      ({
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: "bracket",
        ...options,
      } as StringifyOptions),
    [options]
  );

  const defaultPagination = useMemo(
    () =>
      disablePagination
        ? {}
        : {
            page_no: 1,
            page_size:
              window.innerWidth < 1000
                ? DEFAULT_PAGE_SIZE_MOBILE
                : DEFAULT_PAGE_SIZE,
          },
    [disablePagination]
  );

  const location = useLocation();

  const [filter, setFilter] = useState<some>(defaultFilter);

  const paramsUrl = useMemo(() => {
    if (disableLink) {
      return filter;
    }
    const tmp = query.parseUrl(`?${searchParams.toString()}`, {
      arrayFormat: "bracket",
      parseNumbers: true,
      parseBooleans: true,
      ...parseOptions,
    })?.query;

    return {
      ...defaultPagination,
      ...defaultFilter,
      ...tmp,
      q: searchParams.get("q"),
      ...rawFields?.reduce((v, c) => {
        return { ...v, [c]: searchParams.get(c) };
      }, {}),
    } as some;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchParams]);

  const paginationParams = useMemo((): IParams => {
    const {
      page_no = 1,
      page_size = DEFAULT_PAGE_SIZE,
      tab = "",
      ...rest
    } = paramsUrl;

    return {
      pagination: {
        page_no: Number(page_no),
        page_size: Number(page_size),
      },
      filter: Object.fromEntries(
        Object.entries(rest).filter(
          ([_, v]) => v !== null && v !== "" && v !== undefined
        )
      ),
      params: Object.fromEntries(
        Object.entries(paramsUrl).filter(
          ([_, v]) => v !== null && v !== "" && v !== undefined
        )
      ),
      tab,
    };
  }, [paramsUrl]);

  const clearParams = useCallback(
    (value?: some) => {
      if (disableLink) {
        setFilter((old) => ({
          tab: paginationParams.tab,
          ...defaultPagination,
          ...defaultFilter,
          ...value,
        }));
      } else {
        setSearchParams(
          query.stringify(
            {
              tab: paginationParams.tab,
              ...defaultPagination,
              ...defaultFilter,
              ...value,
            },
            optionsTmp
          ),
          { replace: true, state: location.state }
        );
      }
    },
    [
      disableLink,
      paginationParams.tab,
      defaultPagination,
      defaultFilter,
      setSearchParams,
      optionsTmp,
      location.state,
    ]
  );

  const setParams = useCallback(
    (
      form: some,
      strict?: boolean,
      navigateOpts?: NavigateOptions | undefined
    ) => {
      if (disableLink) {
        setFilter((old) => (strict ? form : { ...old, page_no: 1, ...form }));
      } else {
        setSearchParams(
          disablePagination
            ? query.stringify(
                strict
                  ? form
                  : {
                      tab: paginationParams.tab,
                      ...paginationParams.filter,
                      ...form,
                    },
                optionsTmp
              )
            : query.stringify(
                strict
                  ? form
                  : {
                      tab: paginationParams.tab,
                      ...paginationParams.params,
                      page_no: 1,
                      ...form,
                    },
                optionsTmp
              ),
          { replace: true, state: location.state, ...navigateOpts }
        );
      }
    },
    [
      disableLink,
      disablePagination,
      location.state,
      optionsTmp,
      paginationParams.filter,
      paginationParams.params,
      paginationParams.tab,
      setSearchParams,
    ]
  );

  const onPageChange = useCallback(
    (newPage: number, navigateOpts?: NavigateOptions | undefined) => {
      if (disableLink) {
        setFilter((old) => ({ ...old, page_no: newPage }));
      } else {
        setSearchParams(
          query.stringify(
            { ...paginationParams.params, page_no: newPage },
            optionsTmp
          ),
          { replace: true, state: location.state, ...navigateOpts }
        );
      }
    },
    [
      disableLink,
      setSearchParams,
      paginationParams.params,
      optionsTmp,
      location.state,
    ]
  );

  const onRowsPerPageChange = useCallback(
    (page_size, navigateOpts?: NavigateOptions | undefined) => {
      if (disableLink) {
        setFilter((old) => ({
          ...old,
          page_size,
          page_no: 1,
        }));
      } else {
        setSearchParams(
          query.stringify(
            {
              ...paginationParams.params,
              page_size,
              page_no: 1,
            },
            optionsTmp
          ),
          { replace: true, state: location.state, ...navigateOpts }
        );
      }
    },
    [
      disableLink,
      location.state,
      optionsTmp,
      paginationParams.params,
      setSearchParams,
    ]
  );

  return {
    object: paginationParams,
    setParams,
    clearParams,
    onPageChange,
    onRowsPerPageChange,
  };
};

export default usePaginationHook;
