import {
  Close,
  FullscreenExit,
  Fullscreen as FullscreenIcon,
  PostAdd,
  SyncAltRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FormattedMessage } from "react-intl";
import { useInfiniteQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useIntersectionObserver } from "usehooks-ts";
import LoadingIcon from "../../../../../common/components/LoadingIcon";
import TextAreaEmoji from "../../../../../common/components/TextAreaEmoji";
import { KEYS_QUERY, some } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { ROUTES_PATH } from "../../../../../layout/constants";
import { AppState } from "../../../../../redux/store";
import MessageItem from "./MessageItem";
import TemplatePickerDialog from "./TemplatePickerDialog";
import UploadButton, { FormDataUploadFile } from "./UploadButton";

const GroupMessage = ({ children, index, currentView }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      currentView.current = index;
    }
  }, [currentView, index, isVisible]);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          gap: 2,
          flex: 1,
        }}
      />
      {children}
    </>
  );
};

interface Props {
  headerNode?: React.ReactNode;
}
const WhatsappMessageTab = (props: Props) => {
  const { headerNode } = props;
  const { dispatch, enqueueSnackbar, fetchThunk, getErrorMessage, API_PATHS } =
    useGeneralHook();
  const interval = useRef<any>();
  const handle = useFullScreenHandle();
  const leadInfo =
    useSelector((state: AppState) => state.lead.leadDetail, shallowEqual) || {};

  const agentData = useSelector(
    (state: AppState) => state.auth.agentData,
    shallowEqual
  );

  const [openTemplatePicking, setOpenTemplatePicking] = useState(false);
  const [reply, setReply] = useState<some | undefined>();
  const [inputRef, setInputRef] = useState<HTMLTextAreaElement | null>(null);
  const refMessageBox = useRef<HTMLDivElement | null>(null);
  const currentView = useRef<number>(0);
  const queryFn = async ({ pageParam = dayjs().toISOString() }) => {
    if (!leadInfo?.id) {
      return null;
    }
    const json = await dispatch(
      fetchThunk(
        {
          url: API_PATHS.whatsapp.cursorMessages({
            agent_lead_id: leadInfo?.id,
            cursor: pageParam || dayjs().toISOString(),
            page_size: 20,
          }),
        },
        false
      )
    );
    if (refMessageBox.current && refMessageBox.current?.scrollTop > -100) {
      setTimeout(() => {
        refMessageBox.current?.scrollTo({
          top: 0,
        });
      }, 100);
    }
    return json.data;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [leadInfo?.id, KEYS_QUERY.messagesWhatsapp],
    queryFn,
    getNextPageParam: (lastPage) => {
      return lastPage?.data?.length === 20
        ? lastPage?.data?.[lastPage?.data?.length - 1]?.createdDatetime
        : null;
    },
    enabled: !!leadInfo?.id,
  });

  const sendMessage = async (value) => {
    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.whatsapp.send,
          method: "post",
          data: {
            agent_lead_id: leadInfo?.id,
            type: "text",
            text: value,
            reply_to_id: reply?.id,
          },
        })
      );
      refetch({
        refetchPage: (page, index) => index === 0,
        cancelRefetch: true,
      });
      setReply(undefined);
    } catch (e) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };

  const uploadFile = async (values: FormDataUploadFile) => {
    try {
      await dispatch(
        fetchThunk({
          url: API_PATHS.whatsapp.send,
          method: "post",
          data: {
            agent_lead_id: leadInfo?.id,
            reply_to_id: reply?.id,
            type: values.type,
            caption: values.caption,
            url: values.url,
          },
        })
      );
      refetch({
        refetchPage: (page, index) => index === 0,
        cancelRefetch: true,
      });
      setReply(undefined);
    } catch (e) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };

  const sendTemplate = async (value: some) => {
    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.whatsapp.send,
          method: "post",
          data: {
            agent_lead_id: leadInfo?.id,
            type: "hsm",
            template_name: value?.name,
            reply_to_id: reply?.id,
          },
        })
      );
      setOpenTemplatePicking(false);
      refetch({
        refetchPage: (page, index) => index === 0,
        cancelRefetch: true,
      });
      setReply(undefined);
    } catch (e: any) {
      e.response?.data?.errors?.forEach((v) =>
        enqueueSnackbar({
          message: v.message,
          variant: "error",
        })
      );
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      // Tab is focused, refetch every second
      interval.current = setInterval(() => {
        refetch({
          refetchPage: (page, index) =>
            index === currentView.current || index === 0,
          active: true,
        });
      }, 1000);
    };

    const handleBlur = () => {
      // Tab is not focused, refetch every 1 minute
      clearInterval(interval.current);
      interval.current = setInterval(() => {
        refetch({
          refetchPage: (page, index) =>
            index === currentView.current || index === 0,
          active: true,
        });
      }, 60000);
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    // Initial setup
    if (document.hasFocus()) {
      handleFocus();
    } else {
      handleBlur();
    }

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
      clearInterval(interval.current);
    };
  }, [currentView, refetch]);

  const navigate = useNavigate();

  return (
    <FullScreen handle={handle} className="flex flex-col overflow-auto flex-1">
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Box
          sx={{
            pl: headerNode ? 2 : 4,
            pr: 1,
            py: 1,
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            gap: 1,
          }}
        >
          {headerNode}
          <Box flex={1} className="flex gap-4 items-center">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography component={"div"} display={"flex"}>
                {agentData ? agentData?.name : <Skeleton width={120} />}
                &nbsp;(
                <FormattedMessage id="agent" />)
              </Typography>
              <Typography variant="subtitle1">
                {agentData ? (
                  agentData?.business_mobile_number
                ) : (
                  <Skeleton width={160} />
                )}
              </Typography>
            </Box>
            <SyncAltRounded />
            <Link href={`/${ROUTES_PATH.contacts.index}/${leadInfo?.id}`}>
              <Button variant="text">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography component={"div"} display={"flex"}>
                    {leadInfo ? leadInfo?.name : <Skeleton width={120} />}
                    &nbsp;(
                    <FormattedMessage id="lead" />)
                  </Typography>
                  <Typography variant="subtitle1">
                    {leadInfo ? (
                      leadInfo?.mobile_number
                    ) : (
                      <Skeleton width={160} />
                    )}
                  </Typography>
                </Box>
              </Button>
            </Link>
          </Box>
          <Box>
            <Button
              variant="text"
              onClick={() => {
                if (handle.active) {
                  handle.exit();
                } else {
                  handle.enter();
                }
              }}
            >
              {handle.active && handle.node ? (
                <FullscreenExit />
              ) : (
                <FullscreenIcon />
              )}
            </Button>
          </Box>
        </Box>
        <Box
          ref={refMessageBox}
          sx={{
            overflow: "auto",
            p: 2,
            display: "flex",
            flexDirection: "column-reverse",
            gap: 2,
            flex: 1,
          }}
          onScroll={(e) => {
            if (
              Math.abs(e.currentTarget.scrollTop) +
                e.currentTarget.offsetHeight +
                500 >=
                e.currentTarget.scrollHeight &&
              !isFetchingNextPage &&
              hasNextPage
            ) {
              fetchNextPage();
            }
          }}
        >
          {data?.pages?.map((group, index) => {
            return (
              <GroupMessage key={index} index={index} currentView={currentView}>
                {group?.data?.map((message) => {
                  return (
                    <MessageItem
                      key={message.id}
                      message={message}
                      isOwner={message.from !== leadInfo?.mobile_number}
                      leadId={leadInfo?.id}
                      setReply={(val) => {
                        setReply(val);
                        inputRef?.focus();
                      }}
                    />
                  );
                })}
              </GroupMessage>
            );
          })}
          {(isLoading || !leadInfo) && <LoadingIcon sx={{ minHeight: 120 }} />}
        </Box>
        <Box sx={{ px: 2, py: 1 }}>
          {leadInfo.whatsapp_unsubscribed ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Close color="error" />
              <Typography sx={{ fontStyle: "italic" }}>
                <FormattedMessage id="whatsappUnsubscribed" />
              </Typography>
            </Box>
          ) : null}
          <TextAreaEmoji
            disabled={leadInfo.whatsapp_unsubscribed}
            onSubmitText={sendMessage}
            inputRef={setInputRef}
            actionCustomize={
              <>
                <UploadButton
                  disabled={leadInfo.whatsapp_unsubscribed}
                  onChange={uploadFile}
                />
                <IconButton
                  disabled={leadInfo.whatsapp_unsubscribed}
                  size="small"
                  onClick={() => setOpenTemplatePicking(true)}
                >
                  <PostAdd />
                </IconButton>
              </>
            }
          >
            {reply && (
              <Box
                px={1}
                py={0.5}
                borderRadius={2}
                m={1}
                bgcolor={"background.default"}
                position={"relative"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Box flex={1}>
                  <MessageItem readOnly message={reply} />
                </Box>
                <IconButton size="small" onClick={() => setReply(undefined)}>
                  <Close />
                </IconButton>
              </Box>
            )}
          </TextAreaEmoji>
        </Box>
        <TemplatePickerDialog
          open={openTemplatePicking}
          onClose={setOpenTemplatePicking}
          onSubmit={sendTemplate}
        />
      </Paper>
    </FullScreen>
  );
};

export default WhatsappMessageTab;
