import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { some } from "../common/constants";

export interface LeadState {
  selectionLeads: some;
  isSelectAllLead: boolean;
}

export const initialStateSelection: LeadState = {
  selectionLeads: {},
  isSelectAllLead: false,
};

export const selectionSlice = createSlice({
  name: "auth",
  initialState: initialStateSelection,
  reducers: {
    setSelectionLead: (
      state,
      action: PayloadAction<{ data: some; checked?: boolean; force?: boolean }>
    ) => {
      if (action.payload.force) {
        let obj = action.payload.data;
        Object.keys(obj).forEach(
          (key) => obj[key] === undefined && delete obj[key]
        );
        state.selectionLeads = obj;
      }
      if (action.payload.checked) {
        let obj = {
          ...state.selectionLeads,
          [action.payload.data?.id]: action.payload.data,
        };
        Object.keys(obj).forEach(
          (key) => obj[key] === undefined && delete obj[key]
        );
        state.selectionLeads = obj;
      } else {
        delete state.selectionLeads[action.payload.data?.id];
      }
    },
    setSelectionMode: (state, action: PayloadAction<boolean>) => {
      state.isSelectAllLead = action.payload;
      state.selectionLeads = {};
    },
  },
});

export const { setSelectionMode, setSelectionLead } = selectionSlice.actions;

export default selectionSlice.reducer;
