import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { MODE_THEME, some } from "../common/constants";

export interface CacheState {
  mode: "light" | "dark";
  breadcrumb: some;
  openAsideBar: boolean;
  openAsideBarMobile: boolean;
}

export const initialStateCache: CacheState = {
  mode: (Cookies.get(MODE_THEME) as any) || "light",
  breadcrumb: {},
  openAsideBar: true,
  openAsideBarMobile: true,
};

export const cacheSlice = createSlice({
  name: "common",
  initialState: initialStateCache,
  reducers: {
    setModeTheme: (state, action) => {
      state.mode = action.payload;
    },
    setBreadcrumb: (state, action: PayloadAction<some>) => {
      state.breadcrumb = { ...state.breadcrumb, ...action.payload };
    },
    setOpenAsideBar: (state, action: PayloadAction<boolean | undefined>) => {
      state.openAsideBar =
        action.payload !== undefined ? action.payload : !state.openAsideBar;
    },
    setOpenAsideBarMobile: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.openAsideBarMobile =
        action.payload !== undefined
          ? action.payload
          : !state.openAsideBarMobile;
    },
  },
});

export const {
  setModeTheme,
  setBreadcrumb,
  setOpenAsideBar,
  setOpenAsideBarMobile,
} = cacheSlice.actions;

export default cacheSlice.reducer;
