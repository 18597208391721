import { createSlice } from '@reduxjs/toolkit';

export interface IntlState {
  locale: string
}

export const initialStateIntl: IntlState = {
  locale: 'en',
}

export const intlReducer = createSlice({
  name: 'intl',
  initialState: initialStateIntl,
  reducers: {
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
  }
})

export const { changeLocale } =
  intlReducer.actions;

export default intlReducer.reducer
