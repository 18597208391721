import {
  CloseFullscreenRounded,
  CloseRounded,
  MinimizeRounded,
  OpenInFullRounded,
} from "@mui/icons-material";
import { Box, ButtonBase, Dialog, Paper, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { EmailForm, setEmailForm } from "../../../../../redux/leadReducer";
import { AppState } from "../../../../../redux/store";
import EmailFormBox from "./EmailFormBox";

interface Props {
  formData: EmailForm;
  hidden?: boolean;
}
const CreateEmailCard = (props: Props) => {
  const { formData, hidden } = props;
  const { dispatch, isMobile } = useGeneralHook();
  const [minimize, setMinimize] = useState(false);
  const [openFull, setOpenFull] = useState(false);
  const { createEmail } = useSelector(
    (state: AppState) => state.lead,
    shallowEqual
  );

  const methods = useForm<EmailForm>({
    defaultValues: formData,
    reValidateMode: "onChange",
    mode: "onSubmit",
  });

  const schema = useMemo(
    () => (
      <EmailFormBox
        key={formData?.id}
        methods={methods}
        formData={formData}
        heightTextEditor={openFull ? 600 : 300}
        callback={() => {
          dispatch(
            setEmailForm(createEmail.filter((v) => v.id !== formData.id))
          );
        }}
      />
    ),
    [createEmail, dispatch, formData, methods, openFull]
  );

  if (hidden) {
    return null;
  }

  if (isMobile) {
    return (
      <Dialog open PaperProps={{ sx: { p: 2 } }} fullScreen>
        <Box className="w-full flex gap-2 mb-4">
          <Typography variant="subtitle1" flex={1}>
            <FormattedMessage id="new_message" />
          </Typography>
          <ButtonBase
            sx={{ p: 0 }}
            onClick={() =>
              dispatch(
                setEmailForm(createEmail.filter((v) => v.id !== formData.id))
              )
            }
          >
            <CloseRounded sx={{ fontSize: 16 }} />
          </ButtonBase>
        </Box>
        {schema}
      </Dialog>
    );
  }
  if (openFull) {
    return (
      <Dialog open PaperProps={{ sx: { p: 2 } }} maxWidth="lg" fullWidth>
        <Box className="w-full flex gap-2 mb-4">
          <Typography variant="subtitle1" flex={1}>
            <FormattedMessage id="new_message" />
          </Typography>
          <ButtonBase
            sx={{ p: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenFull((old) => !old);
              setMinimize((old) => !old);
            }}
          >
            <MinimizeRounded
              sx={{ fontSize: 16 }}
              className={minimize ? "rotate-180" : ""}
            />
          </ButtonBase>
          <ButtonBase
            sx={{ p: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenFull((old) => !old);
            }}
          >
            <CloseFullscreenRounded sx={{ fontSize: 16 }} />
          </ButtonBase>

          <ButtonBase
            sx={{ p: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setEmailForm(createEmail.filter((v) => v.id !== formData.id))
              );
            }}
          >
            <CloseRounded sx={{ fontSize: 16 }} />
          </ButtonBase>
        </Box>
        {schema}
      </Dialog>
    );
  }

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          borderRadius: "8px 8px 0px 0px",
          p: 2,
          pb: 0,
          width: minimize ? 240 : 560,
          height: "fit-content",
        }}
      >
        <Box
          className="w-full flex gap-2 mb-4 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setMinimize((old) => !old);
          }}
        >
          <Typography variant="subtitle1" flex={1}>
            <FormattedMessage id="new_message" />
          </Typography>

          <ButtonBase
            sx={{ p: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              setMinimize((old) => !old);
            }}
          >
            <MinimizeRounded
              sx={{ fontSize: 16 }}
              className={minimize ? "rotate-180" : ""}
            />
          </ButtonBase>
          <ButtonBase
            sx={{ p: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenFull((old) => !old);
            }}
          >
            <OpenInFullRounded sx={{ fontSize: 16 }} />
          </ButtonBase>

          <ButtonBase
            sx={{ p: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setEmailForm(createEmail.filter((v) => v.id !== formData.id))
              );
            }}
          >
            <CloseRounded sx={{ fontSize: 16 }} />
          </ButtonBase>
        </Box>
        <Box sx={{ display: minimize ? "none" : undefined }}>{schema}</Box>
      </Paper>
    </>
  );
};
export default CreateEmailCard;
