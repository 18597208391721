import { SearchOutlined } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import MaterialReactTable, {
  MRT_SortingState,
  MRT_Virtualizer,
  MaterialReactTableProps,
} from "material-react-table";
import { FunctionComponent, useRef, useState } from "react";
import { some } from "../constants";
import useGeneralHook from "../hook/useGeneralHook";
import LoadingIcon from "./LoadingIcon";
import MuiPaginationCustom, {
  MuiPaginationCustomProps,
} from "./MuiPaginationCustom";

interface Props<T extends some>
  extends Omit<MaterialReactTableProps<T>, "onSortingChange"> {
  paginationProps?: MuiPaginationCustomProps;
  onSortingChange?: (sorting: MRT_SortingState) => void;
}
const MuiTable: FunctionComponent<Props<some>> = (props) => {
  const {
    paginationProps,
    state,
    muiSearchTextFieldProps,
    muiTablePaperProps,
    muiTopToolbarProps,
    onSortingChange,
    muiTableBodyCellProps,
    muiTableHeadCellProps,
    enableRowVirtualization,
    renderBottomToolbarCustomActions,
    ...rest
  } = props;
  const { isMobile } = useGeneralHook();
  const [sorting, setSort] = useState<MRT_SortingState>(state?.sorting || []);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  return (
    <MaterialReactTable<some>
      enableDensityToggle={false}
      // enableRowNumbers
      renderEmptyRowsFallback={
        props.state?.isLoading ? () => <LoadingIcon /> : undefined
      }
      enableStickyHeader
      enableColumnActions={false}
      enablePagination={false}
      enableTableFooter={false}
      enableGlobalFilter={false}
      enableRowVirtualization={enableRowVirtualization}
      rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
      rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
      columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer
      manualFiltering={true}
      manualSorting={true}
      sortDescFirst={true}
      muiTopToolbarProps={{
        ...muiTopToolbarProps,
        sx: {
          display: "flex",
          minHeight: "unset",
          overflow: "unset",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          ...(muiTopToolbarProps as any)?.sx,
        },
      }}
      muiTablePaperProps={{
        elevation: 0,
        ...muiTablePaperProps,
        sx: {
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          ...(muiTablePaperProps as any)?.sx,
        },
      }}
      muiTableHeadCellProps={{
        ...muiTableHeadCellProps,

        sx: enableRowVirtualization
          ? (muiTableHeadCellProps as any)?.sx
          : {
              width: "auto",
              minWidth: "auto",
              ...(muiTableHeadCellProps as any)?.sx,
            },
      }}
      muiTableBodyCellProps={{
        ...muiTableBodyCellProps,
        sx: enableRowVirtualization
          ? (muiTableBodyCellProps as any)?.sx
          : {
              width: "auto",
              minWidth: "auto",
              maxWidth: 500,
              ...(muiTableBodyCellProps as any)?.sx,
            },
      }}
      enableColumnFilters={false}
      enableFullScreenToggle={!isMobile}
      enableHiding={!isMobile}
      onSortingChange={(sortingUpdater: any) => {
        const newSortVal = sortingUpdater(sorting);
        setSort(newSortVal);
        onSortingChange && onSortingChange(newSortVal);
      }}
      state={{
        density: "compact",
        ...state,
        sorting,
        isLoading: false,
      }}
      muiBottomToolbarProps={{ sx: { minHeight: 48 } }}
      renderBottomToolbarCustomActions={(...params) => (
        <>
          {renderBottomToolbarCustomActions &&
            renderBottomToolbarCustomActions(...params)}
          {paginationProps && (
            <MuiPaginationCustom {...(paginationProps as any)} />
          )}
        </>
      )}
      muiSearchTextFieldProps={{
        variant: "outlined",
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        },
        ...muiSearchTextFieldProps,
      }}
      muiTableProps={() => {
        return {
          sx: {
            "& tr": {
              bgcolor: "background.paper",
            },
          },
        };
      }}
      {...rest}
    />
  );
};
export default MuiTable;
