import query from "query-string";
import { some } from "../common/constants";
import { development } from "./gomedia";

const BASE_URL = development
  ? "/google/"
  : "https://www.googleapis.com/calendar/v3";

const API_GOOGLE = {
  getEvents: (params: some) => {
    return `${BASE_URL}/calendars/primary/events?${query.stringify(
      {
        ...params,
        maxResults: 2500,
      },
      {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      }
    )}`;
  },
  insert: `${BASE_URL}/calendars/primary/events?conferenceDataVersion=${1}`,
  update: (eventId) =>
    `${BASE_URL}/calendars/primary/events/${eventId}?conferenceDataVersion=${1}`,
};

export default API_GOOGLE;
