import {
  AttachFileOutlined,
  CalendarMonthOutlined,
  Call,
  FmdGoodOutlined,
  GroupOutlined,
  NotificationsOutlined,
  OpenInNewOutlined,
  SubjectOutlined,
} from "@mui/icons-material";
import { Box, Button, Link, Typography } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import { FormattedMessage } from "react-intl";
import { LogoMeet } from "../../svg";
import { IEvent } from "./constants";

interface Props {
  data: IEvent;
}
const DetailAppointment = (props: Props) => {
  const { data } = props;

  const joinByPhone = data.conferenceData?.entryPoints?.find(
    (v) => v.entryPointType === "phone"
  );
  const morePhone = data.conferenceData?.entryPoints?.find(
    (v) => v.entryPointType === "more"
  );

  return (
    <>
      <Box pl={6}>
        <Link
          href={data.htmlLink}
          target="_blank"
          rel="noreferrer noopener"
          underline="hover"
        >
          <Typography variant="h4">{data?.summary || "(No title)"}</Typography>
        </Link>
        <Typography variant="body1">
          {data.start.format("dddd, MMMM D")}
          &nbsp;⋅&nbsp;
          {data.start.format("h:mm")}
          &nbsp;-&nbsp;
          {data.end.format("dddd, MMMM D")}&nbsp;⋅&nbsp;
          {data.end.format("h:mm A")}
        </Typography>
      </Box>
      {data.hangoutLink && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <img src={LogoMeet} alt="LogoMeet" className="h-6 w-6" />
          </Box>
          <Box py={1}>
            <Button
              variant="contained"
              component="a"
              href={data.hangoutLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <FormattedMessage id="join_google_meet" />
            </Button>
          </Box>
        </Box>
      )}
      {joinByPhone && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <Call className="text-gray-500" />
          </Box>
          <Box py={1}>
            <Typography
              variant="subtitle1"
              color={"primary"}
              component={"a"}
              href={joinByPhone.uri}
              rel="noreferrer noopener"
              target="_blank"
            >
              <FormattedMessage id="join_by_phone" />
            </Typography>
            <Typography variant="body1">
              ({joinByPhone?.regionCode})&nbsp;{joinByPhone.uri}&nbsp;PIN:&nbsp;
              {joinByPhone.pin}
            </Typography>
          </Box>
        </Box>
      )}
      {morePhone && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <OpenInNewOutlined className="text-gray-500" />
          </Box>
          <Box py={1}>
            <Link
              variant="h6"
              component={"a"}
              href={morePhone.uri}
              rel="noreferrer noopener"
              target="_blank"
              underline="hover"
            >
              <FormattedMessage id="more_phone_numbers" />
            </Link>
          </Box>
        </Box>
      )}
      {data.location && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <FmdGoodOutlined className="text-gray-500" />
          </Box>
          <Box py={1}>{data.location}</Box>
        </Box>
      )}
      {data?.attendees && data?.attendees?.length > 0 && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <GroupOutlined className="text-gray-500" />
          </Box>
          <Box py={1}>
            {data?.attendees?.map((v) => {
              return (
                <Typography key={v.email} color={v.self ? "primary" : ""}>
                  {v.email}
                  {v.organizer ? " (Organizer)" : ""}
                </Typography>
              );
            })}
          </Box>
        </Box>
      )}
      {data?.reminders?.overrides && data?.reminders?.overrides?.length > 0 && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <NotificationsOutlined className="text-gray-500" />
          </Box>
          <Box py={1}>
            {data?.reminders?.overrides?.map((v, index) => {
              return (
                <Typography key={index}>
                  {v.value}&nbsp;
                  {`${v.type} before`}
                </Typography>
              );
            })}
          </Box>
        </Box>
      )}
      {data.description && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <SubjectOutlined className="text-gray-500" />
          </Box>
          <Box
            py={1}
            component={"div"}
            sx={{
              display: "block",
              whiteSpace: "pre-wrap",
              wordBreak: "break-all",
            }}
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </Box>
      )}
      {data?.attachments?.length > 0 && (
        <Box className="flex gap-2 items-center">
          <Box p={1}>
            <AttachFileOutlined className="text-gray-500" />
          </Box>
          <Box py={1.5}>
            {data?.attachments?.map((v) => {
              return (
                <Button
                  key={v.fileId}
                  component="a"
                  href={v.fileUrl}
                  size="small"
                  color="inherit"
                  startIcon={
                    <img src={v.iconLink} alt="iconLink" className="h-4 w-4" />
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ boxShadow: shadows[2], px: 1.5 }}
                >
                  {v.title}
                </Button>
              );
            })}
          </Box>
        </Box>
      )}
      <Box className="flex gap-2 items-center">
        <Box p={1}>
          <CalendarMonthOutlined className="text-gray-500" />
        </Box>
        <Box py={1}>{data?.creator?.email}</Box>
      </Box>
    </>
  );
};
export default DetailAppointment;
