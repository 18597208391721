import {
  AccessTime,
  Close,
  FmdGoodOutlined,
  GroupOutlined,
  NotificationsOutlined,
  SubjectOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/vi";
import { get } from "lodash";
import * as React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { localeMap } from "../../../App";
import FormControlAutoComplete from "../../../common/SchemaForm/element/autocomplete/FormControlAutoComplete";
import SelectCustom from "../../../common/SchemaForm/element/select/SelectCustom";
import TextEditorElement from "../../../common/SchemaForm/element/text-editor/TextEditorElement";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { EMAIL_REGEX } from "../../../config/regex";
import { LogoMeet } from "../../../svg";
import { IEvent, TimeTypeOptions } from "../constants";

const filter = createFilterOptions<some>();
interface Props {
  formData?: IEvent;
  onSubmit: (formVal: IEvent) => Promise<void>;
}

const EventForm: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData } = props;
  const { API_PATHS } = useGeneralHook();
  const [loading, setLoading] = React.useState(false);
  const methods = useForm<IEvent>({
    defaultValues: formData,
  });
  const { control, watch, setValue } = methods;
  const valuesField = watch();
  const { append, fields, remove } = useFieldArray({
    control: control,
    name: "attendees",
    keyName: "key",
  });

  const {
    append: appendReminders,
    fields: reminders,
    remove: removeReminders,
  } = useFieldArray({
    control: control,
    name: "reminders.overrides",
    keyName: "key",
  });

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={localeMap["en"]}
      >
        <FormProvider {...methods}>
          <Box
            component={"form"}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              flex: 1,
            }}
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              // this part is for stopping parent forms to trigger their submit
              if (event) {
                // sometimes not true, e.g. React Native
                if (typeof event.preventDefault === "function") {
                  event.preventDefault();
                }
                if (typeof event.stopPropagation === "function") {
                  // prevent any outer forms from receiving the event too
                  event.stopPropagation();
                }
              }

              return methods.handleSubmit(async (val) => {
                setLoading(true);
                try {
                  await onSubmit({
                    ...val,
                    attendees: val.attendees?.map((v) => ({
                      id: v.id,
                      email: v.email,
                      displayName: v.displayName,
                    })),
                  });
                } finally {
                  setLoading(false);
                }
              })(event);
            }}
          >
            <Box
              pt={2}
              pr={2}
              sx={{
                bgcolor: "background.paper",
                position: "sticky",
                top: 0,
                zIndex: 10,
                display: "flex",
                pl: 7,
              }}
            >
              <Controller
                name="summary"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      variant="standard"
                      fullWidth
                      autoFocus
                      inputProps={{
                        style: { fontSize: 32, padding: 2 },
                      }}
                      placeholder="Add title and time"
                    />
                  );
                }}
              />
            </Box>
            <Box className="flex items-start sm:w-full">
              <Box px={2} py={4}>
                <AccessTime className="text-gray-500" />
              </Box>
              <Box className="flex flex-col pt-6 mb-6 flex-1">
                <Box className="flex flex-col gap-2">
                  <>
                    <Box className="flex items-center gap-2">
                      <Controller
                        name="start"
                        control={control}
                        render={({
                          field: { value = null, onChange, ...rest },
                        }) => {
                          return (
                            <>
                              <Typography variant="subtitle1" width={40}>
                                <FormattedMessage id="from" />
                              </Typography>
                              <DatePicker<Dayjs>
                                value={value}
                                onChange={(date) => {
                                  if (
                                    valuesField.end &&
                                    date &&
                                    date?.isAfter(valuesField.end)
                                  ) {
                                    setValue("end", date.add(1, "hour"));
                                  }
                                  onChange(date);
                                }}
                                {...rest}
                                sx={{ width: 220, flexShrink: 0 }}
                                disablePast
                              />{" "}
                              {!valuesField.allDay && (
                                <TimePicker
                                  value={value}
                                  onChange={(date) => {
                                    if (
                                      valuesField.start &&
                                      date &&
                                      date?.isBefore(valuesField.start)
                                    ) {
                                      setValue(
                                        "start",
                                        date.subtract(1, "hour")
                                      );
                                    }
                                    onChange(date);
                                  }}
                                  sx={{ width: 120, flexShrink: 0 }}
                                />
                              )}
                            </>
                          );
                        }}
                      />
                    </Box>
                    <Box className="flex items-center gap-2">
                      <Typography variant="subtitle1" width={40}>
                        <FormattedMessage id="to" />
                      </Typography>
                      <Controller
                        name="end"
                        control={control || null}
                        render={({ field: { value, onChange, ...rest } }) => {
                          return (
                            <>
                              <DatePicker<Dayjs>
                                value={value}
                                onChange={(date) => {
                                  if (
                                    valuesField.start &&
                                    date &&
                                    date?.isBefore(valuesField.start)
                                  ) {
                                    setValue("start", date.subtract(1, "hour"));
                                  }
                                  onChange(date);
                                }}
                                {...rest}
                                sx={{ width: 220, flexShrink: 0 }}
                                disablePast
                              />
                              {!valuesField.allDay && (
                                <TimePicker
                                  value={value}
                                  onChange={(date) => {
                                    if (
                                      valuesField.end &&
                                      date &&
                                      date?.isAfter(valuesField.end)
                                    ) {
                                      setValue("end", date.add(1, "hour"));
                                    }
                                    onChange(date);
                                  }}
                                  sx={{ width: 120, flexShrink: 0 }}
                                />
                              )}
                            </>
                          );
                        }}
                      />
                    </Box>
                  </>
                </Box>
                <Box className="flex items-center gap-2">
                  <Controller
                    name="allDay"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControlLabel
                          sx={{ flexShrink: 0 }}
                          control={
                            <Checkbox
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                              sx={{ p: 1 }}
                            />
                          }
                          label="All day"
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="flex">
              <Box px={2} py={1} mt={1}>
                <GroupOutlined className="text-gray-500" />
              </Box>
              <Box
                py={1}
                className="flex flex-col gap-1 flex-1"
                pr={2}
                sx={{ maxWidth: 460 }}
              >
                <FormControlAutoComplete
                  style={{ width: "100%" }}
                  placeholder="Add guest"
                  inputMode="email"
                  loadOptions={{
                    url: (q) => API_PATHS.agents_leads.getList({ q }),
                    mapped: (json) =>
                      json?.data.data
                        ?.map((v) => ({
                          id: v.id,
                          name: v.name,
                          email: v.email,
                          photo_url: v.photo_url,
                        }))
                        .filter((v) => v.email),
                  }}
                  freeSolo
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.email
                    );
                    if (inputValue !== "" && !isExisting) {
                      if (EMAIL_REGEX.test(inputValue))
                        filtered.push({
                          id: -1,
                          inputValue,
                          name: `${inputValue}`,
                        });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }

                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return `${option.name}-${option.email || ""}`;
                  }}
                  onChange={(e, value: any) => {
                    if (typeof value === "string" && EMAIL_REGEX.test(value)) {
                      append({ email: value });
                    } else if (value?.inputValue) {
                      append({ email: value?.inputValue });
                    } else {
                      append(value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
                {fields?.map((item: some, index) => {
                  return (
                    <Box
                      key={item.key}
                      className="flex gap-2 items-center hover:bg-gray-100 rounded p-1 [&>button]:hover:opacity-100"
                    >
                      <Avatar src={item.photo_url} alt="photo_url" />
                      <Typography color={"primary"} flex={1} noWrap>
                        {item.email}
                      </Typography>
                      <IconButton
                        className="opacity-0"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className="flex items-center">
              <Box px={2}>
                <img src={LogoMeet} alt="LogoMeet" className="h-6 w-6" />
              </Box>
              <Box py={1} className="flex flex-col gap-4 flex-1 pr-4">
                <Controller
                  name="useMeet"
                  control={control}
                  defaultValue={false}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{ padding: 4 }}
                              checked={value}
                              onChange={onChange}
                            />
                          }
                          label={"Add Google Meet video conferencing"}
                        />
                      </>
                    );
                  }}
                />
              </Box>
            </Box>
            <Divider />
            <Box className="flex sm:w-full">
              <Box px={2} py={1} mt={1}>
                <FmdGoodOutlined className="text-gray-500" />
              </Box>
              <Box py={1} className="flex flex-col gap-4 flex-1 pr-4">
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        placeholder="Add location"
                        InputProps={{ disableUnderline: true }}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
            <Box className="flex">
              <Box px={2} py={1} mt={1}>
                <NotificationsOutlined className="text-gray-500" />
              </Box>
              <Box py={1} pr={2} className="flex flex-col gap-2">
                {reminders?.map((item: some, index) => {
                  return (
                    <Grid key={item.key} spacing={1} container>
                      <Grid item xs={6} md={true}>
                        <Controller
                          name={`reminders.overrides.${index}.method`}
                          defaultValue={"popup"}
                          control={control}
                          render={({
                            field: { onChange, ...field },
                            fieldState: { invalid },
                          }) => (
                            <SelectCustom
                              {...field}
                              error={invalid}
                              options={[
                                { id: "email", name: "Email" },
                                { id: "popup", name: "Notification" },
                              ]}
                              rawOptions
                              className="sm:w-[80px]"
                              disableClearBtn
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Controller
                          name={`reminders.overrides.${index}.value`}
                          defaultValue={10}
                          control={control}
                          rules={{
                            min: 0,
                            required: true,
                            validate: (value, form) => {
                              const type = get(
                                form,
                                `reminders.overrides.${index}.type`
                              );
                              if (type === "minutes") {
                                return value > 60 * 24 * 7 * 4 ? false : true;
                              }
                              if (type === "hours") {
                                return value > 24 * 7 * 4 ? false : true;
                              }
                              if (type === "days") {
                                return value > 7 * 4 ? false : true;
                              }
                              if (type === "weeks") {
                                return value > 4 ? false : true;
                              }
                              return false;
                            },
                          }}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { invalid },
                          }) => {
                            return (
                              <TextField
                                value={value}
                                onChange={onChange}
                                onBlur={(e) => {
                                  onChange(e.target.value?.split(".")?.[0]);
                                }}
                                inputRef={ref}
                                error={invalid}
                                type="number"
                                fullWidth
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={true}>
                        <Controller
                          name={`reminders.overrides.${index}.type`}
                          defaultValue={"hours"}
                          control={control}
                          rules={{ max: 40320, min: 0, required: true }}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { invalid },
                          }) => {
                            return (
                              <SelectCustom
                                inputRef={ref}
                                error={invalid}
                                value={value}
                                onChange={onChange}
                                options={TimeTypeOptions}
                                rawOptions
                                disableClearBtn
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item md={undefined}>
                        <IconButton
                          onClick={() => {
                            removeReminders(index);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
                {reminders?.length < 5 && (
                  <Button
                    variant="outlined"
                    color="inherit"
                    className="w-fit"
                    onClick={() => {
                      appendReminders({
                        method: "popup",
                        value: 1,
                        type: "hours",
                      });
                    }}
                  >
                    Add notification
                  </Button>
                )}
              </Box>
            </Box>
            <Divider />
            <Box className="flex">
              <Box px={2} py={1} mt={1}>
                <WorkOutlineOutlined className="text-gray-500" />
              </Box>
              <Box py={1} pr={2} className="flex gap-2 flex-1">
                <Controller
                  name={"transparency"}
                  control={control}
                  defaultValue={"opaque"}
                  render={({ field, fieldState: { invalid } }) => (
                    <SelectCustom
                      {...field}
                      error={invalid}
                      options={[
                        { id: "opaque", name: "Busy" },
                        { id: "transparent", name: "Free" },
                      ]}
                      rawOptions
                      formControlProps={{
                        style: { maxWidth: 120, width: "100%" },
                      }}
                      disableClearBtn
                    />
                  )}
                />
                <Controller
                  name={"visibility"}
                  defaultValue={"default"}
                  control={control}
                  render={({
                    field: { onChange, ...field },
                    fieldState: { invalid },
                  }) => (
                    <SelectCustom
                      {...field}
                      error={invalid}
                      options={[
                        { id: "default", name: "Default visibility" },
                        { id: "public", name: "Public" },
                        { id: "private", name: "Private" },
                      ]}
                      rawOptions
                      style={{ maxWidth: 160, width: "100%" }}
                      disableClearBtn
                    />
                  )}
                />
              </Box>
            </Box>
            <Divider />
            <Box className="flex sm:w-full">
              <Box px={2} py={1} mt={1}>
                <SubjectOutlined className="text-gray-500" />
              </Box>
              <Box py={1} className="flex flex-col gap-4 flex-1 pr-4">
                <Controller
                  name={"description"}
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <TextEditorElement
                      {...field}
                      error={invalid}
                      height={200}
                      menubar={false}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              className="flex justify-end items-end p-4 sticky bottom-0 z-10"
              sx={{
                bgcolor: "background.paper",
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                sx={{ minWidth: 160 }}
              >
                <FormattedMessage id="save" />
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </LocalizationProvider>
    </>
  );
};

export default EventForm;
