import { createSlice } from "@reduxjs/toolkit";

export interface CommonState {
  networkErrorMsg: string;
  openErrorDialog: boolean;
  loading: boolean;
}

export const initialStateCommon: CommonState = {
  networkErrorMsg: "",
  openErrorDialog: false,
  loading: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState: initialStateCommon,
  reducers: {
    setNetworkError: (state, action) => {
      state.networkErrorMsg = action.payload.networkErrorMsg;
      state.openErrorDialog = action.payload.openErrorDialog;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setNetworkError, setLoading } = commonSlice.actions;

export default commonSlice.reducer;
