import { Avatar, Box, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { AppState } from "../../redux/store";

const ProfilePage = () => {
  const agentData = useSelector(
    (state: AppState) => state.auth.agentData,
    shallowEqual
  );
  const content = [
    {
      title: "personal_email",
      content: agentData?.personal_email,
    },
    {
      title: "business_email",
      content: agentData?.business_email,
    },
    {
      title: "personal_mobile_number",
      content: agentData?.personal_mobile_number,
    },
    {
      title: "business_mobile_number",
      content: agentData?.business_mobile_number,
    },
    {
      title: "calling_mobile_number",
      content: agentData?.calling_mobile_number,
    },
    {
      title: "cea_number",
      content: agentData?.agent_cea_number,
    },
    {
      title: "agency_name",
      content: agentData?.agent_agency_name,
    },
  ];
  return (
    <Box p={2} overflow={"hidden"}>
      <Paper
        sx={{ p: 2, gap: 2 }}
        className="flex flex-col overflow-auto h-full"
      >
        <Box className="w-fit mx-auto flex flex-col items-center justify-center gap-2">
          <Avatar src={agentData?.photo_url} sx={{ height: 180, width: 180 }} />
          <Typography variant="h3">{agentData?.name}</Typography>
        </Box>

        <Box className="flex flex-col gap-2">
          <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-2">
            {content.map((item, index) => {
              return (
                <Box key={index}>
                  <Typography variant="subtitle1">
                    <FormattedMessage id={item.title} />
                  </Typography>
                  <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                    {item?.content || "-"}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
export default ProfilePage;
