import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { KEYS_QUERY } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../../../common/hook/usePaginationHook";
import ListBox from "./ListBox";

const CallTab = () => {
  const { API_PATHS, dispatch, fetchThunk } = useGeneralHook();
  const { leadId } = useParams<{ leadId: string }>();

  const pagination = usePaginationHook({
    defaultFilter: {
      q: "",
    },
    disableLink: true,
  });

  const {
    object: { params },
  } = pagination;

  const { data, isLoading } = useQuery(
    [params, KEYS_QUERY.listCall],
    async () => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.communications.call({
            direction: params.direction,
            created_at_from: params.rage?.[0],
            created_at_to: params.rage?.[1],
            agent_lead_id: leadId,
          }),
        })
      );
      return json;
    }
  );

  return (
    <>
      <Box className="overflow-hidden flex flex-col">
        <ListBox data={data} loading={isLoading} pagination={pagination} />
      </Box>
    </>
  );
};
export default CallTab;
