import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { AxiosResponse } from "axios";

import { InputBaseProps } from "@mui/material/InputBase";
import React, { ReactNode, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from "react-query";
import { useDebounce } from "usehooks-ts";
import { some } from "../../../constants";
import { api } from "../../../fetchThunk";
import TextFieldElement from "../text-field/TextFieldElement";

export interface FormControlAutoCompletePropsBase {
  label?: React.ReactNode;
  formControlStyle?: React.CSSProperties;
  InputStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  error?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  required?: boolean;
  loadOptions?: {
    url: ((input: string) => QueryKey) | QueryKey;
    mapped?: (value: AxiosResponse) => some[];
    config?: Omit<UseQueryOptions, "queryKey" | "queryFn">;
    queryFn?: QueryFunction<unknown, QueryKey>;
  };
  loadKey?: any;
  disableSearchByText?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  autoFocus?: boolean;
  multiple?: boolean;
  disableClearable?: boolean;
  onChangeInput?: InputBaseProps["onChange"];
  windowScroll?: boolean;
  initialSearch?: string;
  options?: some[];
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

export interface FormControlAutoCompleteProps
  extends Omit<
      AutocompleteProps<
        some,
        boolean | undefined,
        boolean | undefined,
        boolean | undefined
      >,
      "renderInput" | "options"
    >,
    FormControlAutoCompletePropsBase {
  innerRef?: React.Ref<any>;
}

export const FormControlAutoComplete = (
  props: FormControlAutoCompleteProps
) => {
  const {
    label,
    placeholder,
    error,
    formControlStyle,
    required,
    renderInput,
    options = [],
    loadOptions,
    startAdornment,
    endAdornment,
    InputStyle,
    inputStyle,
    labelStyle,
    innerRef,
    readOnly,
    onChangeInput,
    autoFocus,
    windowScroll,
    initialSearch,
    loadKey,
    disableSearchByText,
    loading: loadingProps,
    ...rest
  } = props;
  const [text, setText] = useState("");
  const textDebounce = useDebounce(text, 350);

  const { data, isLoading } = useQuery(
    loadOptions
      ? typeof loadOptions.url === "function"
        ? loadOptions.url(textDebounce)
        : loadOptions.url
      : "",
    loadOptions?.queryFn
      ? loadOptions?.queryFn
      : async ({ queryKey }) => {
          if (queryKey[0]) {
            const json = await api({ url: queryKey[0] as string });
            return loadOptions?.mapped ? loadOptions?.mapped(json) : json;
          }
        },
    loadOptions?.config
  );

  const loading = isLoading || loadingProps;

  const optionsTmp = useMemo(() => {
    if (loadOptions) {
      return data || ([] as any);
    }
    return options;
  }, [data, loadOptions, options]);

  return (
    <Autocomplete<some, any, any, any>
      fullWidth
      loading={loading}
      options={optionsTmp as any}
      onInputChange={(event: object, value: string, reason: string) => {
        if (reason === "input" && !disableSearchByText && loadOptions)
          setText(value);
      }}
      noOptionsText={<FormattedMessage id="noOption" />}
      disabled={readOnly}
      renderInput={
        renderInput ||
        (({ InputProps, ...params }) => (
          <TextFieldElement
            {...params}
            disabled={rest.disabled || false}
            fullWidth
            error={error}
            label={label}
            inputRef={innerRef}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              style: inputStyle,
            }}
            required={required}
            InputProps={{
              ...InputProps,
              readOnly,
              autoFocus,
              sx: InputStyle,
              startAdornment: (
                <>
                  {startAdornment}
                  {InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading && (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  )}
                  {InputProps.endAdornment}
                  {endAdornment}
                </>
              ),
              error,
            }}
            onChange={onChangeInput}
            variant="outlined"
            size="medium"
          />
        ))
      }
      getOptionLabel={(option: any) => (option?.name as string) || " "}
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      autoComplete
      onMouseDownCapture={(e) => !optionsTmp?.length && e.stopPropagation()}
      disableCloseOnSelect={!!rest.multiple}
      // PopperComponent={(val) => <Popper {...val} disablePortal />} dùng khi để fullscreen
      {...(loadOptions && !disableSearchByText
        ? { filterOptions: (option) => option }
        : {})}
      {...rest}
    />
  );
};

export default FormControlAutoComplete;
