import {
  Close,
  DeleteRounded,
  FilePresentOutlined,
  ReplyRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import SchemaForm from "../../../../../common/SchemaForm";
import { IControllerRenderProps } from "../../../../../common/SchemaForm/utils";
import { some } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { EMAIL_REGEX } from "../../../../../config/regex";
import { EmailForm } from "../../../../../redux/leadReducer";

interface Props {
  formData?: EmailForm;
  callback?: () => void;
  methods?: UseFormReturn;
  heightTextEditor?: number;
  onClose: () => void;
}
const EmailSendMessageBox = (props: Props) => {
  const { formData, methods, heightTextEditor, callback, onClose } = props;
  const { dispatch, fetchThunk, API_PATHS, getErrorMessage, enqueueSnackbar } =
    useGeneralHook();
  const sendEmail = async (value: some, methods) => {
    try {
      const form = new FormData();
      form.append("from", value?.from);
      form.append("to", value?.to);
      form.append("reply_to", value?.reply_to);
      form.append("subject", value?.subject);
      form.append("body", value?.body);
      for (let i = 0; i < value?.cc?.length; i++) {
        form.append("cc", value?.cc[i]);
      }
      for (let i = 0; i < value?.bcc?.length; i++) {
        form.append("bcc", value?.bcc[i]);
      }
      for (let i = 0; i < value?.attachment?.length; i++) {
        form.append("attachment", value?.attachment[i]);
      }
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.email.send,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: form,
        })
      );
      enqueueSnackbar({
        message: json.data.data.messsage || (
          <FormattedMessage id={"send_email_success"} />
        ),
        variant: json.status,
      });
      methods?.reset();
      callback && callback();
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };

  return (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <SchemaForm
        methods={methods}
        hideSubmitButton
        onSubmit={sendEmail}
        formData={formData}
        schema={{
          propsGridContainer: { spacing: 0 },
          fields: ({ formProps: { intl, state, setState }, valuesField }) => {
            return {
              to: {
                mode: "text-field",
                placeholder: intl.formatMessage({ id: "enter" }),
                InputProps: {
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ReplyRounded />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!state.showCc && !valuesField.cc && (
                        <IconButton
                          onClick={() => setState({ showCc: !state.showCc })}
                        >
                          <Typography variant="body2">
                            <FormattedMessage id="cc" />
                          </Typography>
                        </IconButton>
                      )}
                      {!state.showBcc && (
                        <IconButton
                          onClick={() => setState({ showBcc: !state.showBcc })}
                        >
                          <Typography variant="body2">
                            <FormattedMessage id="bcc" />
                          </Typography>
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                },
                variant: "standard",
                rules: {
                  required: true,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: intl.formatMessage({ id: "invalid_email" }),
                  },
                },
                noHelperText: true,
              },
              cc: {
                mode: "text-field",
                variant: "standard",
                placeholder: intl.formatMessage({ id: "cc_placeholder" }),
                hidden: !state.showCc && !valuesField.cc,
                InputProps: {
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormattedMessage id="cc" />
                    </InputAdornment>
                  ),
                },
                rules: {
                  validate: (value = "") => {
                    const arr = value?.trim() ? value?.trim()?.split(",") : [];
                    return arr?.length > 0
                      ? arr.every((v) => EMAIL_REGEX.test(v))
                        ? true
                        : intl.formatMessage({ id: "invalid_email" })
                      : true;
                  },
                },
                noHelperText: true,
              },
              bcc: {
                mode: "text-field",
                variant: "standard",
                placeholder: intl.formatMessage({ id: "bcc_placeholder" }),
                hidden: !state.showBcc,
                InputProps: {
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormattedMessage id="bcc" />
                    </InputAdornment>
                  ),
                },
              },
              body: {
                mode: "text-editor",
                height: heightTextEditor || 300,
                rules: { required: true },
                noHelperText: true,
              },
              attachment: {
                mode: "raw",
                render: ({
                  field: { value, onChange },
                }: IControllerRenderProps) => {
                  return (
                    <Box>
                      {value?.length > 0 && (
                        <Grid
                          container
                          spacing={1}
                          style={{ maxHeight: 160, overflow: "auto" }}
                        >
                          {value?.map((file: any, index) => (
                            <Grid item xs={12} sm={6} key={file.path}>
                              <Box
                                display="flex"
                                alignItems="center"
                                border={1}
                                borderColor="divider"
                                justifyContent="space-between"
                                borderRadius={1}
                                paddingX={1}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Typography variant="body2" noWrap>
                                  {file.name} - {Math.round(file.size / 1000)}
                                  KB
                                </Typography>
                                <IconButton
                                  onClick={() => {
                                    onChange(
                                      value?.filter((_, idx) => idx !== index)
                                    );
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      <Box className="flex gap-2 pt-1 ">
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ minWidth: 120 }}
                        >
                          <FormattedMessage id="send" />
                        </Button>
                        <Button
                          variant="outlined"
                          component="label"
                          className="w-10"
                          sx={{ p: 0 }}
                        >
                          <FilePresentOutlined />
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e) => {
                              if (e.target.files)
                                onChange(Array.from(e.target.files));
                            }}
                          />
                        </Button>
                        <Box className="flex items-center flex-1 justify-end">
                          <Button
                            variant="outlined"
                            component="label"
                            className="w-10"
                            sx={{ p: 0 }}
                            onClick={onClose}
                            color="error"
                          >
                            <DeleteRounded />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                },
              },
            };
          },
          changeDataBeforeSubmit: (values) => {
            return {
              ...values,
              cc: values?.cc?.trim()?.split(","),
              bcc: values?.bcc?.trim()?.split(","),
            };
          },
        }}
      />
    </Paper>
  );
};
export default EmailSendMessageBox;
