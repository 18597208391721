import { AxiosResponse } from "axios";
import MuiTable from "../../../../../common/components/MuiTable";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { HookPaginationProps } from "../../../../../common/hook/usePaginationHook";
import CallCard from "../../../../inbox/CallContentBox/CallCard";
import FilterBox from "./FilterBox";

interface Props {
  data?: AxiosResponse;
  loading: boolean;
  pagination: HookPaginationProps;
}
const ListBox = (props: Props) => {
  const { data, loading, pagination } = props;
  const { onPageChange, onRowsPerPageChange } = pagination;
  const { intl } = useGeneralHook();
  const source = data?.data?.data || [];
  return (
    <>
      <MuiTable
        data={source}
        state={{
          isLoading: loading,
        }}
        renderTopToolbarCustomActions={() => {
          return <FilterBox pagination={pagination} />;
        }}
        muiTableHeadRowProps={{ sx: { display: "none" } }}
        enableHiding={false}
        paginationProps={{
          count: data?.data?.count,
          page: data?.data?.metadata.page_no,
          rowsPerPage: data?.data?.metadata.page_size,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        columns={[
          {
            accessorKey: "name",
            header: intl.formatMessage({ id: "lead_name" }),
            Cell: ({ row }) => {
              const record = row.original;
              return <CallCard info={record} sm={3} />;
            },
          },
        ]}
      />
    </>
  );
};
export default ListBox;
