import { EmojiEmotionsOutlined } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import emojis from "emojis-list";
import React from "react";

interface Props {
  onSelectEmoji?: (emoji: string) => void;
  containerRef?: any;
  disabled?: boolean;
}
const EmojiPicker = (props: Props) => {
  const { onSelectEmoji, containerRef, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : containerRef || event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "emoji-popper" : undefined;
  const ListEmoji = [
    ...emojis.slice(1749, 1817),
    ...emojis.slice(2211, 2219),
    ...emojis.slice(2416, 2422),
  ];

  return (
    <>
      <IconButton onClick={handleClick} size="small" disabled={disabled}>
        <EmojiEmotionsOutlined />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        className="z-50"
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              width: 360,
              maxHeight: 320,
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              overflow: "auto",
            }}
            style={{ marginBottom: 12 }}
          >
            {ListEmoji.map((emoji) => {
              return (
                <Button
                  key={emoji}
                  variant="text"
                  sx={{ minWidth: 0, p: 0, px: 0.5 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectEmoji && onSelectEmoji(emoji);
                    setAnchorEl(null);
                  }}
                >
                  <Typography variant="h4">{emoji}</Typography>
                </Button>
              );
            })}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
export default EmojiPicker;
