import {
  AttachFile,
  InsertDriveFileRounded,
  PhotoSizeSelectActualRounded,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import SchemaForm from "../../../../../common/SchemaForm";
import DialogCustom from "../../../../../common/components/DialogCustom";
import ImageDialog from "../../../../../common/components/ImageDialog";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
export interface FormDataUploadFile {
  url: string;
  caption?: string;
  type?: string;
}
interface Props {
  onChange: (value: FormDataUploadFile) => Promise<void>;
  maxSize?: number;
  disabled?: boolean;
}
const UploadButton = (props: Props) => {
  const { onChange, disabled } = props;
  const { API_PATHS, dispatch, fetchThunk, enqueueSnackbar } = useGeneralHook();
  const [formData, setFormData] = useState<FormDataUploadFile | undefined>(
    undefined
  );
  const [key, setKey] = useState(false);

  const onSubmit = useCallback(
    async (values: FormDataUploadFile) => {
      try {
        await onChange(values);
      } finally {
        setFormData(undefined);
      }
    },
    [onChange]
  );

  const onUpload = async (files: FileList | null) => {
    if (!files) {
      return;
    }
    setKey((old) => !old);
    const fileTmp = files[0];
    const formData = new FormData();
    formData.append("file", fileTmp);

    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.whatsapp.upload,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          method: "POST",
        })
      );
      setFormData({
        url: json.data?.data?.url,
        caption: json.data?.metadata?.file.Filename,
        type: fileTmp.type.includes("image")
          ? "image"
          : fileTmp.type.includes("video")
          ? "video"
          : fileTmp.type.includes("audio")
          ? "audio"
          : "file",
      });
    } catch (e: any) {
      e.response?.data?.errors?.forEach((v) =>
        enqueueSnackbar({
          message: v.message,
          variant: "error",
        })
      );
    }
  };

  return (
    <>
      <IconButton size="small" component="label" disabled={disabled}>
        <AttachFile />
        <input
          key={String(key)}
          hidden
          accept={"*"}
          type="file"
          disabled={disabled}
          onChange={(e) => onUpload(e.target.files)}
        />
      </IconButton>
      <DialogCustom
        open={!!formData}
        onClose={() => setFormData(undefined)}
        title="upload"
        fullWidth
      >
        <Box p={2}>
          <SchemaForm<FormDataUploadFile>
            formData={formData}
            onSubmit={onSubmit}
            schema={{
              fields: ({ valuesField }) => ({
                url: {
                  mode: "raw",
                  render: ({ field: { value } }) => {
                    return valuesField?.type.includes("image") ? (
                      <ImageDialog
                        src={value}
                        variant="rounded"
                        sx={{ height: 260, width: "100%" }}
                        imgProps={{ sx: { objectFit: "contain" } }}
                        className=" my-2 text-white flex items-center justify-center rounded-lg"
                      >
                        <PhotoSizeSelectActualRounded
                          style={{ fontSize: 80 }}
                        />
                      </ImageDialog>
                    ) : valuesField?.type.includes("video") ? (
                      <video controls style={{ height: 260, width: "100%" }}>
                        <source src={value} type="video/mp4" />
                      </video>
                    ) : (
                      <ImageDialog
                        variant="rounded"
                        sx={{ height: 260, width: "100%" }}
                        className=" my-2 text-white flex items-center justify-center rounded-lg"
                      >
                        <InsertDriveFileRounded style={{ fontSize: 80 }} />
                      </ImageDialog>
                    );
                  },
                },
                caption: {
                  mode: "hidden",
                },
              }),
            }}
          />
        </Box>
      </DialogCustom>
    </>
  );
};
export default UploadButton;
