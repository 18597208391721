import { Box, Button, Typography } from "@mui/material";
import { getWhatsappText } from "../../../../../common/components/elements";
import { some } from "../../../../../common/constants";
import FooterMessage from "./FooterMessage";

interface Props {
  message: some;
  isOwner?: boolean;
  readOnly?: boolean;
}

const InteractiveItem = (props: Props) => {
  const { message, readOnly, isOwner } = props;
  const text = message.content?.interactive;
  const content = (
    <>
      <Typography variant="body1" maxWidth={360}>
        {getWhatsappText(
          text?.type === "button_reply" ? text?.reply?.text : text?.body?.text
        )}
      </Typography>
      <FooterMessage message={message} isOwner={isOwner} />
    </>
  );

  if (readOnly) {
    return content;
  }

  return (
    <>
      {content}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
        {text?.action?.buttons?.map((item) => {
          return (
            <Button key={item.id} variant="outlined" disableRipple>
              <Typography color={"inherit"} noWrap maxWidth={160}>
                {item.title}
              </Typography>
            </Button>
          );
        })}
      </Box>
    </>
  );
};

export default InteractiveItem;
