import { DateRange } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import {
  CalendarIcon,
  DateRangeCalendar,
  DateRangeCalendarProps,
  SingleInputDateTimeRangeField,
  SingleInputDateTimeRangeFieldProps,
  TimePicker,
} from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import React, { memo, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DATE_RAGE_OPTIONS } from "../../../constants";
import useGeneralHook from "../../../hook/useGeneralHook";

export interface PropsDateRangePickerElement
  extends Omit<
    DateRangeCalendarProps<Dayjs>,
    "value" | "onChange" | "defaultValue"
  > {
  required?: boolean;
  error?: boolean;
  readOnly?: boolean;
  textFieldProps?: Omit<SingleInputDateTimeRangeFieldProps<Dayjs>, "ref">;
  value: DateRange<string>;
  onChange: React.Dispatch<React.SetStateAction<DateRange<string>>>;
  label?: React.ReactNode;
}

const DateRangePickerElement = React.forwardRef<
  HTMLInputElement,
  PropsDateRangePickerElement
>((props: PropsDateRangePickerElement, ref) => {
  const {
    required,
    error,
    textFieldProps,
    readOnly,
    value = [null, null],
    onChange,
    label,
    sx,
    ...rest
  } = props;
  const { isMobile } = useGeneralHook();
  const componentProps = {
    ...rest,
    sx: { ...sx, "&>:first-of-type": { display: "none" } },
  } as DateRangeCalendarProps<Dayjs>;
  const inputRef = useRef<any>(null);
  const btnRef = useRef<any>(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [range, setRage] = useState<number | undefined>();
  const [rangePosition, setRagePosition] = useState<"start" | "end">("start");
  const [focus, setFocus] = useState(false);
  const time = rangePosition === "start" ? value?.[0] : value?.[1];

  useEffect(() => {
    const idx = DATE_RAGE_OPTIONS().findIndex((one) => {
      return value?.[0] &&
        dayjs(one.value.from).isSame(dayjs(value?.[0])) &&
        value?.[1] &&
        dayjs(one.value.to).isSame(dayjs(value?.[1]))
        ? true
        : false;
    });
    setRage(idx);
  }, [value]);
  const startLabel =
    range && DATE_RAGE_OPTIONS()[range]?.name ? (
      <FormattedMessage id={DATE_RAGE_OPTIONS()[range]?.name} />
    ) : (
      ""
    );
  return (
    <>
      <Box ref={inputRef}>
        <SingleInputDateTimeRangeField<Dayjs>
          {...textFieldProps}
          label={label}
          required={required}
          fullWidth
          readOnly={readOnly}
          InputProps={{
            error: error,
            inputRef: ref,
            onFocus: () => {
              setOpenPopper(false);
            },
            startAdornment: startLabel && (
              <InputAdornment position="start">{startLabel}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  ref={btnRef}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenPopper(true);
                  }}
                >
                  <CalendarIcon />
                </IconButton>
              </InputAdornment>
            ),
            ...textFieldProps?.InputProps,
          }}
          ampm={false}
          key={focus.toString()}
          defaultValue={
            value?.map((v) => (v ? dayjs(v) : null)) as DateRange<Dayjs>
          }
          value={
            openPopper
              ? (value?.map((v) => (v ? dayjs(v) : null)) as DateRange<Dayjs>)
              : undefined
          }
          onChange={(value, context) => {
            onChange([
              value?.[0] && context.validationError[0] === null
                ? value?.[0]?.toISOString()
                : null,
              value?.[1] && context.validationError[1] === null
                ? value?.[1]?.toISOString()
                : null,
            ]);
          }}
        />
      </Box>
      <ClickAwayListener
        onClickAway={(e: any) => {
          if (!btnRef.current?.contains(e.target as any)) {
            setOpenPopper(false);
          }
          setFocus((old) => !old);
        }}
      >
        <Popper
          open={openPopper}
          anchorEl={inputRef.current}
          sx={{ zIndex: 2100 }}
          placement="auto"
        >
          <Paper
            sx={{
              mt: 1,
              display: "flex",
              overflow: "auto",
              my: 1,
              height: 428,
            }}
            elevation={2}
            className="flex flex-col sm:flex-row sm:pr-4"
          >
            <Box className="flex sm:flex-col gap-2 p-4 pr-2 overflow-auto">
              <Button
                variant={"outlined"}
                size="small"
                sx={{ textAlign: "start" }}
                onClick={() => {
                  setRage(undefined);
                  onChange([null, null]);
                }}
                disabled={readOnly}
              >
                <FormattedMessage id={"reset"} />
              </Button>
              {DATE_RAGE_OPTIONS()?.map((item, index) => {
                return (
                  <Button
                    key={index}
                    disabled={readOnly}
                    variant={range === index ? "contained" : "outlined"}
                    size="small"
                    sx={{ textAlign: "start", flexShrink: 0 }}
                    onClick={() => {
                      setRage(index);
                      onChange([item.value.from, item.value.to]);
                    }}
                  >
                    <FormattedMessage id={item.name || ""} />
                  </Button>
                );
              })}
            </Box>
            <Box className="sm:pt-4 pl-2">
              <Box className="flex gap-2">
                <ButtonGroup>
                  <Button
                    variant={
                      rangePosition === "start" ? "contained" : "outlined"
                    }
                    onClick={() => setRagePosition("start")}
                  >
                    <FormattedMessage id="start" />
                  </Button>
                  <Button
                    variant={rangePosition === "end" ? "contained" : "outlined"}
                    onClick={() => setRagePosition("end")}
                  >
                    <FormattedMessage id="end" />
                  </Button>
                </ButtonGroup>
                <TimePicker<Dayjs>
                  key={rangePosition}
                  readOnly={readOnly}
                  ampm={false}
                  slotProps={{ popper: { sx: { zIndex: 2300 } } }}
                  defaultValue={
                    time && dayjs(time).isValid() ? dayjs(time) : null
                  }
                  onChange={(val) => {
                    if (!val || val.isValid()) {
                      return;
                    }
                    if (rangePosition === "start") {
                      onChange([val?.toISOString(), value?.[1]]);
                    } else {
                      onChange([value?.[0], val?.toISOString()]);
                    }
                  }}
                />
              </Box>
              <DateRangeCalendar<Dayjs>
                {...componentProps}
                readOnly={readOnly}
                key={range}
                value={
                  value?.map((v) => (v ? dayjs(v) : null)) as DateRange<Dayjs>
                }
                onChange={(value) => {
                  setRage(undefined);
                  onChange([
                    value?.[0] ? value?.[0]?.toISOString() : null,
                    value?.[1] ? value?.[1].endOf("day")?.toISOString() : null,
                  ]);
                }}
                rangePosition={rangePosition}
                onRangePositionChange={setRagePosition}
                calendars={isMobile ? 1 : 2}
              />
            </Box>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
});
export default memo(DateRangePickerElement);
