import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import Popper, { PopperProps } from "@mui/material/Popper";
import * as React from "react";
interface Props {
  content?:
    | ((handleClickMenu: (e: any) => void) => React.ReactElement)
    | React.ReactElement;
  popperProps?: Omit<PopperProps, "open" | "anchorEl">;
  children?: React.ReactNode;
}

const PopperWrapper: React.FunctionComponent<Props> = (props) => {
  const { children, popperProps, content } = props;
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(anchorElMenu ? null : event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  const openMenu = Boolean(anchorElMenu) && !!children;

  return (
    <>
      {typeof content === "function" ? (
        content && content((e) => handleClickMenu(e))
      ) : (
        <Box onClick={handleClickMenu}>{content}</Box>
      )}
      <Popper
        open={openMenu}
        anchorEl={anchorElMenu}
        transition
        {...popperProps}
        style={{ zIndex: 100, ...popperProps?.style }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <Fade {...TransitionProps} timeout={350}>
              <Box> {children}</Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default PopperWrapper;
