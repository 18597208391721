import {
  CheckRounded,
  CloseRounded,
  DoneAllRounded,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import dayjs from "dayjs";

const FooterMessage = ({ message, isOwner = false }) => {
  return (
    <>
      <Typography
        variant="caption"
        textAlign={"end"}
        color={"textSecondary"}
        component={"div"}
        alignItems={"center"}
        display={"flex"}
        justifyContent={"flex-end"}
        gap={0.5}
      >
        {message.createdDatetime
          ? dayjs(message.createdDatetime).format("LL")
          : ""}
        {isOwner && (
          <>
            {message.error ? (
              <CloseRounded color="error" sx={{ fontSize: 14 }} />
            ) : message.status === "delivered" ? (
              <DoneAllRounded color="disabled" sx={{ fontSize: 14 }} />
            ) : message.status === "read" ? (
              <DoneAllRounded color="info" sx={{ fontSize: 14 }} />
            ) : (
              <CheckRounded color="disabled" sx={{ fontSize: 14 }} />
            )}
          </>
        )}
      </Typography>
      {message.error?.code && (
        <Typography
          variant="caption"
          textAlign={"end"}
          color={"error"}
          component={"a"}
          href={
            "https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/#error-codes"
          }
          target="_blank"
          display={"flex"}
          flexDirection={"column"}
          rel="noreferrer noopener"
        >
          Error code: {message.error?.code}
        </Typography>
      )}
    </>
  );
};
export default FooterMessage;
