import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { setOpenAsideBarMobile } from "../../redux/cacheReducer";
import DefaultBreadcrumb from "./DefaultBreadcrumb";
import ProfileBox from "./ProfileBox";
import { HEADER_PORTAL_ID, HEADER_TOOLBAR_PORTAL_ID } from "./constants";
import Menu from "@mui/icons-material/Menu";

const DefaultHeader = () => {
  const { dispatch, isMobile } = useGeneralHook();

  return (
    <>
      <Box
        className="flex items-center sm:px-4 gap-2 sm:gap-4 z-10 px-2"
        sx={{
          bgcolor: "background.paper",
          borderBottom: 1,
          borderColor: "divider",
          minHeight: 54,
        }}
      >
        {isMobile ? (
          <>
            <Button
              variant="outlined"
              sx={{ p: 1, height: 40 }}
              onClick={() => {
                dispatch(setOpenAsideBarMobile());
              }}
            >
              <Menu />
            </Button>
            <Box
              className="flex items-center flex-1 overflow-auto gap-2 sm:gap-4 h-full"
              id={HEADER_PORTAL_ID}
            />
          </>
        ) : (
          <>
            <DefaultBreadcrumb />
            <Box
              className="flex items-center flex-1 overflow-auto gap-2 sm:gap-4 h-full"
              id={HEADER_PORTAL_ID}
            />
          </>
        )}
        {!isMobile && <ProfileBox />}
      </Box>
      {isMobile && (
        <Box id={HEADER_TOOLBAR_PORTAL_ID} bgcolor={"background.paper"} />
      )}
    </>
  );
};
export default DefaultHeader;
