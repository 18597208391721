import { Box, Typography } from "@mui/material";
import { getWhatsappText } from "../../../../../common/components/elements";
import { some } from "../../../../../common/constants";
import FooterMessage from "./FooterMessage";

interface Props {
  message: some;
  isOwner?: boolean;
  readOnly?: boolean;
}

const AudioMessageItem = (props: Props) => {
  const { message, readOnly, isOwner } = props;

  if (readOnly) {
    return (
      <Box className="flex gap-2">
        <audio controls>
          <source src={message?.content?.audio?.url} type="audio/ogg"></source>
        </audio>
        {message?.content?.audio?.caption && (
          <Typography variant="body1">
            {getWhatsappText(message?.content?.audio?.caption)}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <audio controls>
        <source src={message?.content?.audio?.url} type="audio/ogg"></source>
      </audio>
      {message?.content?.audio?.caption && (
        <Typography variant="body1" maxWidth={360} mt={1}>
          {getWhatsappText(message?.content?.audio?.caption)}
        </Typography>
      )}
      <FooterMessage message={message} isOwner={isOwner} />
    </>
  );
};

export default AudioMessageItem;
