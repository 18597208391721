/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
// Hooks + Common
import { FilterAltRounded, Refresh } from "@mui/icons-material";
import { Badge, Box, Button } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import SchemaForm from "../../../../../common/SchemaForm";
import DateRangePickerElement from "../../../../../common/SchemaForm/element/date-range/DateRangePickerElement";
import ButtonTooltip from "../../../../../common/components/ButtonTooltip";
import DialogCustom from "../../../../../common/components/DialogCustom";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { HookPaginationProps } from "../../../../../common/hook/usePaginationHook";
import { countActiveFilter } from "../../../../../common/utils";

interface Props {
  pagination: HookPaginationProps;
}
const FilterBox = (props: Props) => {
  const { pagination } = props;
  const { isMobile } = useGeneralHook();
  const {
    setParams,
    clearParams,
    object: { filter },
  } = pagination;
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <DateRangePickerElement
          value={filter.range}
          onChange={(e) => {
            setParams({ range: e });
          }}
          textFieldProps={{
            sx: { width: isMobile ? undefined : 390 },
          }}
        />
        <Badge
          badgeContent={countActiveFilter(filter, [
            "types",
            "agent_lead_ids",
            "agent_ids",
          ])}
          color="primary"
        >
          <ButtonTooltip
            tooltipProps={{ title: <FormattedMessage id="filters" /> }}
            variant="outlined"
            onClick={handleClick}
            sx={{ height: "fit-content", px: 1 }}
          >
            <FilterAltRounded />
          </ButtonTooltip>
        </Badge>
        <Button
          variant="text"
          sx={{ height: "fit-content" }}
          onClick={() => clearParams()}
        >
          <Refresh />
        </Button>
      </Box>
      <DialogCustom
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        title="filter"
      >
        <Box p={2}>
          <SchemaForm
            formData={filter}
            onSubmit={(value) => {
              setParams(value);
              handleClose();
            }}
            onCancel={handleClose}
            onReset={() => {
              clearParams({ q: filter.q });
              handleClose();
            }}
            schema={{
              fields: ({ formProps: { intl } }) => {
                return {
                  direction: {
                    mode: "select",
                    label: intl.formatMessage({ id: "direction" }),
                    placeholder: intl.formatMessage({ id: "select" }),
                    options: ["inbound", "outbound"].map((option) => ({
                      id: option,
                      name: option,
                    })),
                  },
                };
              },
            }}
          />
        </Box>
      </DialogCustom>
    </>
  );
};
export default FilterBox;
