/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
// Hooks + Common
import {
  CloseRounded,
  FilterAltRounded,
  Refresh,
  SearchRounded,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import SchemaForm from "../../../../../common/SchemaForm";
import DialogCustom from "../../../../../common/components/DialogCustom";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { HookPaginationProps } from "../../../../../common/hook/usePaginationHook";
import { countActiveFilter } from "../../../../../common/utils";
import { FormattedMessage } from "react-intl";
import ButtonTooltip from "../../../../../common/components/ButtonTooltip";

interface Props {
  pagination: HookPaginationProps;
}
const FilterBox = (props: Props) => {
  const { pagination } = props;
  const { intl } = useGeneralHook();
  const {
    setParams,
    clearParams,
    object: { filter },
  } = pagination;
  const [term, setTerm] = useState("");
  const [open, setOpen] = useState(false);

  const onChangeSearch = useCallback(
    debounce((q) => {
      setParams({ q });
    }, 350),
    [setParams]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTerm(filter.q || "");
  }, [filter.q]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          flex: 1,
        }}
      >
        <TextField
          value={term}
          fullWidth
          placeholder={intl.formatMessage({
            id: "search",
          })}
          onChange={(e) => {
            setTerm(e.target.value);
            onChangeSearch(e.target.value);
          }}
          style={{ width: "fit-content" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  sx={{ opacity: term ? 1 : 0 }}
                  disabled={!term}
                  onClick={() => {
                    setTerm("");
                    setParams({ q: "" });
                  }}
                >
                  <CloseRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Badge
          badgeContent={countActiveFilter(filter, [
            "types",
            "agent_lead_ids",
            "agent_ids",
          ])}
          color="primary"
        >
          <ButtonTooltip
            tooltipProps={{ title: <FormattedMessage id="filters" /> }}
            variant="outlined"
            onClick={handleClick}
            sx={{ height: "fit-content", px: 1 }}
          >
            <FilterAltRounded />
          </ButtonTooltip>
        </Badge>
        <Button
          variant="text"
          sx={{ height: "fit-content" }}
          onClick={() => clearParams()}
        >
          <Refresh />
        </Button>
      </Box>
      <DialogCustom
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        title="filter"
      >
        <Box p={2}>
          <SchemaForm
            formData={filter}
            onSubmit={(value) => {
              setParams(value);
              handleClose();
            }}
            onCancel={handleClose}
            onReset={() => {
              clearParams({ q: filter.q });
              handleClose();
            }}
            schema={{
              fields: ({ formProps: { intl } }) => {
                return {
                  range: {
                    mode: "dateRange",
                    label: intl.formatMessage({ id: "time" }),
                  },
                  direction: {
                    mode: "select",
                    label: intl.formatMessage({ id: "direction" }),
                    placeholder: intl.formatMessage({ id: "select" }),
                    options: ["inbound", "outbound"].map((option) => ({
                      id: option,
                      name: option,
                    })),
                  },
                };
              },
            }}
          />
        </Box>
      </DialogCustom>
    </>
  );
};
export default FilterBox;
