import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useRoutes } from "react-router";
import AppointmentEventFormDialog from "../../common/components/AppointmentEventFormDialog";
import LoadingIcon from "../../common/components/LoadingIcon";
import { AppState } from "../../redux/store";
import { PROJECT_LIST_ROUTES } from "../routes";
import DefaultAside from "./DefaultAside";
import DefaultHeader from "./DefaultHeader";
import { getVisibleRoutes } from "./utils";

const ProcessLoading = React.memo(
  () => {
    const loading = useSelector(
      (state: AppState) => state.common.loading,
      shallowEqual
    );
    if (loading) {
      return (
        <LinearProgress
          sx={{ position: "fixed", top: 0, right: 0, left: 0, zIndex: 100 }}
        />
      );
    }
    return null;
  },
  () => true
);

interface Props {}

const ProjectLayout = (props: Props) => {
  const agentData = useSelector((state: AppState) => state.auth.agentData);
  const visibleRoutes = getVisibleRoutes(agentData, PROJECT_LIST_ROUTES) as any;
  const visibleRouteElements = useRoutes(visibleRoutes);

  return (
    <>
      <Box display="flex" bgcolor={"background.default"}>
        <DefaultAside listRouter={visibleRoutes} />
        <Box className="relative flex-1 h-screen">
          <Box className="absolute top-0 h-screen w-screen sm:w-full sm:inset-0  flex-1 flex flex-col overflow-hidden">
            <DefaultHeader />
            <ProcessLoading />
            <React.Suspense fallback={<LoadingIcon />}>
              {visibleRouteElements}
            </React.Suspense>
          </Box>
        </Box>
        <Box id="aside-right" />
      </Box>
      <AppointmentEventFormDialog />
    </>
  );
};

export default ProjectLayout;
