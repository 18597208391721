import { InsertDriveFileRounded } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import MyLink from "../../../../../common/components/MyLink";
import { getWhatsappText } from "../../../../../common/components/elements";
import { some } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import FooterMessage from "./FooterMessage";

interface Props {
  message: some;
  isOwner?: boolean;
  readOnly?: boolean;
}

const FileMessageItem = (props: Props) => {
  const { message, readOnly, isOwner } = props;
  const { dispatch, fetchThunk } = useGeneralHook();

  const { data: infoFile, isLoading } = useQuery(
    message?.content?.file?.url,
    async ({ queryKey }) => {
      try {
        const json = await dispatch(
          fetchThunk({
            url: queryKey[0].replace(
              "https://messaging.messagebird.com/",
              "/msb-mes/"
            ),
            responseType: "blob",
          })
        );
        return json;
      } catch (e) {}
    }
  );

  if (readOnly) {
    return (
      <Box className="flex gap-2">
        <Box sx={{ color: "text.secondary" }}>
          <InsertDriveFileRounded style={{ fontSize: 32 }} />
        </Box>
        <Typography variant="body1">
          {getWhatsappText(message?.content?.file?.caption)}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <MyLink
        href={message?.content?.file?.url}
        target="_blank"
        rel="noreferrer noopener"
        className="flex items-center gap-2"
        underline="none"
        sx={{
          borderRadius: 2,
          p: 1,
        }}
      >
        <InsertDriveFileRounded style={{ fontSize: 32 }} />
        {isLoading ? (
          <Box sx={{ alignSelf: "center", height: 21 }}>
            <CircularProgress size={21} color={"primary"} />
          </Box>
        ) : (
          infoFile && (
            <Box>
              <Typography sx={{ wordBreak: "break-all" }}>
                {infoFile?.headers?.["content-disposition"]
                  ?.split("filename=")[1]
                  .split(";")[0]
                  .replaceAll('"', "")}
              </Typography>
              <Typography noWrap variant="caption">
                {Math.round(infoFile?.data?.size / 1000)}kB
              </Typography>
            </Box>
          )
        )}
      </MyLink>
      {message?.content?.file?.caption && (
        <Typography
          variant="body1"
          mt={1}
          sx={{ wordBreak: "break-all", maxWidth: 320 }}
        >
          {getWhatsappText(message?.content?.file?.caption)}
        </Typography>
      )}
      <FooterMessage message={message} isOwner={isOwner} />
    </>
  );
};

export default FileMessageItem;
