import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { some } from "../common/constants";

export interface InboxState {
  currentInbox?: some;
  selection: number[];
}

export const initialStateInbox: InboxState = { selection: [] };

export const inboxSlice = createSlice({
  name: "auth",
  initialState: initialStateInbox,
  reducers: {
    setCurrentInbox: (state, action: PayloadAction<some | undefined>) => {
      state.currentInbox = action.payload;
    },
    addSelection: (state, action: PayloadAction<number>) => {
      if (state.selection?.includes(action.payload)) {
        state.selection = state.selection?.filter((v) => v !== action.payload);
      } else {
        state.selection = [...(state.selection || []), action.payload];
      }
    },
    removeSelection: (state) => {
      state.selection = [];
    },
  },
});

export const { setCurrentInbox, addSelection, removeSelection } =
  inboxSlice.actions;

export default inboxSlice.reducer;
