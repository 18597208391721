export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const E164_REGEX = /^\+[1-9]\d{1,14}$/;
export const PARAMS_TEMPLATE_REGEX = /{{[a-z_]*}}/g;
export const PARAMS_EMAIL_TEMPLATE_REGEX = /{{[0-9a-zA-Z]*}}/g;
export const PHONE_INPUT_NUMBER_REGEX = /[+0-9]$/g;
export const BOLD_TEXT_REGEX = /\*(.+?)\*/g;
export const LINE_THROUGH_TEXT_REGEX = /~(.+?)~/g;
export const ITALIC_TEXT_REGEX = /_(.+?)_/g;
export const WEB_URL_REGEX = /\b(?:https?:\/\/|www\.)\S+\b/g;
