import { Box, Button, ButtonBase, Typography, alpha } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { API_PATHS } from "../../../../../api/gomedia";
import DialogCustom from "../../../../../common/components/DialogCustom";
import LoadingIcon from "../../../../../common/components/LoadingIcon";
import { KEYS_QUERY, some } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../../../common/hook/usePaginationHook";
import { TEMPLATE_TYPE } from "../../../../template/constants";

const DetailContent = (props: {
  formData: some;
  onSubmit: (value: string) => void;
  onClose: () => void;
}) => {
  const { formData, onClose, onSubmit } = props;
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();
  const { data, isFetching } = useQuery(
    KEYS_QUERY.emailTemplateDetail,
    async () => {
      const json = await dispatch(
        fetchThunk({ url: API_PATHS.email.templateDetail(formData?.name) })
      );
      return json?.data;
    }
  );

  if (isFetching) {
    return <LoadingIcon />;
  }
  return (
    <>
      <Box
        component={"div"}
        sx={{ p: 2, pt: 0, whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: data?.data?.version?.template }}
      />
      <Box className="flex gap-2 justify-end p-4">
        <Button variant="outlined" sx={{ width: 100 }} onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          variant="contained"
          sx={{ width: 120 }}
          onClick={() => onSubmit(data?.data?.version?.template)}
        >
          <FormattedMessage id="apply" />
        </Button>
      </Box>
    </>
  );
};
interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

const EmailPickingTemplateDialog = (props: Props) => {
  const { onClose, onSubmit, open } = props;
  const { isMobile, dispatch, fetchThunk } = useGeneralHook();
  const [selection, setSelection] = useState<some | undefined>();
  const {
    object: { params },
  } = usePaginationHook({
    disablePagination: true,
    disableLink: true,
    defaultFilter: {
      template_provider: "MAILGUN",
      template_type: TEMPLATE_TYPE.personal,
    },
  });

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery(
    "templates-email",
    async () => {
      const json = await dispatch(
        fetchThunk({ url: API_PATHS.templates.getList(params) })
      );
      return json?.data?.data;
    },
    { enabled: open }
  );

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  return (
    <DialogCustom
      open={!!open}
      onClose={() => {
        if (selection) {
          setSelection(undefined);
        } else {
          onClose();
        }
      }}
      title={"templates"}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      {selection ? (
        <>
          <DetailContent
            formData={selection}
            onSubmit={(value) => {
              onSubmit(value);
              setSelection(undefined);
              onClose();
            }}
            onClose={() => setSelection(undefined)}
          />
        </>
      ) : (
        <Box>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <>
              {data?.map((item, index) => {
                return (
                  <ButtonBase
                    key={index}
                    sx={(theme) => ({
                      justifyContent: "flex-start",
                      width: "100%",
                      px: 2,
                      py: 1,
                      ":hover": {
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                      },
                    })}
                    onClick={() => setSelection(item)}
                  >
                    <Typography variant="subtitle1">
                      {item.description}
                    </Typography>
                  </ButtonBase>
                );
              })}
            </>
          )}
        </Box>
      )}
    </DialogCustom>
  );
};
export default EmailPickingTemplateDialog;
