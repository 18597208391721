import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { some } from "../common/constants";

export interface EmailForm {
  id?: number;
  from?: string;
  to?: string;
  reply_to?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
  attachment?: File[];
}

export interface LeadState {
  leadDetail?: some;
  createEmail: EmailForm[];
}

export const initialStateLead: LeadState = {
  createEmail: [],
};

export const leadSlice = createSlice({
  name: "auth",
  initialState: initialStateLead,
  reducers: {
    setLeadDetail: (state, action) => {
      state.leadDetail = action.payload;
    },
    setEmailForm: (state, action: PayloadAction<EmailForm[]>) => {
      state.createEmail = action.payload;
    },
    addEmailForm: (state, action: PayloadAction<EmailForm>) => {
      state.createEmail = [action.payload, ...(state.createEmail || [])];
    },
  },
});

export const { addEmailForm, setEmailForm, setLeadDetail } = leadSlice.actions;

export default leadSlice.reducer;
