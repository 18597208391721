import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { setLoading } from "../redux/commonReducer";
import { AppState } from "../redux/store";
import { GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN } from "./constants";

const request = axios.create({
  headers: {
    "accept-language": "en",
    "Content-Type": "application/json",
  },
  method: "get",
  withCredentials: false,
});

function fetchGoogle(
  options: AxiosRequestConfig,
  loading = true
): ThunkAction<Promise<AxiosResponse<any, any>>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    if (loading) dispatch(setLoading(true));
    try {
      const json = await request({
        withCredentials: false,
        headers: {
          Authorization:
            "Bearer " +
            (getState().auth.userData?.access_token ||
              Cookies.get(GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN)),
        },
        ...options,
      });
      return json || {};
    } catch (e: any) {
      throw e;
    } finally {
      if (loading) dispatch(setLoading(false));
    }
  };
}

export default fetchGoogle;
