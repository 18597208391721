import { ArrowBack, Check } from "@mui/icons-material";
import { Box, ButtonBase, Typography } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";

import SchemaForm from "../../../../../../common/SchemaForm";
import DialogCustom from "../../../../../../common/components/DialogCustom";
import LoadingIcon from "../../../../../../common/components/LoadingIcon";
import NotFoundBox from "../../../../../../common/components/NotFoundBox";
import { KEYS_QUERY, some } from "../../../../../../common/constants";
import useGeneralHook from "../../../../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../../../../common/hook/usePaginationHook";
import PreviewTemplateBox from "../../../../../template/messagebird/form/PreviewTemplateBox";
import {
  SchemaFormTemplate,
  mappedTemplateForm,
} from "../../../../../template/messagebird/utils";
import FilterBox from "./FilterBox";

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
  onSubmit: (value) => void;
}
const TemplatePickerDialog = (props: Props) => {
  const { open, onClose } = props;
  const { isMobile } = useGeneralHook();
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      key="template"
      title="template"
      fullScreen={isMobile}
      fullWidth
      maxWidth={"md"}
    >
      <Content {...props} />
    </DialogCustom>
  );
};

const Content = (props: Props) => {
  const { onSubmit, open } = props;
  const { isMobile, API_PATHS, dispatch, fetchThunk } = useGeneralHook();
  const [template, setTemplate] = useState<some | undefined>(undefined);
  const pagination = usePaginationHook({
    disableLink: true,
    disablePagination: true,
  });
  const {
    object: { filter },
  } = pagination;

  const { data: formData, isFetching: isFetchingDetail } = useQuery(
    [template?.name, KEYS_QUERY.whatsappTemplateDetail],
    async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.whatsapp.detail(queryKey[0]),
        })
      );
      return mappedTemplateForm(json.data.data?.details);
    },
    { enabled: !!template?.name }
  );

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["templates-messagebird", filter],
      queryFn: async ({ pageParam = 1 }) => {
        const json = await dispatch(
          fetchThunk({
            url: API_PATHS.templates.getList({
              ...filter,
              template_provider: "MESSAGEBIRD",
              template_type: "PERSONAL",
              page_size: 100,
              page_no: pageParam,
            }),
          })
        );
        return json.data;
      },
      getNextPageParam: (lastPage, pages) => {
        const totalCount = lastPage?.count || 0;
        const totalList = pages.filter(Boolean)?.reduce((v, c) => {
          return [...v, ...(c?.data || [])];
        }, []);
        if (totalList?.length < totalCount) {
          return lastPage.metadata.page_no + 1;
        }
        return null;
      },
      refetchOnMount: false,
      enabled: open,
    });

  const mappedData = useMemo(() => {
    return data?.pages.reduce((value, current) => {
      return [...value, ...current?.data];
    }, []);
  }, [data?.pages]);

  useEffect(() => {
    if (mappedData?.length <= 14 && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, mappedData?.length, isFetchingNextPage, hasNextPage]);

  const SchemaFormContent = useMemo(() => {
    return (
      <>
        <SchemaForm
          onSubmit={onSubmit}
          getAllData
          hideSubmitButton={true}
          formData={formData}
          propsGridContainer={{ spacing: 2 }}
          schema={{
            fields: ({ valuesField, formProps: { intl, state } }) => {
              return {
                preview: {
                  mode: "raw",
                  unregister: true,
                  render: () => (
                    <PreviewTemplateBox
                      sx={{ width: isMobile ? "100%" : 326, mx: "auto" }}
                      data={valuesField as SchemaFormTemplate}
                    />
                  ),
                  noHelperText: true,
                },
                cancelBtn: {
                  mode: "button",
                  variant: "outlined",
                  loading: state.loading,
                  children: intl.formatMessage({ id: "back" }),
                  startIcon: <ArrowBack />,
                  style: { minWidth: 120 },
                  onClick: () => setTemplate(undefined),
                  propsWrapper: {
                    display: "flex",
                    xs: undefined,
                  },
                },
                saveBtn: {
                  mode: "button",
                  type: "submit",
                  loading: state.loading,
                  children: intl.formatMessage({ id: "send" }),
                  style: { minWidth: 120 },
                  propsWrapper: {
                    justifyContent: isMobile ? "flex-end" : "flex-start",
                    display: "flex",
                    xs: true,
                  },
                },
              };
            },
            ui: [
              {
                id: "left",
                fields: [isMobile ? "cancelBtn" : undefined, "saveBtn"],
                propsWrapper: { xs: isMobile ? 12 : 12 },
              },
              {
                id: "right",
                fields: ["preview"],
                propsWrapper: {
                  xs: isMobile ? 12 : true,
                  justifyContent: "center",
                },
              },
            ],
          }}
        />
      </>
    );
  }, [formData, isMobile, onSubmit]);

  if (isMobile) {
    return (
      <>
        {!template && (
          <Box
            height={"100%"}
            overflow={"hidden"}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
          >
            <Box p={2}>
              <FilterBox pagination={pagination} />
            </Box>
            <Box
              overflow={"auto"}
              pb={2}
              onScroll={(e) => {
                if (
                  e.currentTarget.scrollTop +
                    e.currentTarget.offsetHeight +
                    500 >=
                    e.currentTarget.scrollHeight &&
                  !isFetchingNextPage &&
                  hasNextPage
                ) {
                  fetchNextPage();
                }
              }}
              flex={1}
            >
              {mappedData?.map((item) => {
                return (
                  <ButtonBase
                    key={item.id}
                    sx={{
                      width: "100%",
                      px: 2,
                      py: 1,
                      justifyContent: "flex-start",
                      height: 40,
                      textAlign: "start",
                    }}
                    onClick={() => {
                      setTemplate(item);
                    }}
                  >
                    <Typography flex={1}>{item.description}</Typography>
                  </ButtonBase>
                );
              })}
              {isFetching && <LoadingIcon />}
            </Box>
          </Box>
        )}
        {template && (
          <Box className="flex flex-col flex-1 p-4">
            {isFetchingDetail ? (
              <LoadingIcon />
            ) : formData ? (
              SchemaFormContent
            ) : (
              <NotFoundBox label={"select_template"} />
            )}
          </Box>
        )}
      </>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box flexShrink={0} width={320} height={"100%"} overflow={"hidden"}>
        <Box p={2}>
          <FilterBox pagination={pagination} />
        </Box>
        <Box
          height={"70vh"}
          overflow={"auto"}
          pb={2}
          onScroll={(e) => {
            if (
              e.currentTarget.scrollTop + e.currentTarget.offsetHeight + 500 >=
                e.currentTarget.scrollHeight &&
              !isFetchingNextPage &&
              hasNextPage
            ) {
              fetchNextPage();
            }
          }}
        >
          {mappedData?.map((item) => {
            return (
              <ButtonBase
                key={item.id}
                sx={{
                  width: "100%",
                  px: 2,
                  py: 1,
                  justifyContent: "flex-start",
                  height: 40,
                  textAlign: "start",
                }}
                onClick={() => {
                  setTemplate(item);
                }}
              >
                <Typography flex={1}>{item.description}</Typography>
                {item.name === template?.name && <Check color="success" />}
              </ButtonBase>
            );
          })}
          {isFetching && <LoadingIcon />}
        </Box>
      </Box>
      <Box className="flex flex-col flex-1 p-4">
        {isFetchingDetail ? (
          <LoadingIcon />
        ) : formData ? (
          SchemaFormContent
        ) : (
          <NotFoundBox label="select_template" />
        )}
      </Box>
    </Box>
  );
};
export default memo(TemplatePickerDialog, (a, b) => a.open === b.open);
