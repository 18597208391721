export const ROUTES_PATH = {
  login: "login",
  profile: "profile",
  agents: "agents",
  inbox: { index: "inbox" },
  opportunities: {
    index: "opportunities",
    leads: {
      index: "leads",
      detail: ":leadId/*",
      communications: "communications",
      activities: "activities",
    },
  },
  templates: {
    index: "templates/*",
    whatsapp: "whatsapp",
    email: "emails",
    new: "new",
    customized: "customized/:id",
    detail: ":id",
  },
  contacts: {
    index: "contacts",
    detail: ":leadId/*",
    communications: {
      index: "communications/*",
      calls: "calls",
      whatsapp: "whatsapp",
      emails: "emails",
    },
    activities: "activities",
  },
  reports: {
    path: "reports",
    index: "reports/*",
    whatsapp: "whatsapp",
    emails: "emails",
    calls: "calls",
    leads: "leads",
  },
  facebook: {
    index: "fb-ads-manager",
    createCampaign: "create-campaign",
  },
  blast: {
    index: "blast/*",
    secondary: "blast",
    emails: "emails",
    whatsapp: "whatsapp",
  },
  billing: {
    index: "billing/*",
  },
  calendar: {
    index: "calendar",
  },
};
