import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { OptionsObject, useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { API_PATHS } from "../../api/gomedia";
import { setLoading } from "../../redux/commonReducer";
import { AppState } from "../../redux/store";
import { useConfirmDialog } from "../components/ConfirmDialogProvider";
import { useWarningDialog } from "../components/WarningDialogProvider";
import { SUCCESS_CODE, some } from "../constants";
import fetchThunk from "../fetchThunk";
import { getErrorMessage } from "../utils";

interface OptionsSnackbarObject extends Omit<OptionsObject, "variant"> {
  message?: React.ReactNode;
  variant?: number | OptionsObject["variant"];
  requestId?: number;
}

const useGeneralHook = () => {
  const dispatch: ThunkDispatch<AppState, null, AnyAction> = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const confirmDialog = useConfirmDialog();
  const warningDialog = useWarningDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const location = useLocation();
  // const navigate = useNavigate();
  const intl = useIntl();
  const [state, setState] = useState<some>({});

  const setLoadingFnc = useCallback(
    (value: boolean) => {
      dispatch(setLoading(value));
    },
    [dispatch]
  );

  const wrapperEnqueueSnackbar = (params: OptionsSnackbarObject) => {
    const { message, variant, ...rest } = params;
    return enqueueSnackbar(
      <Typography variant="subtitle2" component={"div"} color="inherit">
        {message || "Error!"}
      </Typography>,
      {
        ...rest,
        variant:
          typeof variant === "number"
            ? variant === SUCCESS_CODE
              ? "success"
              : "error"
            : variant,
        autoHideDuration: 3000,
      }
    );
  };

  const copyToClipboard = (text, noNoti?: boolean) => {
    if (text) {
      if (typeof text === "object" && text.props && text.props.children) {
        navigator.clipboard.writeText(text.props.children);
      } else {
        navigator.clipboard.writeText(text);
      }
      if (!noNoti)
        wrapperEnqueueSnackbar({
          message: intl.formatMessage({ id: "copied" }),
          variant: "success",
        });
    } else {
      if (!noNoti)
        wrapperEnqueueSnackbar({
          message: intl.formatMessage({ id: "copied_fail" }),
          variant: "error",
        });
    }
  };

  return {
    intl,
    dispatch,
    fetchThunk: fetchThunk,
    API_PATHS: API_PATHS,
    enqueueSnackbar: wrapperEnqueueSnackbar,
    getErrorMessage: getErrorMessage,
    closeSnackbar,
    theme,
    confirmDialog,
    warningDialog,
    // location,
    // navigate,
    setLoading: setLoadingFnc,
    state,
    setState: (value) => setState((old) => (value ? { ...old, ...value } : {})),
    isMobile,
    copyToClipboard,
  };
};

export default useGeneralHook;
