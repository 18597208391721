import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { uniq } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import SelectCustom from "../SchemaForm/element/select/SelectCustom";
import { DEFAULT_PAGE_SIZE } from "../constants";
import useGeneralHook from "../hook/useGeneralHook";
export interface MuiPaginationCustomProps {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  onPageChange: (value: number) => void;
  onRowsPerPageChange: (value) => void;
}
function MuiPaginationCustom(props: MuiPaginationCustomProps) {
  const {
    count = 0,
    page = 0,
    rowsPerPage = DEFAULT_PAGE_SIZE,
    rowsPerPageOptions = [25, 50, 75, 100, 250, 500, 1000],
    onPageChange,
    onRowsPerPageChange,
  } = props;
  const { isMobile } = useGeneralHook();

  const labelPagination = useMemo(() => {
    let from = (page - 1) * rowsPerPage + 1;
    if (from < 0) {
      from = 0;
    }
    let to = page * rowsPerPage;
    return `${from}-${to > count ? count : to} of ${count}`;
  }, [count, page, rowsPerPage]);
  const controlKeyDown = useCallback(
    (event) => {
      event.stopPropagation();
      if (document.activeElement !== document.body) {
        return;
      }
      const key = event.keyCode;
      if (key === 37) {
        if (page <= 1) {
          return;
        }
        onPageChange(page - 1);
        // left arrow
      } else if (key === 39) {
        if (page === Math.ceil(count / rowsPerPage)) {
          return;
        }
        onPageChange(page + 1);
        // right arrow
      }
    },
    [count, onPageChange, page, rowsPerPage]
  );

  useEffect(() => {
    window.addEventListener("keydown", controlKeyDown);
    return () => window.removeEventListener("keydown", controlKeyDown);
  }, [controlKeyDown]);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexWrap={"wrap"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      gap={1}
    >
      {!isMobile && (
        <Typography>
          <FormattedMessage id="labelRowPerPage" />
        </Typography>
      )}
      <SelectCustom
        value={rowsPerPage}
        formControlProps={{ sx: { width: 80 } }}
        style={{ height: 32 }}
        options={uniq([...rowsPerPageOptions, rowsPerPage])?.map((v) => ({
          id: v,
          name: v,
        }))}
        disableClearBtn
        onChange={onRowsPerPageChange}
      />
      {!isMobile && (
        <>
          <Typography>{labelPagination}</Typography>
        </>
      )}
      <Pagination
        page={page}
        count={Math.ceil(count / rowsPerPage)}
        onChange={(e, page) => {
          onPageChange(page);
        }}
        variant="text"
        color="primary"
        shape="rounded"
        size={isMobile ? "small" : "medium"}
      />
    </Box>
  );
}
export default MuiPaginationCustom;
