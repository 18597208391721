import { Reply } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  alpha,
  darken,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { KEYS_QUERY, some } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { AppState } from "../../../../../redux/store";
import AudioMessageItem from "./AudioMessageItem";
import FileMessageItem from "./FileMessageItem";
import HSMItem from "./HSMItem";
import ImageMessageItem from "./ImageMessageItem";
import InteractiveItem from "./InteractiveItem";
import TextItem from "./TextItem";
import VideoMessageItem from "./VideoMessageItem";

interface Props {
  message: some;
  isOwner?: boolean;
  readOnly?: boolean;
  leadId?: number;
  setReply?: (data: some) => void;
}
const MessageItem = (props: Props) => {
  const { message, isOwner, readOnly, leadId, setReply } = props;
  const agentData = useSelector(
    (state: AppState) => state.auth.agentData,
    shallowEqual
  );
  const { isMobile, dispatch, fetchThunk, API_PATHS, copyToClipboard } =
    useGeneralHook();

  const { data } = useQuery(
    [message.replyTo?.id, leadId, KEYS_QUERY.detailMessage],
    async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.whatsapp.detailMessage(queryKey[0], queryKey[1]),
        })
      );
      return json.data?.data;
    },
    { enabled: !!message.replyTo?.id && !!leadId }
  );

  const content = (
    <Box
      component={"div"}
      onDoubleClick={() => {
        copyToClipboard(message.id, true);
      }}
      sx={{ wordBreak: "break-all", overflow: "hidden" }}
    >
      {message.content?.text ? (
        <TextItem message={message} readOnly={readOnly} isOwner={isOwner} />
      ) : message.type === "hsm" ? (
        <HSMItem message={message} readOnly={readOnly} isOwner={isOwner} />
      ) : message.type === "interactive" ? (
        <InteractiveItem
          message={message}
          readOnly={readOnly}
          isOwner={isOwner}
        />
      ) : message.type === "video" ? (
        <VideoMessageItem
          message={message}
          readOnly={readOnly}
          isOwner={isOwner}
        />
      ) : message.type === "audio" ? (
        <AudioMessageItem
          message={message}
          readOnly={readOnly}
          isOwner={isOwner}
        />
      ) : message.type === "image" ? (
        <ImageMessageItem
          message={message}
          readOnly={readOnly}
          isOwner={isOwner}
        />
      ) : message.type === "file" ? (
        <FileMessageItem
          message={message}
          readOnly={readOnly}
          isOwner={isOwner}
        />
      ) : null}
    </Box>
  );
  if (!!readOnly) {
    return content;
  }
  return (
    <>
      <Box
        component={"div"}
        display="flex"
        alignItems={"flex-start"}
        gap={1}
        flexDirection={isOwner ? "row-reverse" : "row"}
        sx={{
          ":hover #message_action": {
            opacity: 100,
          },
          "#message_card": {
            background: isOwner ? alpha("#00A884", 0.35) : undefined,
          },
          "#reply_message_card": {
            background: isOwner ? alpha("#00A884", 0.35) : undefined,
          },
        }}
      >
        {!isMobile && (
          <Tooltip
            title={message.metadata?.sender?.displayName}
            placement="left"
          >
            <Avatar
              className="sm:hidden md:block"
              src={isOwner ? agentData?.photo_url : ""}
            >
              {message.metadata?.sender?.displayName?.[0]}
            </Avatar>
          </Tooltip>
        )}
        <Box
          id="message_card"
          sx={{
            bgcolor: "background.default",
            borderRadius: 4,
            px: 1,
            py: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            whiteSpace: "pre-wrap",
            gap: 1,
            maxWidth: "80%",
          }}
        >
          {message.replyTo?.id && (
            <Box
              id="reply_message_card"
              py={0.5}
              px={1}
              borderRadius={1}
              bgcolor={(theme) => darken(theme.palette.background.default, 0.1)}
              position={"relative"}
              minHeight={32}
            >
              {data ? (
                <>
                  <Typography variant="subtitle1" color={"primary"}>
                    {data.from}
                  </Typography>
                  <MessageItem readOnly message={data} />
                </>
              ) : (
                <>
                  <Skeleton width={"120"} />
                  <Skeleton width={"50%"} />
                </>
              )}
            </Box>
          )}
          {content}
        </Box>
        <Box id="message_action" className={"flex opacity-0"}>
          {setReply && (
            <Tooltip
              title={
                <>
                  <FormattedMessage id="reply_to" />
                  &nbsp;
                  {message.metadata?.sender?.displayName}
                </>
              }
            >
              <IconButton
                size="small"
                sx={{ width: 32, height: 32, p: 0 }}
                onClick={() => setReply(message)}
              >
                <Reply sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </>
  );
};
export default MessageItem;
