import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getWhatsappText } from "../../../../../common/components/elements";
import { some } from "../../../../../common/constants";
import FooterMessage from "./FooterMessage";

interface Props {
  message: some;
  isOwner?: boolean;
  readOnly?: boolean;
}

const VideoMessageItem = (props: Props) => {
  const { message, readOnly, isOwner } = props;
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    video?.addEventListener("loadeddata", () => {
      if (video && video.duration < 15) {
        video.loop = true;
        video.muted = true;
        video.controls = false;
        video.play();
      }
    });
  }, [video]);
  if (readOnly) {
    return (
      <Box className="flex gap-2">
        <Box
          bgcolor={"grey.200"}
          display={"flex"}
          minWidth={120}
          justifyContent={"center"}
          borderRadius={2}
          position={"relative"}
        >
          <video
            ref={setVideo}
            style={{ height: 60 }}
            controls
            autoPlay
            playsInline
            muted
            onLoadedData={(e) => {}}
          >
            <source src={message?.content?.video?.url} type="video/mp4" />
          </video>
          <Typography
            variant="caption"
            className="absolute bottom-1 right-1"
            color={"common.white"}
          >
            {Number(video?.duration).toFixed(0) || 0}s
          </Typography>
        </Box>
        {message?.content?.video?.caption && (
          <Typography variant="body1">
            {getWhatsappText(message?.content?.video?.caption)}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <video
        ref={setVideo}
        style={{ maxWidth: 320 }}
        controls
        autoPlay
        playsInline
        muted
        onLoadedData={(e) => {}}
      >
        <source src={message?.content?.video?.url} type="video/mp4" />
      </video>
      {message?.content?.video?.caption && (
        <Typography variant="body1" maxWidth={360} mt={1}>
          {getWhatsappText(message?.content?.video?.caption)}
        </Typography>
      )}
      <FooterMessage message={message} isOwner={isOwner} />
    </>
  );
};

export default VideoMessageItem;
