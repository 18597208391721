import {
  Close,
  DocumentScannerRounded,
  FilePresentOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import SchemaForm from "../../../../../common/SchemaForm";
import { IControllerRenderProps } from "../../../../../common/SchemaForm/utils";
import { some } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { EmailForm } from "../../../../../redux/leadReducer";
import EmailPickingTemplateDialog from "./EmailPickingTemplateDialog";

interface Props {
  formData?: EmailForm;
  callback?: () => void;
  methods?: UseFormReturn;
  heightTextEditor?: number;
}
const EmailFormBox = (props: Props) => {
  const { formData, methods, heightTextEditor, callback } = props;
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar, getErrorMessage } =
    useGeneralHook();

  const sendEmail = async (value: some, methods) => {
    try {
      const form = new FormData();
      form.append("from", value?.from);
      form.append("to", value?.to);
      form.append("reply_to", value?.reply_to);
      form.append("subject", value?.subject);
      form.append("body", value?.body);
      for (let i = 0; i < value?.cc?.length; i++) {
        form.append("cc", value?.cc[i]);
      }
      for (let i = 0; i < value?.bcc?.length; i++) {
        form.append("bcc", value?.bcc[i]);
      }
      for (let i = 0; i < value?.attachment?.length; i++) {
        form.append("attachment", value?.attachment[i]);
      }
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.email.send,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: form,
        })
      );
      enqueueSnackbar({
        message: json.data.data.messsage || (
          <FormattedMessage id={"send_email_success"} />
        ),
        variant: json.status,
      });
      methods?.reset();
      callback && callback();
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };

  return (
    <SchemaForm
      methods={methods}
      onSubmit={sendEmail}
      hideSubmitButton
      formData={formData}
      schema={{
        propsGridContainer: { spacing: 0 },
        fields: ({
          formProps: { intl, state, setState },
          methods: { setValue },
        }) => {
          return {
            from: {
              mode: "text-field",
              placeholder: intl.formatMessage({ id: "enter" }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FormattedMessage id="from" />
                  </InputAdornment>
                ),
              },
              readOnly: true,
              rules: {
                required: true,
              },
            },
            reply_to: {
              mode: "text-field",
              placeholder: intl.formatMessage({ id: "enter" }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FormattedMessage id="reply_to" />
                  </InputAdornment>
                ),
              },
              readOnly: true,
              rules: {
                required: true,
              },
            },
            to: {
              mode: "text-field",
              placeholder: intl.formatMessage({ id: "enter" }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FormattedMessage id="to" />
                  </InputAdornment>
                ),
              },
              readOnly: true,
              rules: {
                required: true,
              },
            },
            cc: {
              mode: "text-field",
              placeholder: intl.formatMessage({ id: "cc_placeholder" }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FormattedMessage id="cc" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setState({ showBcc: !state.showBcc })}
                    >
                      <Typography variant="body2">
                        <FormattedMessage id="bcc" />
                      </Typography>
                    </IconButton>
                  </InputAdornment>
                ),
              },
              propsWrapper: { xs: 12, sm: 6 },
            },
            bcc: {
              mode: "text-field",
              placeholder: intl.formatMessage({ id: "bcc_placeholder" }),
              hidden: !state.showBcc,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FormattedMessage id="bcc" />
                  </InputAdornment>
                ),
              },
              propsWrapper: { xs: 12, sm: 6 },
            },
            subject: {
              mode: "text-field",
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">Subject(*)</InputAdornment>
                ),
              },
              rules: { required: true },
              propsWrapper: { xs: 12, sm: 12 },
            },
            body: {
              mode: "text-editor",
              height: heightTextEditor || 300,
              rules: { required: true },
            },
            attachment: {
              mode: "raw",
              render: ({
                field: { value, onChange },
              }: IControllerRenderProps) => {
                return (
                  <Box>
                    {value?.length > 0 && (
                      <Grid
                        container
                        spacing={1}
                        style={{ maxHeight: 160, overflow: "auto" }}
                      >
                        {value?.map((file: any, index) => (
                          <Grid item xs={12} sm={6} key={file.path}>
                            <Box
                              display="flex"
                              alignItems="center"
                              border={1}
                              borderColor="divider"
                              justifyContent="space-between"
                              borderRadius={1}
                              paddingX={1}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Typography variant="body2" noWrap>
                                {file.name} - {Math.round(file.size / 1000)} KB
                              </Typography>
                              <IconButton
                                onClick={() => {
                                  onChange(
                                    value?.filter((_, idx) => idx !== index)
                                  );
                                }}
                              >
                                <Close />
                              </IconButton>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    <Box className="flex flex-col gap-2 sm:flex-row pt-1">
                      <Box className="flex items-center sm:flex-1 gap-1 sm:gap-2">
                        <Button
                          variant="outlined"
                          component="label"
                          className="w-10"
                          sx={{ p: 0 }}
                        >
                          <FilePresentOutlined />
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e) => {
                              if (e.target.files)
                                onChange(Array.from(e.target.files));
                            }}
                          />
                        </Button>
                        <Button
                          variant="outlined"
                          component="label"
                          className="w-10"
                          sx={{ p: 0 }}
                          onClick={() => {
                            setState({ openEmailTempate: true });
                          }}
                        >
                          <DocumentScannerRounded />
                        </Button>
                        <EmailPickingTemplateDialog
                          open={state.openEmailTempate}
                          onClose={() => {
                            setState({ openEmailTempate: false });
                          }}
                          onSubmit={(value) => {
                            setValue("body", value);
                          }}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ minWidth: 160 }}
                      >
                        <FormattedMessage id="send" />
                      </Button>
                    </Box>
                  </Box>
                );
              },
            },
          };
        },
        changeDataBeforeSubmit: (values) => {
          return {
            ...values,
            cc: values?.cc?.trim()?.split(","),
            bcc: values?.bcc?.trim()?.split(","),
          };
        },
      }}
    />
  );
};
export default EmailFormBox;
