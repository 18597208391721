import { AnyAction, createSlice, ThunkAction } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { API_PATHS } from "../api/gomedia";
import {
  GM_HEADER_KEY_ACCESS_TOKEN,
  GM_HEADER_KEY_AUTH_STATE,
  GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN,
  some,
} from "../common/constants";
import fetchThunk from "../common/fetchThunk";
import { AppState } from "./store";
export interface AuthState {
  isLogin: boolean;
  loading: boolean;
  validate: boolean;
  userData?: some;
  agentData?: some;
}

export const initialStateAuth: AuthState = {
  isLogin: false,
  loading: true,
  validate: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialStateAuth,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setValidateAuth: (state, action) => {
      state.validate = action.payload;
    },
    authIn: (state) => {
      state.isLogin = true;
    },
    authOut: (state) => {
      state.isLogin = false;
      state.userData = undefined;
      state.agentData = undefined;
      localStorage.clear();
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setAgentData: (state, action) => {
      state.agentData = action.payload;
    },
  },
});

export const {
  authIn,
  authOut,
  setLoading,
  setValidateAuth,
  setUserData,
  setAgentData,
} = authSlice.actions;

export function getUserInfo(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch) => {
    try {
      dispatch(setValidateAuth(true));
      const json = await dispatch(
        fetchThunk({ url: API_PATHS.auth.me, method: "get" })
      );
      dispatch(authIn());
      dispatch(setUserData(json.data?.data));
      const jsonAgent = await dispatch(
        fetchThunk({ url: API_PATHS.auth.agentProfile })
      );
      dispatch(setAgentData(jsonAgent.data?.data));
    } catch (e) {
      dispatch(authOut());
      dispatch(setValidateAuth(false));
      dispatch(setLoading(false));
    } finally {
      dispatch(setValidateAuth(false));
    }
  };
}

export function gAuthWindowEvent(
  event: any,
  state: string
): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    if (event.origin !== process.env.REACT_APP_GOMEDIA_BACKEND_BASE_URL) {
      return;
    }
    try {
      dispatch(setLoading(true));

      const json = await axios.get(API_PATHS.auth.verify(event.data), {
        headers: { [GM_HEADER_KEY_AUTH_STATE]: state },
      });
      if (json.headers[GM_HEADER_KEY_ACCESS_TOKEN]) {
        dispatch(setUserData(json.data?.data));
        dispatch(authIn());
        Cookies.set(
          GM_HEADER_KEY_ACCESS_TOKEN,
          json.headers[GM_HEADER_KEY_ACCESS_TOKEN],
          {
            expires: dayjs().add(1, "month").toDate(),
          }
        );
        Cookies.set(
          GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN,
          json.headers[GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN],
          {
            expires: dayjs().add(1, "month").toDate(),
          }
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function loginSSO(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const w = window.innerWidth <= 480 ? window.innerWidth : 480;
      const h = window.innerHeight <= 800 ? window.innerHeight : 800;
      let y = 0;
      let x = 0;

      if (window.top) {
        y = window?.top.outerHeight / 2 + window?.top.screenY - h / 2;
        x = window?.top.outerWidth / 2 + window?.top.screenX - w / 2;
      }
      var windowReference = window.open(
        undefined,
        "ga-login",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,
  height=${h}, width=${w}, top=${y}, left=${x}`
      );
      const json = await axios.get(API_PATHS.auth.login);
      const state = json.headers[GM_HEADER_KEY_AUTH_STATE];
      window.removeEventListener("message", (event) =>
        dispatch(gAuthWindowEvent(event, state))
      );
      window.addEventListener(
        "message",
        (event) => dispatch(gAuthWindowEvent(event, state)),
        false
      );
      if (json.data?.id) {
        dispatch(authIn());
        dispatch(setUserData(json.data?.data));
        const jsonAgent = await dispatch(
          fetchThunk({ url: API_PATHS.auth.agentProfile })
        );
        dispatch(setAgentData(jsonAgent.data?.data));
      } else {
        if (windowReference) {
          windowReference.location = json.data?.data;
        }
      }
    } catch (e) {
    } finally {
      dispatch(setLoading(false));
    }
  };
}

const shouldBrowserRefreshKey = "shouldBrowserRefresh";
export function logoutSSO(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await dispatch(fetchThunk({ url: API_PATHS.auth.logout }));
      dispatch(authOut());
      Cookies.remove(GM_HEADER_KEY_ACCESS_TOKEN);

      // set shouldBrowserRefresh to true if not set
      let shouldBrowserRefresh = Cookies.get(shouldBrowserRefreshKey);
      if (typeof shouldBrowserRefresh === "undefined") {
        Cookies.set(shouldBrowserRefreshKey, "true", {
          expires: dayjs().add(1, "month").toDate(),
        });
      }

      // refresh browser and set shouldBrowserRefresh to false
      shouldBrowserRefresh = Cookies.get(shouldBrowserRefreshKey);
      if (shouldBrowserRefresh == "true") {
        await Cookies.set(shouldBrowserRefreshKey, "false", {
          expires: dayjs().add(1, "month").toDate(),
        });
        window.location.reload();
      }
    } catch (e) {
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export default authSlice.reducer;
