import Close from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { InputBaseProps } from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { AxiosResponse } from "axios";
import React, { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from "react-query";
import { some } from "../../../constants";
import { api } from "../../../fetchThunk";

interface IOption {
  id?: string | number | undefined;
  disabled?: boolean;
  name?: React.ReactNode | string | number;
}

export interface SelectCustomProps
  extends Omit<SelectProps, "defaultValue" | "value" | "onChange" | "ref"> {
  label?: React.ReactNode;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  required?: boolean;
  options?: (IOption | some)[];
  placeholder?: string;
  rawOptions?: boolean;
  disableClearBtn?: boolean;
  InputProps?: InputBaseProps;
  loadOptions?: {
    url: QueryKey;
    mapped?: (json?: AxiosResponse) => some[];
    config?: Omit<UseQueryOptions, "queryKey" | "queryFn">;
    queryFn?: QueryFunction<unknown, QueryKey>;
  };
  value?: any;
  onChange?: (val: any) => void;
  formControlProps?: FormControlProps;
  hasAllOptions?: boolean;
}

const SelectCustom = React.forwardRef<HTMLDivElement | null, SelectCustomProps>(
  (props: SelectCustomProps, ref) => {
    const {
      label,
      required,
      options,
      placeholder,
      rawOptions,
      error,
      InputProps,
      loadOptions,
      value,
      onChange,
      disableClearBtn: disableCloseBtn,
      formControlProps,
      hasAllOptions,
      multiple,
      variant,
      disableUnderline,
      ...rest
    } = props;
    const intl = useIntl();
    const { data, isLoading } = useQuery(
      loadOptions?.url || "",
      loadOptions?.queryFn
        ? loadOptions?.queryFn
        : async ({ queryKey }) => {
            if (queryKey[0]) {
              const json = await api({ url: queryKey[0] as string });
              return loadOptions?.mapped ? loadOptions?.mapped(json) : json;
            }
          },
      loadOptions?.config
    );

    const optionsTmp = useMemo(() => {
      if (loadOptions) {
        return (data as any) || [];
      }
      return options || [];
    }, [data, loadOptions, options]);

    const isAllSelected = hasAllOptions
      ? multiple && optionsTmp
        ? value?.length === optionsTmp?.length || value?.length === 0
        : value === ""
      : false;

    const handleChange = (valueSelect: any) => {
      if (!onChange) {
        return;
      }
      if (multiple) {
        const tmp =
          typeof valueSelect === "string"
            ? valueSelect.split(",")
            : valueSelect;
        if (isAllSelected) {
          onChange(tmp.filter((v) => v !== "all"));
        } else if (hasAllOptions && (tmp || []).includes("all")) {
          onChange((optionsTmp || []).map((v) => v.id));
        } else {
          onChange(tmp);
        }
        return;
      } else {
        onChange(valueSelect);
      }
    };

    return (
      <FormControl error={!!error} fullWidth ref={ref} {...formControlProps}>
        {label && (
          <InputLabel required={required} shrink>
            {label}
          </InputLabel>
        )}
        <Select
          value={isAllSelected ? ["all"] : value}
          multiple={multiple}
          onChange={(event) => handleChange(event.target.value)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          fullWidth
          variant={variant || "outlined"}
          size="medium"
          displayEmpty
          disableUnderline={disableUnderline || false}
          {...(multiple
            ? {
                renderValue: (selected: any) => {
                  if (selected?.length === 0) {
                    return <em>{placeholder}</em>;
                  }
                  return selected
                    ?.map((one) => optionsTmp?.find((v) => v.id === one)?.name)
                    .join(", ");
                },
              }
            : {})}
          endAdornment={
            <>
              <InputAdornment
                position="end"
                sx={{ position: "absolute", right: 16 }}
              >
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {(multiple
                  ? value?.length > 0 && !isAllSelected
                  : value !== "") &&
                !rest.readOnly &&
                !rest.disabled &&
                !disableCloseBtn ? (
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleChange(
                        multiple ? (hasAllOptions ? ["all"] : []) : null
                      )
                    }
                    sx={{ display: "none" }}
                    className="clear-btn"
                  >
                    <Close />
                  </IconButton>
                ) : null}
              </InputAdornment>
            </>
          }
          sx={{
            ":hover": {
              "& .clear-btn": {
                display: "flex",
              },
            },
            ...rest.sx,
          }}
          {...rest}
        >
          {placeholder && (
            <MenuItem value={""} disabled={true} style={{ opacity: ".5" }}>
              <Typography
                variant="inherit"
                style={{ opacity: ".5" }}
                component="span"
              >
                {placeholder}
              </Typography>
            </MenuItem>
          )}
          {hasAllOptions && (
            <MenuItem value={multiple ? "all" : " "}>
              {intl.formatMessage({ id: "all" })}
            </MenuItem>
          )}

          {Array.isArray(optionsTmp) &&
            optionsTmp?.map((option: IOption, index: number) => {
              const { name, disabled, id, ...rest } = option;
              return (
                <MenuItem key={index} value={id} disabled={disabled} {...rest}>
                  {typeof name === "string"
                    ? rawOptions || loadOptions
                      ? name
                      : name
                      ? intl.formatMessage({ id: name })
                      : ""
                    : name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }
);

export default memo(SelectCustom);
