import { Box, Typography } from "@mui/material";
import ImageDialog from "../../../../../common/components/ImageDialog";
import { getWhatsappText } from "../../../../../common/components/elements";
import { some } from "../../../../../common/constants";
import FooterMessage from "./FooterMessage";

interface Props {
  message: some;
  isOwner?: boolean;
  readOnly?: boolean;
}

const ImageMessageItem = (props: Props) => {
  const { message, readOnly, isOwner } = props;
  if (readOnly) {
    return (
      <Box className="flex gap-2">
        <ImageDialog
          src={message?.content.image?.url}
          alt="image_url"
          sx={{
            maxHeight: 80,
            maxWidth: 120,
            borderRadius: 1,
            objectFit: "cover",
          }}
        />
        <Typography variant="body1" flex={1}>
          {getWhatsappText(message?.content.image?.caption)}
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <ImageDialog
        src={message?.content.image?.url}
        alt="image_url"
        sx={{ maxWidth: 400, mx: "auto" }}
      />
      {message?.content.image?.caption && (
        <Typography
          variant="body1"
          mt={1}
          sx={{ wordBreak: "break-all", maxWidth: 400 }}
        >
          {getWhatsappText(message?.content.image?.caption)}
        </Typography>
      )}
      <FooterMessage message={message} isOwner={isOwner} />
    </>
  );
};

export default ImageMessageItem;
