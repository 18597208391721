// Material UI
// Hooks + Common
import { SearchRounded } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import useGeneralHook from "./../../../../../../common/hook/useGeneralHook";
import { HookPaginationProps } from "./../../../../../../common/hook/usePaginationHook";

const LabelContent = (props: { content: any; sx?: BoxProps["sx"] }) => {
  const { content, sx } = props;
  return (
    <Tooltip title={content}>
      <Box
        sx={{
          borderRadius: 32,
          display: "flex",
          alignItems: "center",
          px: 1,
          border: 1,
          borderColor: "primary.main",
          height: 24,
          maxWidth: 280,
          ...sx,
        }}
      >
        <Typography variant="caption" color="primary" noWrap>
          {content}
        </Typography>
      </Box>
    </Tooltip>
  );
};
interface Props {
  pagination: HookPaginationProps;
}
const FilterBox = (props: Props) => {
  const { pagination } = props;
  const { isMobile, intl, API_PATHS, dispatch, fetchThunk } = useGeneralHook();
  const {
    setParams,
    object: { filter },
  } = pagination;
  const [term, setTerm] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeSearch = useCallback(
    debounce((q) => {
      setParams({ q });
    }, 350),
    [setParams]
  );

  const FilterLabelBox = useMemo(() => {
    let list: string[] = [];

    if (filter.statuses) {
      const content =
        intl.formatMessage({ id: "status" }) +
        ": " +
        intl.formatMessage({ id: filter.statuses });
      list.push(content);
    }

    return list.filter(Boolean);
  }, [intl, filter.statuses]);

  useEffect(() => {
    setTerm(filter.q || "");
  }, [filter.q]);

  return (
    <>
      <Box className="flex gap-2">
        <TextField
          value={term}
          placeholder={intl.formatMessage({
            id: "search",
          })}
          onChange={(e) => {
            setTerm(e.target.value);
            onChangeSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
        {!isMobile && (
          <Box display={"flex"} gap={1} alignItems={"center"} flexWrap={"wrap"}>
            {FilterLabelBox.map((content, index) => {
              return <LabelContent key={index} content={content} />;
            })}
          </Box>
        )}
      </Box>
    </>
  );
};
export default FilterBox;
