import dayjs from "dayjs";
import query from "query-string";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import EventForm from "../../modules/calendar/EventForm";
import { IEvent } from "../../modules/calendar/constants";
import { setFormDataRedux } from "../../redux/formReducer";
import { AppState } from "../../redux/store";
import { KEY_FORM } from "../constants";
import useGeneralHook from "../hook/useGeneralHook";
import DialogCustom from "./DialogCustom";
const AppointmentEventFormDialog = () => {
  const { API_PATHS, dispatch, fetchThunk } = useGeneralHook();
  const location = useLocation();
  const formData = useSelector(
    (state: AppState) => state.form.formData[KEY_FORM.event]
  );

  const callback = useSelector(
    (state: AppState) => state.form.callback[KEY_FORM.event]
  );

  const onCreateEvent = async (value: IEvent) => {
    (window as any)
      .open(
        `https://calendar.google.com/calendar/u/0/r/eventedit?${query.stringify(
          {
            dates: value.allDay
              ? (
                  value.start.format("YYYYMMDD") +
                  "/" +
                  value.end.format("YYYYMMDD")
                )
                  .replaceAll("-", "")
                  .replaceAll(":", "")
              : (value.start.toISOString() + "/" + value.end.toISOString())
                  .replaceAll("-", "")
                  .replaceAll(":", ""),
            location: value.location,
            text: value.summary,
            details: value.description,
            add: value.attendees?.map((v) => v.email).join(","),
            crm: value.transparency === "opaque" ? "BUSY" : "AVAILABLE",
          },
          {
            arrayFormat: "comma",
            skipEmptyString: true,
            skipNull: true,
          }
        )}`,
        "_blank"
      )
      .focus();
    try {
      await Promise.all(
        (value.attendees || [])
          .filter((v) => v.id)
          ?.map((val) => {
            return dispatch(
              fetchThunk({
                url: API_PATHS.calendar,
                data: {
                  agent_lead_id: val.id,
                  attendee: val.email,
                  created: dayjs().toISOString(),
                  date_start: value.allDay
                    ? value.start.startOf("date").toISOString()
                    : value.start.toISOString(),
                  date_end: value.allDay
                    ? value.end.endOf("date").toISOString()
                    : value.end.toISOString(),
                  location: value.location,
                  organizer: formData?.agent_email,
                  summary: value.summary,
                  description: value.description,
                },
                method: "POST",
              })
            );
          })
      );
      callback && callback();
    } finally {
      dispatch(
        setFormDataRedux({
          name: KEY_FORM.event,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      setFormDataRedux({
        name: KEY_FORM.event,
      })
    );
  }, [dispatch, location.pathname]);

  return (
    <DialogCustom
      open={!!formData}
      onClose={() => {
        dispatch(
          setFormDataRedux({
            name: KEY_FORM.event,
          })
        );
      }}
      fullWidth
      maxWidth="sm"
    >
      <EventForm formData={formData} onSubmit={onCreateEvent} />
    </DialogCustom>
  );
};
export default AppointmentEventFormDialog;
