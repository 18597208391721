import { AnyAction, createSlice, ThunkAction } from "@reduxjs/toolkit";
import { API_PATHS } from "../api/gomedia";
import fetchThunk from "../common/fetchThunk";
import { AppState } from "./store";

export interface AuthFBState {
  isLogin: boolean;
  loading: boolean;
}

export const initialStateAuthFB: AuthFBState = {
  isLogin: false,
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialStateAuthFB,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    authFbIn: (state) => {
      state.isLogin = true;
    },
    authFbOut: (state) => {
      state.isLogin = false;
      localStorage.clear();
    },
  },
});

export const { authFbIn, authFbOut, setLoading } = authSlice.actions;

export function getUserInfo(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch) => {
    try {
      await dispatch(fetchThunk({ url: API_PATHS.auth.me, method: "get" }));
      dispatch(authFbIn());
    } catch (e) {
      dispatch(authFbOut());
    }
  };
}

function fbAuthWindowEvent(
  event: any
): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    if (event.origin !== process.env.REACT_APP_GOMEDIA_BACKEND_BASE_URL) {
      return;
    }
    try {
      dispatch(setLoading(true));
      await dispatch(
        fetchThunk({
          url: API_PATHS.auth_facebook.verify(event.data),
          method: "get",
        })
      );
      dispatch(authFbIn());
      dispatch(setLoading(false));
    } catch (e) {}
  };
}

export function loginFbSSO(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const json = await dispatch(
        fetchThunk({ url: API_PATHS.auth_facebook.login })
      );
      dispatch(setLoading(false));

      const w = window.innerWidth <= 480 ? window.innerWidth : 480;
      const h = window.innerHeight <= 800 ? window.innerHeight : 800;
      let y = 0;
      let x = 0;

      if (window.top) {
        y = window?.top.outerHeight / 2 + window?.top.screenY - h / 2;
        x = window?.top.outerWidth / 2 + window?.top.screenX - w / 2;
      }
      window.removeEventListener("message", (event) =>
        dispatch(fbAuthWindowEvent(event))
      );
      window.addEventListener(
        "message",
        (event) => dispatch(fbAuthWindowEvent(event)),
        false
      );
      window.open(
        json.data,
        "ga-login",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,
      height=${h}, width=${w}, top=${y}, left=${x}`
      );
    } catch (e) {
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export default authSlice.reducer;
