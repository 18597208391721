import { isEmpty } from "lodash";
import { IRouteObject } from "../../common/utils";

export function checkPermission(
  permissions: string[],
  listRole?: string[][]
): boolean {
  if (!listRole?.length) {
    return true;
  }
  return !!listRole.find((groupRole) => {
    return groupRole.every((role) => {
      return permissions?.includes(role);
    });
  });
}

export const getVisibleRoutes = (
  agentData: any = {},
  routes: IRouteObject[]
): IRouteObject[] => {
  if (isEmpty(agentData)) return [];
  const { admin_permissions = [] } = agentData;
  if (isEmpty(admin_permissions)) return routes;

  return routes
    .map((route) => {
      const children = route.children
        ? getVisibleRoutes(agentData, route.children)
        : [];

      if (!checkPermission(admin_permissions, route.listRole)) {
        return undefined;
      }
      if (
        route.children &&
        route.children?.length > 0 &&
        children?.length === 0
      ) {
        return undefined;
      }
      return {
        ...route,
        children: route.children && getVisibleRoutes(agentData, route.children),
      };
    })
    .filter(Boolean) as IRouteObject[];
};
