import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormHelperText,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { get, pickBy } from "lodash";
import _ from "lodash/fp";
import React, { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { some } from "../constants";
import { ElementFormProps, ElementMode } from "./utils";

const ArrayElement = React.lazy(
  () => import("./element/array-element/ArrayElement")
);
const AutoCompleteElement = React.lazy(
  () => import("./element/autocomplete/AutoCompleteElement")
);
const FormControlAutoComplete = React.lazy(
  () => import("./element/autocomplete/FormControlAutoComplete")
);
const CheckBoxElement = React.lazy(
  () => import("./element/checkbox/CheckBoxElement")
);
const DateRangePickerElement = React.lazy(
  () => import("./element/date-range/DateRangePickerElement")
);
const DatePickerElement = React.lazy(
  () => import("./element/datepicker-element/DatePickerElement")
);
const DateTimePickerElement = React.lazy(
  () => import("./element/datepickerTime-element/DateTimePickerElement")
);
const DropZoneElement = React.lazy(
  () => import("./element/drop-zone/DropZoneElement")
);
const MultipleCheckBoxElement = React.lazy(
  () => import("./element/multiple-checkbox/MultipleCheckBoxElement")
);
const MultipleRadioElement = React.lazy(
  () => import("./element/multiple-radio/MultipleRadioElement")
);
const PhoneFieldElement = React.lazy(
  () => import("./element/phone-input/PhoneFieldElement")
);
const RadioElement = React.lazy(() => import("./element/radio/RadioElement"));
const SectionElement = React.lazy(
  () => import("./element/section-element/SectionElement")
);
const SelectElement = React.lazy(
  () => import("./element/select/SelectElement")
);
const SwitchElement = React.lazy(
  () => import("./element/switch/SwitchElement")
);
const TextEditorElement = React.lazy(
  () => import("./element/text-editor/TextEditorElement")
);
const TextFieldElement = React.lazy(
  () => import("./element/text-field/TextFieldElement")
);
const TimePickerElement = React.lazy(
  () => import("./element/timepicker-element/TimePickerElement")
);
const UploadFileElement = React.lazy(
  () => import("./element/uploadFile/UploadFileElement")
);
const UploadImageElement = React.lazy(
  () => import("./element/uploadImage/UploadImageElement")
);

interface Props {
  fieldName: string;
  rawElement?: boolean;
  propsElement: ElementFormProps;
}

SchemaElement.defaultProps = {};

function SchemaElement(props: Props | some) {
  const { fieldName, rawElement, propsElement } = props;
  const {
    mode,
    render,
    unregister,
    rules = {},
    propsWrapper,
    defaultValue,
    tooltipError,
    noHelperText,
    shouldUnregister,
    hidden,
    viewMode,
    mapperValue,
    ...rest
  } = propsElement;

  const onChangeValue = useCallback(
    (onChangeTmp, params) => {
      const { onChange } = rest;
      if (mapperValue) {
        if (typeof params === "object") {
          onChangeTmp(mapperValue(...params));
          onChange && onChange(mapperValue(...params));
        } else {
          onChangeTmp(mapperValue(params));
          onChange && onChange(mapperValue(params));
        }
      } else {
        if (typeof params === "object") {
          onChangeTmp(...params);
          onChange && onChange(...params);
        } else {
          onChangeTmp(params);
          onChange && onChange(params);
        }
      }
    },
    [mapperValue, rest]
  );

  const methods = useFormContext();
  const {
    control,
    register,
    formState: { errors },
  } = methods;

  const { valueAsDate, valueAsNumber, pattern, setValueAs, ...restRegis } =
    rules;

  const required = Object.keys(restRegis)?.length > 0;

  const getElement = React.useMemo(() => {
    let element;

    if (!!viewMode) {
      return unregister ? (
        render && render()
      ) : (
        <Controller
          shouldUnregister={shouldUnregister}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { value } }) => (
            <>
              <Box>
                <Typography variant="subtitle1">{rest.label}</Typography>
                <Typography
                  variant="body2"
                  component={"div"}
                  sx={{ minHeight: 18 }}
                >
                  {render ? render(value) : rest.value || value}
                </Typography>
              </Box>
            </>
          )}
        />
      );
    }
    if (!mode) {
      return [];
    }
    switch (mode as ElementMode) {
      case "hidden":
        element = <input type="hidden" {...register(fieldName, rules)} />;
        break;
      case "text":
        element = (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { value } }) => (
              <>
                <Box>
                  <Typography variant="subtitle1">{rest.label}</Typography>
                  <Typography variant="body2">{rest.value || value}</Typography>
                </Box>
              </>
            )}
          />
        );
        break;
      case "text-field":
        element = unregister ? (
          <TextFieldElement fullWidth {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid },
            }) => (
              <>
                <TextFieldElement
                  fullWidth
                  required={required}
                  name={name}
                  {...rest}
                  inputRef={(e) => {
                    ref(e);
                    rest.inputRef && rest.inputRef(e);
                  }}
                  value={
                    typeof value === "string" || typeof value === "number"
                      ? value
                      : ""
                  }
                  onChange={(...params) => {
                    onChangeValue(onChange, params);
                  }}
                  error={invalid}
                />
              </>
            )}
          />
        );
        break;
      case "phone-field":
        element = unregister ? (
          <PhoneFieldElement fullWidth {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid },
            }) => (
              <>
                <PhoneFieldElement
                  fullWidth
                  required={required}
                  name={name}
                  {...rest}
                  inputRef={(e) => {
                    ref(e);
                    rest.inputRef && rest.inputRef(e);
                  }}
                  value={
                    typeof value === "string" || typeof value === "number"
                      ? value
                      : ""
                  }
                  onChange={(...params) => {
                    onChangeValue(onChange, params);
                  }}
                  error={invalid}
                />
              </>
            )}
          />
        );
        break;
      case "uploadFile":
        element = unregister ? (
          <UploadFileElement fullWidth {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid },
            }) => (
              <UploadFileElement
                inputRef={ref}
                fullWidth
                required={required}
                name={name}
                {...rest}
                value={value}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "uploadImage":
        element = unregister ? (
          <UploadImageElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid },
            }) => (
              <UploadImageElement
                required={required}
                name={name}
                {...rest}
                value={value}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "checkbox":
        element = unregister ? (
          <CheckBoxElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || false}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <CheckBoxElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "multiple-checkbox":
        element = unregister ? (
          <MultipleCheckBoxElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || []}
            render={({
              field: { onChange, ref, ...field },
              fieldState: { invalid },
            }) => (
              <MultipleCheckBoxElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "radio":
        element = unregister ? (
          <RadioElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || false}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <RadioElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "multiple-radio":
        element = unregister ? (
          <MultipleRadioElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <MultipleRadioElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "select":
        element = unregister ? (
          <SelectElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <SelectElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "switch":
        element = unregister ? (
          <SwitchElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || false}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <SwitchElement
                required={required}
                {...rest}
                disabled={rest.disabled || rest.readOnly}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "auto-complete":
        element = unregister ? (
          <FormControlAutoComplete fullWidth {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({
              field: { onChange, ref, ...field },
              fieldState: { invalid },
            }) => (
              <AutoCompleteElement
                required={required}
                {...rest}
                {...field}
                innerRef={ref}
                error={invalid}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
              />
            )}
          />
        );
        break;
      case "datePicker":
        element = unregister ? (
          <DatePickerElement {...(rest as any)} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || null}
            render={({
              field: { onChange, value, ...field },
              fieldState: { invalid },
            }) => {
              return (
                <DatePickerElement
                  required={required}
                  {...rest}
                  value={value ? dayjs(value) : null}
                  onChange={(val?: Dayjs | null) => {
                    onChangeValue(onChange, val ? val?.toISOString() : null);
                  }}
                  {...field}
                  error={invalid}
                />
              );
            }}
          />
        );
        break;
      case "dateTimePicker":
        element = unregister ? (
          <DatePickerElement {...(rest as any)} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || null}
            render={({
              field: { onChange, value, ...field },
              fieldState: { invalid },
            }) => {
              return (
                <DateTimePickerElement
                  required={required}
                  {...rest}
                  value={value ? dayjs(value) : null}
                  onChange={(val?: Dayjs | null) => {
                    onChangeValue(onChange, val ? val?.toISOString() : null);
                  }}
                  {...field}
                  error={invalid}
                />
              );
            }}
          />
        );
        break;
      case "timePicker":
        element = unregister ? (
          <TimePickerElement {...(rest as any)} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || null}
            render={({
              field: { onChange, value, ...field },
              fieldState: { invalid },
            }) => (
              <TimePickerElement
                required={required}
                {...rest}
                value={value ? dayjs(value) : null}
                onChange={(val?: Dayjs | null) => {
                  onChangeValue(onChange, val ? val?.toISOString() : null);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "dateRange":
        element = unregister ? (
          <DateRangePickerElement {...(rest as any)} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || null}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <DateRangePickerElement
                required={required}
                {...field}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "text-editor":
        element = unregister ? (
          <TextEditorElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || ""}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <TextEditorElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "drop-zone":
        element = unregister ? (
          <DropZoneElement {...rest} />
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue || null}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid },
            }) => (
              <DropZoneElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChangeValue(onChange, params);
                }}
                {...field}
                error={invalid}
              />
            )}
          />
        );
        break;
      case "array":
        element = <ArrayElement {...rest} name={fieldName} />;
        break;
      case "section":
        element = <SectionElement {...rest} name={fieldName} />;
        break;
      case "button":
        element = (
          <LoadingButton variant="contained" color="primary" {...rest}>
            {rest.children}
          </LoadingButton>
        );
        break;
      case "raw":
        element = unregister ? (
          typeof render === "function" ? (
            render(rest)
          ) : render ? (
            React.createElement(render as any, rest)
          ) : null
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={(propsRender) => (
              <>{render ? render({ ...rest, ...propsRender }) : null}</>
            )}
          />
        );
        break;
      default:
        element = unregister ? (
          typeof render === "function" ? (
            render(rest)
          ) : render ? (
            React.createElement(render as any, rest)
          ) : null
        ) : (
          <Controller
            shouldUnregister={shouldUnregister}
            name={fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={render}
          />
        );
        break;
    }
    return element;
  }, [
    viewMode,
    mode,
    unregister,
    render,
    shouldUnregister,
    fieldName,
    control,
    rules,
    defaultValue,
    rest,
    register,
    required,
    onChangeValue,
  ]);

  const errorMessage = React.useCallback(() => {
    const tmp = pickBy(get(errors, fieldName), (value, key) => {
      return key !== "ref";
    }) as any;
    const message = tmp?.message || tmp?.type;
    return { ...tmp, message: message };
  }, [errors, fieldName]);

  const content = React.useMemo(() => {
    const messageError =
      errorMessage() &&
      _.entries(errorMessage()).map(([type, message]: [string, unknown]) => {
        return (
          typeof message === "string" &&
          (message ? type !== "type" : true) && (
            <span key={type}>
              {message === "required" ? (
                <FormattedMessage id="required" />
              ) : (
                message
              )}
              <br />
            </span>
          )
        );
      });

    return (
      <>
        <React.Suspense fallback={<Skeleton />}>{getElement}</React.Suspense>
        {mode !== "button" &&
          typeof mode === "string" &&
          mode !== "hidden" &&
          !noHelperText &&
          !viewMode && (
            <Tooltip title={tooltipError ? messageError : ""} arrow>
              <FormHelperText
                style={{ height: 8 }}
                error={!rest?.readOnly}
                component="div"
              >
                <Typography
                  variant="caption"
                  color="inherit"
                  noWrap
                  component="div"
                >
                  {messageError}
                </Typography>
              </FormHelperText>
            </Tooltip>
          )}
      </>
    );
  }, [
    errorMessage,
    getElement,
    mode,
    noHelperText,
    rest?.readOnly,
    tooltipError,
    viewMode,
  ]);
  if (hidden) {
    return null;
  }
  if (mode === "hidden") {
    return content;
  }
  if (rawElement) {
    return <div {...propsWrapper}>{content}</div>;
  }
  return (
    <Grid item xs={12} {...propsWrapper}>
      {content}
    </Grid>
  );
}

export default SchemaElement;
