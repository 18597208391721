import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { authFbOut } from "../redux/authFacebookReducer";
import { logoutSSO } from "../redux/authReducer";
import { setLoading } from "../redux/commonReducer";
import { AppState } from "../redux/store";
import { GM_HEADER_KEY_ACCESS_TOKEN, GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN } from "./constants";

const request = axios.create({
  headers: {
    "accept-language": "en",
    "Content-Type": "application/json",
  },
  method: "get",
  withCredentials: false,
});

export const api = (options: AxiosRequestConfig) => {
  return request({
    ...options,
    headers: {
      ...options.headers,
      Authorization: "Bearer " + Cookies.get(GM_HEADER_KEY_ACCESS_TOKEN),
    },
  });
};

function fetchThunk(
  options: AxiosRequestConfig,
  loading = true
): ThunkAction<Promise<AxiosResponse<any, any>>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    if (loading) dispatch(setLoading(true));
    try {
      const json = await api(options);
      if (json.headers[GM_HEADER_KEY_ACCESS_TOKEN]) {
        Cookies.set(GM_HEADER_KEY_ACCESS_TOKEN, json.headers[GM_HEADER_KEY_ACCESS_TOKEN], {
          expires: dayjs().add(1, "month").toDate(),
        });
        Cookies.set(
          GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN,
          json.headers[GM_HEADER_KEY_GOOGLE_APPS_ACCESS_TOKEN],
          {
            expires: dayjs().add(1, "month").toDate(),
          }
        );
      }
      return json || {};
    } catch (e: any) {
      if (e.response.status === 401) {
        dispatch(logoutSSO());
        dispatch(authFbOut());
      }
      throw e;
    } finally {
      if (loading) dispatch(setLoading(false));
    }
  };
}

export default fetchThunk;
