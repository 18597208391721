import { LoadingButtonProps } from "@mui/lab";
import { BoxProps, GridProps, PaperProps } from "@mui/material";
import { get } from "lodash";
import React, { ReactNode } from "react";
import {
  ControllerFieldState,
  ControllerRenderProps,
  RegisterOptions,
  UseFormReturn,
  UseFormStateReturn,
} from "react-hook-form";
import { E164_REGEX } from "../../config/regex";
import { some } from "../constants";
import useGeneralHook from "../hook/useGeneralHook";
import { PropsArrayElement } from "./element/array-element/ArrayElement";
import { FormControlAutoCompleteProps } from "./element/autocomplete/FormControlAutoComplete";
import { PropsCheckBoxElement } from "./element/checkbox/CheckBoxElement";
import { PropsDateRangePickerElement } from "./element/date-range/DateRangePickerElement";
import { PropsDatePickerElement } from "./element/datepicker-element/DatePickerElement";
import { PropsDateTimePickerElement } from "./element/datepickerTime-element/DateTimePickerElement";
import { PropsDropZoneElement } from "./element/drop-zone/DropZoneElement";
import { PropsMultipleCheckBoxElement } from "./element/multiple-checkbox/MultipleCheckBoxElement";
import { PropsMultipleRadioElement } from "./element/multiple-radio/MultipleRadioElement";
import countryphonecodes from "./element/phone-input/countryphonecodes.json";
import { PropsRadioElement } from "./element/radio/RadioElement";
import { PropsSectionElement } from "./element/section-element/SectionElement";
import { PropsSelectElement } from "./element/select/SelectElement";
import { PropsSwitchElement } from "./element/switch/SwitchElement";
import { TextEditorElementProps } from "./element/text-editor/TextEditorElement";
import { TextFieldElementProps } from "./element/text-field/TextFieldElement";
import { PropsTimePickerElement } from "./element/timepicker-element/TimePickerElement";
import { UploadFileElementProps } from "./element/uploadFile/UploadFileElement";
import { UploadImageElementProps } from "./element/uploadImage/UploadImageElement";
export interface IControllerRenderProps extends some {
  field: ControllerRenderProps<some, string>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<some>;
}

export type ElementMode =
  | "hidden"
  | "text"
  | "text-field"
  | "phone-field"
  | "auto-complete"
  | "select"
  | "datePicker"
  | "button"
  | "drop-zone"
  | "switch"
  | "checkbox"
  | "multiple-checkbox"
  | "radio"
  | "multiple-radio"
  | "array"
  | "section"
  | "dateTimePicker"
  | "timePicker"
  | "dateRange"
  | "text-editor"
  | "uploadImage"
  | "uploadFile"
  | "raw";

/*---------------------------*/

interface ElementBaseProps {
  mode: ElementMode;
  key_element?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  propsWrapper?: GridProps;
  defaultValue?: any;
  unregister?: boolean;
  tooltipError?: boolean;
  noHelperText?: boolean;
  hidden?: boolean;
  shouldUnregister?: boolean;
  mapperValue?: (...parmas) => any;
}

interface IHiddenElement {
  type?: "hiddenField";
}

export interface IDropZoneElement
  extends ElementBaseProps,
    PropsDropZoneElement {
  mode: "drop-zone";
}
export interface IRawElement {
  mode: "raw";
  render?: (props: IControllerRenderProps) => React.ReactElement;
}

export interface IArrayElement extends ElementBaseProps, PropsArrayElement {
  mode: "array";
}
export interface IButtonElement
  extends ElementBaseProps,
    Omit<LoadingButtonProps, "defaultValue"> {
  mode: "button";
  label?: React.ReactNode | string;
}
export interface ITextElement {
  mode: "text";
  label?: React.ReactNode;
  boxProps: BoxProps;
}
export interface ITextFieldElement
  extends ElementBaseProps,
    TextFieldElementProps {
  mode: "text-field";
}
export interface ISectionElement extends ElementBaseProps, PropsSectionElement {
  mode: "section";
}
export interface IUploadFileElement
  extends ElementBaseProps,
    UploadFileElementProps {
  mode: "uploadFile";
}
export interface IUploadImageElement
  extends ElementBaseProps,
    UploadImageElementProps {
  mode: "uploadImage";
}

export interface ICheckBoxElement
  extends ElementBaseProps,
    PropsCheckBoxElement {
  mode: "checkbox";
}

export interface IMultipleCheckBoxElement
  extends ElementBaseProps,
    PropsMultipleCheckBoxElement {
  mode: "multiple-checkbox";
}

export interface IRadioElement extends ElementBaseProps, PropsRadioElement {
  mode: "checkbox";
}
export interface ISelectElement extends ElementBaseProps, PropsSelectElement {
  mode: "select";
}

export interface IMultipleRadioElement
  extends ElementBaseProps,
    PropsMultipleRadioElement {
  mode: "multiple-radio";
}

export interface ISwitchElement extends ElementBaseProps, PropsSwitchElement {
  mode: "switch";
}
export interface IDatePickerElement
  extends ElementBaseProps,
    PropsDatePickerElement {
  mode: "datePicker";
}
export interface IDateTimePickerElement
  extends ElementBaseProps,
    PropsDateTimePickerElement {
  mode: "dateTimePicker";
}
export interface ITimePickerElement
  extends ElementBaseProps,
    PropsTimePickerElement {
  mode: "timePicker";
}
export interface IDateRangePickerElement
  extends ElementBaseProps,
    PropsDateRangePickerElement {
  mode: "dateRange";
}

export interface IAutoCompleteElement
  extends ElementBaseProps,
    Omit<FormControlAutoCompleteProps, "defaultValue"> {
  mode: "auto-complete";
}
export interface ITextEditorElement
  extends ElementBaseProps,
    TextEditorElementProps {
  mode: "text-editor";
}

export type ElementFormProps =
  | IDropZoneElement
  | IHiddenElement
  | IAutoCompleteElement
  | ITextFieldElement
  | ITextElement
  | IUploadFileElement
  | IUploadImageElement
  | ICheckBoxElement
  | IMultipleCheckBoxElement
  | IRadioElement
  | ISelectElement
  | IMultipleRadioElement
  | ISwitchElement
  | IDatePickerElement
  | IDateTimePickerElement
  | ITimePickerElement
  | IDateRangePickerElement
  | IButtonElement
  | ISectionElement
  | ITextEditorElement
  | IArrayElement
  | IRawElement
  | ElementBaseProps;

type SchemaTypeTmp<T> = {
  [key in keyof Partial<T>]: ElementFormProps;
};

export type SchemaType<T> = SchemaTypeTmp<T> & {
  [key: string]: ElementFormProps;
};

/*---------------------------*/

export interface FormProps extends ReturnType<typeof useGeneralHook>, some {}

export type ISchemaFields<T> = (params: {
  methods: UseFormReturn;
  fieldName?: string;
  formProps: FormProps;
  valuesField: any;
}) => SchemaType<T>;
export interface FieldsType<T> {
  id: string;
  fields: (keyof T)[] | (string | undefined)[];
  title?: ReactNode;
  paper?: boolean;
  paperProps?: PaperProps;
  propsWrapper?: GridProps;
  propsGridContainer?: GridProps;
  hidden?: boolean;
  [key: string]: any;
}

export interface FieldsElementType<T> extends Omit<FieldsType<T>, "fields"> {
  fields: ElementFormProps[];
}

export type IUiFields<T extends some> = (params: {
  methods: UseFormReturn<T>;
  formProps: FormProps;
  valuesField?: any;
}) => FieldsType<T>[];

interface IParamsLayoutFields<T extends some> {
  valuesField: any;
  fields: any;
  methods: UseFormReturn<T>;
  formProps: FormProps;
  view: ReactNode;
  listElement: ReactNode[];
}

type ILayoutFields<T extends some> = (
  params: IParamsLayoutFields<T>
) => ReactNode;

export interface ISchemaForm<T extends any | undefined = any> {
  // propsWrapperContainer?: GridProps;
  propsGridContainer?: GridProps;
  fields: ISchemaFields<T> | SchemaType<T>;
  ui?: IUiFields<any> | FieldsType<T>[];
  layout?: ILayoutFields<any>;
  changeDataBeforeSubmit?: (
    values: any,
    dirtyValues: any,
    props: FormProps
  ) => any;
}

/*---------------------------*/

export const mergeFieldName = (params: {
  name: string;
  parent?: string;
  index?: number;
}) => {
  const { name, parent, index } = params;
  if (parent && typeof index === "number") {
    return `${parent}.${index}.${name}`;
  }
  if (parent) {
    return `${parent}.${name}`;
  }
  if (typeof index === "number") {
    return `${name}.${index}`;
  }
  return name;
};

export const getFieldForm = (
  methods: UseFormReturn<any>,
  formProps: FormProps,
  schema?: ISchemaForm<any>,
  fieldName?: `${string}` | `${string}.${string}` | `${string}.${number}`,
  showSubmitButton?: boolean,
  props?: any
) => {
  if (!schema) {
    return { groupFields: [] };
  }
  const { intl } = formProps;
  const valuesField = methods
    ? ((fieldName ? methods?.watch(fieldName) : methods?.watch()) as any)
    : {};
  const fields =
    typeof schema?.fields === "function"
      ? schema?.fields({ valuesField, methods, fieldName, formProps })
      : schema?.fields;
  const ui =
    typeof schema?.ui === "function"
      ? schema?.ui({ valuesField, methods, formProps })
      : schema?.ui;

  let groupFields = ui
    ? (ui
        .filter(Boolean)
        .reduce((value: any[], current: any, index: number) => {
          const flatValue = value
            .filter((v) => !v.hidden)
            .reduce((val: any[], cur: any, idx: number) => {
              return [
                ...val,
                ...get(cur, "fields", [])
                  .map((v) => v.key_element)
                  .filter(Boolean),
              ];
            }, []);
          const tmp = get(current, "fields", []);
          return [
            ...value,
            {
              ...current,
              fields: (Array.isArray(tmp) ? tmp : [])
                .filter((val) => !flatValue.includes(val))
                .map((val: string) => {
                  const field = get(fields, val) as any;
                  return (
                    field && {
                      ...formProps.globalProps,
                      readOnly: formProps.readOnly,
                      viewMode: formProps.viewMode,
                      ...field,
                      key_element: val,
                    }
                  );
                })
                .filter(Boolean),
            },
          ];
        }, [])
        .filter(Boolean) as FieldsElementType<any>[])
    : ([
        {
          id: "default",
          fields: Object.entries(fields).map(([key, value]) => {
            return {
              key_element: key,
              ...formProps.globalProps,
              readOnly: formProps.readOnly,
              viewMode: formProps.viewMode,
              ...(value as any),
            };
          }),
        },
      ] as FieldsElementType<any>[]);

  groupFields = [
    ...(groupFields as FieldsElementType<any>[]),
    {
      id: "footer",
      propsGridContainer: { justifyContent: "flex-end" },
      hidden: formProps.readOnly || formProps.viewMode || !showSubmitButton,
      fields: [
        {
          key_element: "resetBtn",
          mode: "button",
          variant: "outlined",
          sx: { minWidth: 40, px: 1 },
          children: props.refreshIcon,
          onClick: () =>
            typeof formProps?.onReset === "function"
              ? formProps?.onReset(methods)
              : methods.reset(),
          hidden: !formProps?.onReset,
          title: "Reset",
          propsWrapper: {
            xs: undefined,
          },
        },
        {
          key_element: "cancel",
          mode: "button",
          variant: "outlined",
          style: { minWidth: 100 },
          children:
            formProps.cancelLabel || intl.formatMessage({ id: "cancel" }),
          onClick: () => formProps?.onCancel(methods),
          hidden: !formProps?.onCancel,
          title: "Cancel",
          propsWrapper: {
            xs: undefined,
          },
        },
        {
          key_element: "submit",
          mode: "button",
          type: "submit",
          title: "Submit",
          style: { minWidth: 100 },
          children: formProps.submitLabel || intl.formatMessage({ id: "apply" }),
          loading: formProps.loading,
          propsWrapper: {
            xs: undefined,
          },
        },
      ],
    } as FieldsElementType<any>,
  ].filter(Boolean) as FieldsElementType<any>[];

  return { groupFields };
};

/*---------------------------*/
export const submitForm = (id: string) => {
  const submitBtn = document.getElementById(id);
  if (
    submitBtn &&
    submitBtn.tagName === "INPUT" &&
    submitBtn.getAttribute("type") === "submit"
  ) {
    submitBtn.click();
  }
};

export const validatePhoneNumber = (value: string, intl: any) => {
  return value
    ? !!countryphonecodes.find((v) => v.code === value)
      ? intl.formatMessage({ id: "required" })
      : E164_REGEX.test(value)
      ? true
      : intl.formatMessage({ id: "invalidPhoneNumber" })
    : intl.formatMessage({ id: "required" });
};
