import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormattedMessage } from "react-intl";

interface Props extends Omit<DialogProps, "onClose" | "title"> {
  onClose?: (
    value: boolean,
    reason: "backdropClick" | "escapeKeyDown" | "button"
  ) => void;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  disableCloseBtn?: boolean;
}

const DialogCustom: React.FunctionComponent<Props> = (props) => {
  const { children, onClose, title, footer, open, disableCloseBtn, ...rest } =
    props;

  return (
    <Dialog
      keepMounted={false}
      {...rest}
      onClose={(_, reason) => {
        onClose && onClose(false, reason);
      }}
      open={open}
    >
      {title ? (
        <Box
          sx={{
            position: "sticky",
            top: 0,
            padding: "12px 16px",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            bgcolor: "background.paper",
          }}
        >
          <Box flex={1}>
            {typeof title === "string" ? (
              <Typography variant="h5" color="primary">
                <FormattedMessage id={title} />
              </Typography>
            ) : (
              title
            )}
          </Box>
          {!disableCloseBtn && (
            <Box justifySelf="flex-end">
              <IconButton
                onClick={() => onClose && onClose(false, "button")}
                size="small"
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      ) : (
        !disableCloseBtn && (
          <Box
            position="absolute"
            top={6}
            right={0}
            marginX={2}
            marginY={1}
            zIndex={100}
          >
            <IconButton
              onClick={() => onClose && onClose(false, "button")}
              size="small"
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>
        )
      )}
      {children}
      {footer && (
        <Box
          bgcolor="primary.50"
          style={{
            position: "sticky",
            bottom: 0,
            padding: "12px 16px",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {footer}
        </Box>
      )}
    </Dialog>
  );
};
export default DialogCustom;
