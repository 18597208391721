import { Close } from "@mui/icons-material";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { FunctionComponent, useState } from "react";
import DialogCustom from "./DialogCustom";

interface Props extends AvatarProps {
  children?: React.ReactNode;
  isAvatar?: boolean;
  loadingContent?: React.ReactNode;
}
const ImageDialog: FunctionComponent<Props> = (props: Props) => {
  const {
    children,
    sx,
    src,
    onClick,
    isAvatar,
    loadingContent,
    alt = "avatar",
    ...rest
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Avatar
        variant={isAvatar ? "circular" : "square"}
        sx={
          isAvatar
            ? sx
            : { height: "unset", width: "unset", cursor: "pointer", ...sx }
        }
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(e);
          src && setOpen(true);
        }}
        alt={alt}
        src={src}
        {...rest}
      >
        {children}
      </Avatar>
      <DialogCustom
        open={open}
        onClose={setOpen}
        disableCloseBtn
        PaperProps={{
          sx: { borderRadius: 0, bgcolor: "transparent", boxShadow: "none" },
        }}
      >
        <IconButton
          sx={{
            position: "fixed",
            top: 32,
            right: 32,
            bgcolor: "white",
            ":hover": {
              bgcolor: "white",
            },
            color: "black",
          }}
          onClick={() => setOpen(false)}
        >
          <Close />
        </IconButton>
        <Avatar
          variant="square"
          sx={{ height: "unset", width: "unset", cursor: "pointer" }}
          src={src}
          alt={alt}
        >
          {children}
        </Avatar>
      </DialogCustom>
    </>
  );
};
export default ImageDialog;
