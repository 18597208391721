import { Close, EditOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import MuiTable from "../../../../../common/components/MuiTable";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { HookPaginationProps } from "../../../../../common/hook/usePaginationHook";
import { addEmailForm } from "../../../../../redux/leadReducer";
import { AppState } from "../../../../../redux/store";
import EmailViewBox from "./EmailViewBox";
import FilterBox from "./FilterBox";

interface Props {
  data?: AxiosResponse;
  loading: boolean;
  pagination: HookPaginationProps;
  refetch: () => void;
}
const ListEmailBox = (props: Props) => {
  const { data, loading, pagination, refetch } = props;
  const { onPageChange, onRowsPerPageChange } = pagination;
  const { dispatch, intl } = useGeneralHook();
  const source = data?.data?.data || [];
  const agentData =
    useSelector((state: AppState) => state.auth.agentData, shallowEqual) || {};
  const leadDetail =
    useSelector((state: AppState) => state.lead.leadDetail, shallowEqual) || {};

  const agentBusinessEmail = `${agentData?.name} <${agentData?.business_email}>`;
  const agentPersonalEmail = agentData?.personal_email;
  const leadEmail = `${leadDetail?.name} <${leadDetail?.email}>`;
  const unsubscribed = leadDetail.email_unsubscribed;

  return (
    <MuiTable
      data={source}
      state={{
        showSkeletons: loading,
      }}
      renderTopToolbarCustomActions={({ table: { getState } }) => {
        if (unsubscribed)
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Close color="error" />
              <Typography sx={{ fontStyle: "italic" }}>
                <FormattedMessage id="emailUnsubscribed" />
              </Typography>
            </Box>
          );

        return (
          <Box className="flex gap-2 items-center flex-1">
            <FilterBox pagination={pagination} />
            <Button
              disabled={unsubscribed}
              variant="contained"
              startIcon={<EditOutlined />}
              onClick={() =>
                dispatch(
                  addEmailForm({
                    id: new Date().valueOf(),
                    from: agentBusinessEmail,
                    reply_to: agentBusinessEmail,
                    to: leadEmail,
                    cc: agentPersonalEmail,
                  })
                )
              }
            >
              <FormattedMessage id="compose" />
            </Button>
          </Box>
        );
      }}
      muiTableHeadRowProps={{ sx: { display: "none" } }}
      enableHiding={false}
      paginationProps={{
        count: data?.data?.count,
        page: data?.data?.metadata.page_no,
        rowsPerPage: data?.data?.metadata.page_size,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
      }}
      columns={[
        {
          header: intl.formatMessage({ id: "from" }),
          Cell: ({ row }) => {
            const record = row.original;
            const isFromAgent = record?.sender.includes(
              agentData?.business_email
            );
            return (
              <EmailViewBox
                unsubscribed={unsubscribed}
                isFromAgent={isFromAgent}
                key={record.message_id}
                data={record}
                callback={refetch}
              />
            );
          },
        },
      ]}
    />
  );
};
export default ListEmailBox;
