import { Typography } from "@mui/material";
import { getWhatsappText } from "../../../../../common/components/elements";
import { some } from "../../../../../common/constants";
import FooterMessage from "./FooterMessage";

interface Props {
  message: some;
  readOnly?: boolean;
  isOwner?: boolean;
}

const TextItem = (props: Props) => {
  const { message, isOwner, readOnly } = props;

  if (readOnly) {
    return (
      <Typography variant="body1" className="line-clamp-2">
        {getWhatsappText(message.content?.text)}
      </Typography>
    );
  }
  return (
    <>
      <Typography
        variant="body1"
        maxWidth={460}
        sx={{
          display: "block",
          whiteSpace: "pre-wrap",
          wordBreak: "break-all",
        }}
      >
        {getWhatsappText(message.content?.text)}
      </Typography>
      <FooterMessage message={message} isOwner={isOwner} />
    </>
  );
};

export default TextItem;
