import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { some } from "../common/constants";

export type FormMode = "update" | "create" | "delete" | "view";

export interface FormState {
  formData: some;
  callback: some;
}

export const initialStateForm: FormState = { formData: {}, callback: {} };

export const formSlice = createSlice({
  name: "auth",
  initialState: initialStateForm,
  reducers: {
    setFormDataRedux: (
      state,
      action: PayloadAction<{
        name: string;
        data?: some;
        callback?: (json) => void;
      }>
    ) => {
      state.formData = {
        ...state.formData,
        [action.payload.name]: action.payload.data,
      };
      state.callback = {
        ...state.callback,
        [action.payload.name]: action.payload.callback,
      };
    },
  },
});

export const { setFormDataRedux } = formSlice.actions;

export default formSlice.reducer;
