import {
  InsertDriveFileRounded,
  OpenInNew,
  PhoneRounded,
  PhotoSizeSelectActualRounded,
  PlayCircle,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useMemo } from "react";
import ImageDialog from "../../../../../common/components/ImageDialog";
import MyLink from "../../../../../common/components/MyLink";
import { getWhatsappText } from "../../../../../common/components/elements";
import { some } from "../../../../../common/constants";
import FooterMessage from "./FooterMessage";

interface Props {
  message: some;
  isOwner?: boolean;
  readOnly?: boolean;
}

const HSMItem = (props: Props) => {
  const { message, readOnly, isOwner } = props;
  const template = message.template;

  const mappedData = useMemo(() => {
    const headerObj = template?.components?.find((v) => v.type === "HEADER");
    const bodyObj = template?.components?.find((v) => v.type === "BODY");
    const footerObj = template?.components?.find((v) => v.type === "FOOTER");
    const btnObj = template?.components?.find((v) => v.type === "BUTTONS");
    const buttonType =
      btnObj?.buttons?.[0]?.type === "QUICK_REPLY"
        ? "QUICK_REPLY"
        : !!btnObj?.buttons?.[0]?.type
        ? "ACTION"
        : "none";

    return {
      headerType: headerObj?.format,
      headerText: headerObj?.text,
      headerUrl: headerObj?.example?.header_url?.[0],
      body: bodyObj?.text,
      footer: footerObj?.text,
      buttonType: buttonType,
      quickReply: buttonType === "QUICK_REPLY" ? btnObj?.buttons : undefined,
      btnCallToAction:
        buttonType !== "QUICK_REPLY" ? btnObj?.buttons : undefined,
    };
  }, [template?.components]);

  const header = useMemo(() => {
    return (
      <>
        {mappedData?.headerType === "TEXT" ? (
          <Typography color="inherit" variant="h6">
            {getWhatsappText(mappedData.headerText)}
          </Typography>
        ) : mappedData?.headerType === "IMAGE" ? (
          <ImageDialog
            src={mappedData.headerUrl}
            variant="rounded"
            sx={{ height: 160, width: 240, mx: "auto" }}
            className=" my-2 text-white flex items-center justify-center rounded-lg"
          >
            <PhotoSizeSelectActualRounded style={{ fontSize: 80 }} />
          </ImageDialog>
        ) : mappedData?.headerType === "DOCUMENT" ? (
          <MyLink
            href={mappedData.headerUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <ImageDialog
              variant="rounded"
              sx={{ height: 160, width: 240, mx: "auto" }}
              className=" my-2 text-white flex items-center justify-center rounded-lg"
            >
              <InsertDriveFileRounded style={{ fontSize: 80 }} />
            </ImageDialog>
          </MyLink>
        ) : mappedData?.headerType === "VIDEO" ? (
          <MyLink
            href={mappedData.headerUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <ImageDialog
              variant="rounded"
              sx={{ height: 160, width: 240, mx: "auto" }}
              className=" my-2 text-white flex items-center justify-center rounded-lg"
            >
              <PlayCircle style={{ fontSize: 80 }} />
            </ImageDialog>
          </MyLink>
        ) : null}
      </>
    );
  }, [mappedData?.headerType, mappedData.headerText, mappedData.headerUrl]);

  const buttons = useMemo(() => {
    const arrayBtn = template?.components?.find(
      (v) => v.type === "BUTTONS"
    )?.buttons;
    if (!arrayBtn?.length) {
      return null;
    }
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, my: 1 }}>
        {arrayBtn?.map((item, index) => {
          if (item.type === "PHONE_NUMBER") {
            return (
              <MyLink
                key={index}
                href={`tel:${item.phone_number}`}
                underline="none"
              >
                <Button variant="outlined" sx={{ bgcolor: "transparent" }}>
                  <PhoneRounded /> &emsp;
                  <Typography color={"inherit"} noWrap maxWidth={160}>
                    {item.phone_number}
                  </Typography>
                </Button>
              </MyLink>
            );
          }
          if (item.type === "URL") {
            return (
              <MyLink
                key={index}
                href={item.url}
                target="_blank"
                underline="none"
                rel="noreferrer noopener"
              >
                <Button variant="outlined" sx={{ bgcolor: "transparent" }}>
                  <OpenInNew /> &emsp;
                  <Typography color={"inherit"} noWrap maxWidth={160}>
                    {item.text}
                  </Typography>
                </Button>
              </MyLink>
            );
          }
          return (
            <Button
              key={index}
              variant="outlined"
              sx={{ bgcolor: "transparent" }}
            >
              <Typography color={"inherit"} noWrap maxWidth={160}>
                {item.text}
              </Typography>
            </Button>
          );
        })}
      </Box>
    );
  }, [template?.components]);
  if (readOnly) {
    return (
      <>
        {header}
        <Typography variant="body1" className="line-clamp-2">
          {mappedData?.body}
        </Typography>
      </>
    );
  }
  return (
    <>
      {header}
      {mappedData?.body && (
        <Typography variant="body1" component={"div"}>
          {getWhatsappText(mappedData?.body)}
        </Typography>
      )}
      {mappedData?.footer && (
        <Typography variant="body2" color={"textSecondary"}>
          {getWhatsappText(mappedData?.footer)}
        </Typography>
      )}
      {buttons}
      <FooterMessage message={message} isOwner={isOwner} />
    </>
  );
};

export default HSMItem;
