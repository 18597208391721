import {
  DownloadRounded,
  ImageRounded,
  InsertDriveFileRounded,
  Reply,
  ReplyAll,
  ReplyRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import ImageDialog from "../../../../../common/components/ImageDialog";
import MyLink from "../../../../../common/components/MyLink";
import { some } from "../../../../../common/constants";
import useGeneralHook from "../../../../../common/hook/useGeneralHook";
import { EmailForm } from "../../../../../redux/leadReducer";
import EmailSendMessageBox from "./EmailSendMessageBox";
import "./styles.css";

interface Props {
  data: some;
  lastMessage?: boolean;
  unsubscribed?: boolean;
  isFromAgent?: boolean;
  callback?: () => void;
}
const EmailViewBox = (props: Props) => {
  const { data, lastMessage, unsubscribed, isFromAgent, callback } = props;
  const { API_PATHS, dispatch, fetchThunk, copyToClipboard } = useGeneralHook();
  const [formReply, setFormReply] = useState<EmailForm | undefined>();
  const enableReply = !unsubscribed && !isFromAgent;

  const onDownload = async (file) => {
    const res = await dispatch(
      fetchThunk({
        url: API_PATHS.email.attachments(file),
        method: "get",
        responseType: "blob",
      })
    );
    const url = window.URL.createObjectURL(res.data);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const isOutbound = data.direction === "sending";

  return (
    <>
      <Box
        component={"div"}
        className="flex gap-2"
        sx={{
          maxWidth: "85%",
          marginLeft: isOutbound ? "auto" : 0,
          marginRight: isOutbound ? 0 : "auto",
        }}
      >
        <Box
          className="gap-1 overflow-auto"
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography variant="h6" component={"div"} className="flex">
            <FormattedMessage id="subject" />
            :&nbsp;
            <Typography variant="inherit" color="inherit" noWrap>
              {data.subject}
            </Typography>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="from" />
            :&nbsp;
            <MyLink href={`mailto:${data.sender}`}>{data.sender}</MyLink>
          </Typography>
          <Typography variant="caption" color="textSecondary" component={"div"}>
            <FormattedMessage id="to" />
            :&nbsp;
            <MyLink href={`mailto:${data.recipient}`}>{data.recipient}</MyLink>
          </Typography>
          {data.reply_to && (
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="reply_to" />
              :&nbsp;
              <MyLink href={`mailto:${data.reply_to}`}>{data.reply_to}</MyLink>
            </Typography>
          )}
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="direction" />
            :&nbsp;<span className="capitalize">{data.direction}</span>
          </Typography>
          {data.cc?.length > 0 && (
            <Typography variant="body2" color="textSecondary" component={"div"}>
              <FormattedMessage id="cc" />
              :&nbsp;
              {data.cc?.map((email, index) => {
                return (
                  <React.Fragment key={index}>
                    {index > 0 && ", "}
                    <MyLink href={`mailto:${email}`}>{email}</MyLink>
                  </React.Fragment>
                );
              })}
            </Typography>
          )}
          {data.bcc?.length > 0 && (
            <Typography variant="body2" color="textSecondary" component={"div"}>
              <FormattedMessage id="bcc" />
              :&nbsp;
              {data.bcc?.map((email, index) => {
                return (
                  <React.Fragment key={index}>
                    {index > 0 && ", "}
                    <MyLink href={`mailto:${email}`}>{email}</MyLink>
                  </React.Fragment>
                );
              })}
            </Typography>
          )}
          <Typography
            variant="body2"
            component={"div"}
            sx={{ my: 1, whiteSpace: "pre-wrap" }}
            className="email-body"
            dangerouslySetInnerHTML={{ __html: data.body_html }}
          />
          <Box className="flex gap-2 flex-wrap">
            {data.attachments?.map((file, idx) => {
              let content;
              if (file["content-type"].includes("image")) {
                content = (
                  <ImageDialog
                    src={API_PATHS.email.attachments(file)}
                    sx={{ width: 220, height: 120 }}
                  >
                    <ImageRounded sx={{ width: 112, height: 112 }} />
                  </ImageDialog>
                );
              } else {
                content = (
                  <ImageDialog sx={{ width: 220, height: 120 }}>
                    <InsertDriveFileRounded sx={{ width: 112, height: 112 }} />
                  </ImageDialog>
                );
              }
              return (
                <Paper
                  key={idx}
                  elevation={4}
                  sx={{
                    position: "relative",
                    borderRadius: 1,
                    overflow: "hidden",
                  }}
                >
                  {content}
                  <Box
                    sx={{
                      bgcolor: "grey.100",
                      color: "white",
                      p: 1,
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Box flex={1}>
                      <Typography variant="subtitle2">{file.name}</Typography>
                      <Typography variant="body2" color={"textSecondary"}>
                        {Math.round(file.size / 1000)}KB
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      sx={{ bgcolor: "transparent", p: 0, width: 40 }}
                      onClick={() => onDownload(file)}
                    >
                      <DownloadRounded />
                    </Button>
                  </Box>
                </Paper>
              );
            })}
          </Box>
          <Typography
            variant="caption"
            color={"textSecondary"}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: isOutbound ? "flex-end" : "flex-start",
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
              copyToClipboard(data.message_key || data.message_id, true);
            }}
          >
            {data?.timestamp
              ? `${dayjs(data.timestamp).format("LL")} (${dayjs(
                  data.timestamp
                ).fromNow()})`
              : "-"}
          </Typography>
        </Box>
        <Box>
          {enableReply && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setFormReply({
                  id: new Date().valueOf(),
                  from: data.sender,
                  reply_to: data.reply_to || data.sender,
                  to: data.recipient,
                });
              }}
            >
              <ReplyRounded />
            </IconButton>
          )}
        </Box>
      </Box>
      {lastMessage && <Divider />}
      {lastMessage && enableReply && !formReply && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 2 }}>
          <Button
            variant="outlined"
            sx={{ borderRadius: 10 }}
            startIcon={<Reply />}
            onClick={() => {
              setFormReply({
                id: new Date().valueOf(),
                from: data.sender,
                reply_to: data.reply_to || data.sender,
                to: data.recipient,
              });
            }}
          >
            <FormattedMessage id="reply" />
          </Button>
          <Button
            variant="outlined"
            sx={{ borderRadius: 10 }}
            startIcon={<ReplyAll />}
            onClick={() => {
              setFormReply({
                id: new Date().valueOf(),
                from: data.sender,
                reply_to: data.reply_to || data.sender,
                to: data.recipient,
                cc: data.cc.join(","),
              });
            }}
          >
            <FormattedMessage id="reply_all" />
          </Button>
        </Box>
      )}
      {formReply && enableReply && (
        <EmailSendMessageBox
          formData={formReply}
          onClose={() => setFormReply(undefined)}
          callback={callback}
        />
      )}
    </>
  );
};
export default EmailViewBox;
