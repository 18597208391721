import dayjs, { Dayjs } from "dayjs";
import { some } from "../../common/constants";

export interface IEvent {
  id?: string;
  summary?: string;
  description?: string;
  location?: string;
  end: Dayjs;
  start: Dayjs;
  attendees?: {
    additionalGuests?: number;
    comment?: string;
    displayName?: string;
    email?: string;
    optional?: boolean;
    resource?: boolean;
    self?: boolean;
    organizer?: boolean;
    id?: boolean;
  }[];
  reminders: {
    useDefault: boolean;
    overrides?: {
      method: "popup" | "email";
      value: number;
      type: "minutes" | "hours" | "days" | "weeks";
    }[];
  };
  allDay?: boolean;
  htmlLink?: string;
  hangoutLink?: string;
  transparency?: string;
  visibility?: string;
  conferenceData?: some;
  creator?: some;
  attachments?: any;
  useMeet?: boolean;
}
export const TimeTypeOptions = [
  { id: "minutes", name: "minutes", val: 1 },
  { id: "hours", name: "hours", val: 60 },
  { id: "days", name: "days", val: 60 * 24 },
  { id: "weeks", name: "weeks", val: 60 * 24 * 7 },
];
export const mappedUpdateEventParams = (value: IEvent) => {
  const {
    allDay,
    start,
    end,
    id,
    summary,
    location,
    attendees,
    reminders,
    transparency,
    visibility,
    description,
  } = value;
  return {
    id: id,
    conferenceData: value.useMeet
      ? {
          createRequest: {
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
            requestId: "gomedia" + dayjs().valueOf(),
          },
        }
      : null,
    attendees,
    summary,
    location,
    reminders: {
      ...reminders,
      overrides: reminders.overrides?.map((val) => {
        let minutes = val.value;
        if (val.type === "hours") {
          minutes = val.value * 60;
        } else if (val.type === "days") {
          minutes = val.value * 60 * 24;
        } else if (val.type === "weeks") {
          minutes = val.value * 60 * 24 * 7;
        }
        return { method: val.method, minutes };
      }),
    },
    transparency,
    visibility,
    description,
    start: allDay
      ? {
          date: start?.format("YYYY-MM-DD"),
        }
      : {
          dateTime: start,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    end: allDay
      ? {
          date: dayjs(end?.toISOString()).format("YYYY-MM-DD"),
        }
      : {
          dateTime: end,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
  };
};
export const mappedEventFormData = (val): IEvent => {
  const tmp = {
    startTime: val.end?.dateTime
      ? dayjs(val.start?.dateTime).tz(val.start?.timeZone)
      : dayjs(val.start?.date),
    endTime: val.end?.dateTime
      ? dayjs(val.end?.dateTime).tz(val.end?.timeZone)
      : dayjs(val.end?.date),
  };
  return {
    ...val,
    id: val.id,
    start: tmp.startTime,
    end: tmp.endTime,
    useMeet: !!val.conferenceData?.conferenceSolution,
    allDay: !!val.start?.date,
    reminders: {
      ...val?.reminders,
      overrides: val?.reminders?.overrides?.map((v) => {
        let type = "minutes";
        let val = v.minutes;
        if (Number.isInteger(v.minutes / 60 / 24 / 7)) {
          val = v.minutes / 60 / 24 / 7;
          type = "weeks";
        } else if (Number.isInteger(v.minutes / 60 / 24)) {
          val = v.minutes / 60 / 24;
          type = "days";
        } else if (Number.isInteger(v.minutes / 60)) {
          val = v.minutes / 60;
          type = "hours";
        }
        return {
          method: v.method,
          type,
          value: Math.round(val * 100) / 100,
        };
      }),
    },
  };
};
