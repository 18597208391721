import {
  AttachMoney,
  BarChart,
  CalendarMonthRounded,
  Facebook,
  FlashOnRounded,
  Group,
  LibraryBooks,
  Send,
  ViewColumn,
} from "@mui/icons-material";
import React from "react";
import { Navigate } from "react-router";
import { IRouteObject } from "../common/utils";
import CalendarGooglePage from "../modules/calendar/CalendarGooglePage";
import CallTab from "../modules/opportunities/lead-detail/communications/call-tab/CallTab";
import EmailTab from "../modules/opportunities/lead-detail/communications/email-tab/EmailTab";
import WhatsappMessageTab from "../modules/opportunities/lead-detail/communications/whatsapp-message/WhatsappMessageTab";
import ProfilePage from "../modules/profile/ProfilePage";
import { ROUTES_PATH } from "./constants";

const ContactsPage = React.lazy(
  () => import("../modules/contact/ContactsPage")
);
const FacebookAdsManagerPage = React.lazy(
  () => import("../modules/facebook_ads/FacebookAdsManagerPage")
);
const CreateAdsCampaign = React.lazy(
  () => import("../modules/facebook_ads/campaigns/CreateAdsCampaign")
);
const InboxPage = React.lazy(() => import("../modules/inbox/InboxPage"));
const LeadDetailPageWrapper = React.lazy(
  () => import("../modules/opportunities/lead-detail/LeadDetailPageWrapper")
);
const ActivitiesTab = React.lazy(
  () => import("../modules/opportunities/lead-detail/activities/ActivitiesTab")
);
const CommunicationsTab = React.lazy(
  () =>
    import(
      "../modules/opportunities/lead-detail/communications/CommunicationsTab"
    )
);
const LeadsKanbanView = React.lazy(
  () => import("../modules/opportunities/leads/LeadsKanbanView")
);
const ReportPage = React.lazy(() => import("../modules/report/ReportPage"));
const CallsReportPage = React.lazy(
  () => import("../modules/report/calls/CallsReportPage")
);
const EmailsReportPage = React.lazy(
  () => import("../modules/report/emails/EmailsReportPage")
);
const LeadsReportPage = React.lazy(
  () => import("../modules/report/leads/LeadsReportPage")
);
const WhatsAppsReportPage = React.lazy(
  () => import("../modules/report/whatsapp/WhatsAppsReportPage")
);
const TemplatePage = React.lazy(
  () => import("../modules/template/TemplatePage")
);
const TemplateEmailPage = React.lazy(
  () => import("../modules/template/email/TemplateEmailPage")
);
const TemplateMBGeneratorPage = React.lazy(
  () => import("../modules/template/messagebird/TemplateMBGeneratorPage")
);
const TemplateMBPage = React.lazy(
  () => import("../modules/template/messagebird/TemplateMBPage")
);
const BlastPage = React.lazy(() => import("../modules/blast/BlastPage"));
const BlastEmailsHistory = React.lazy(
  () => import("../modules/blast/emails/BlastEmailsHistory")
);
const BlastWhatsappHistory = React.lazy(
  () => import("../modules/blast/whatsapp/BlastWhatsappHistory")
);
const BillingPage = React.lazy(() => import("../modules/billing/BillingPage"));

export const PROJECT_LIST_ROUTES: IRouteObject[] = [
  {
    title: "profile",
    path: ROUTES_PATH.profile,
    element: <ProfilePage />,
    hidden: true,
  },
  {
    title: "contacts",
    path: ROUTES_PATH.contacts.index,
    icon: <Group />,
    children: [
      {
        element: <ContactsPage />,
        index: true,
      },
      {
        path: ROUTES_PATH.contacts.detail,
        portalId: "leadName",
        element: <LeadDetailPageWrapper />,
        hidden: true,
        blockBreadcrumb: true,
        children: [
          {
            title: "communications",
            path: ROUTES_PATH.contacts.communications.index,
            element: <CommunicationsTab />,
            hidden: true,
            collapseWhenIn: true,
            children: [
              {
                title: "call",
                path: ROUTES_PATH.contacts.communications.calls,
                element: <CallTab />,
              },
              {
                title: "whatsapp",
                path: ROUTES_PATH.contacts.communications.whatsapp,
                element: <WhatsappMessageTab />,
              },
              {
                title: "emails",
                path: ROUTES_PATH.contacts.communications.emails,
                element: <EmailTab />,
              },
              {
                path: "*",
                element: (
                  <Navigate
                    replace
                    to={ROUTES_PATH.contacts.communications.whatsapp}
                  />
                ),
                hidden: true,
              },
            ],
          },
          {
            title: "activities",
            path: ROUTES_PATH.contacts.activities,
            element: <ActivitiesTab />,
            hidden: true,
            collapseWhenIn: true,
          },
          {
            path: "*",
            element: (
              <Navigate
                replace
                to={ROUTES_PATH.contacts.communications.index}
              />
            ),
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: "opportunities",
    path: ROUTES_PATH.opportunities.index,
    icon: <ViewColumn />,
    children: [
      {
        element: <LeadsKanbanView />,
        index: true,
      },
      {
        path: ROUTES_PATH.opportunities.leads.detail,
        portalId: "leadName",
        element: <LeadDetailPageWrapper />,
        hidden: true,
        blockBreadcrumb: true,
        children: [
          {
            title: "communications",
            path: ROUTES_PATH.opportunities.leads.communications,
            element: <CommunicationsTab />,
            hidden: true,
            collapseWhenIn: true,
            children: [
              {
                title: "call",
                path: ROUTES_PATH.contacts.communications.calls,
                element: <CallTab />,
              },
              {
                title: "whatsapp",
                path: ROUTES_PATH.contacts.communications.whatsapp,
                element: <WhatsappMessageTab />,
              },
              {
                title: "emails",
                path: ROUTES_PATH.contacts.communications.emails,
                element: <EmailTab />,
              },
              {
                path: "*",
                element: (
                  <Navigate
                    replace
                    to={ROUTES_PATH.contacts.communications.whatsapp}
                  />
                ),
                hidden: true,
              },
            ],
          },
          {
            title: "activities",
            path: ROUTES_PATH.opportunities.leads.activities,
            element: <ActivitiesTab />,
            hidden: true,
            collapseWhenIn: true,
          },
          {
            path: "*",
            element: (
              <Navigate
                replace
                to={ROUTES_PATH.contacts.communications.index}
              />
            ),
            hidden: true,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate replace to={ROUTES_PATH.opportunities.index} />,
        hidden: true,
      },
    ],
  },
  {
    title: "templates",
    path: ROUTES_PATH.templates.index,
    icon: <LibraryBooks />,
    element: <TemplatePage />,
    children: [
      {
        path: ROUTES_PATH.templates.whatsapp,
        title: "whatsapp",
        hidden: true,
        children: [
          {
            index: true,
            element: <TemplateMBPage />,
          },
          {
            path: ROUTES_PATH.templates.new,
            title: "new",
            element: <TemplateMBGeneratorPage mode="create" />,
          },
          {
            path: ROUTES_PATH.templates.customized,
            title: "customized",
            element: <TemplateMBGeneratorPage mode={"customized"} />,
          },
          {
            path: ROUTES_PATH.templates.detail,
            title: "detail",
            element: <TemplateMBGeneratorPage mode="view" />,
          },
        ],
      },
      {
        path: ROUTES_PATH.templates.email,
        element: <TemplateEmailPage />,
        title: "emails",
        hidden: true,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate replace to={ROUTES_PATH.templates.whatsapp} />,
      },
    ],
  },

  {
    path: ROUTES_PATH.inbox.index,
    icon: <Send />,
    title: "inbox",
    children: [
      {
        element: <InboxPage />,
        index: true,
      },
    ],
  },
  {
    title: "reports",
    path: ROUTES_PATH.reports.index,
    icon: <BarChart />,
    element: <ReportPage />,
    children: [
      {
        path: ROUTES_PATH.reports.calls,
        element: <CallsReportPage />,
        title: "calls",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.emails,
        element: <EmailsReportPage />,
        title: "emails",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.leads,
        element: <LeadsReportPage />,
        title: "leads",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.whatsapp,
        element: <WhatsAppsReportPage />,
        title: "whatsapp",
        hidden: true,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate replace to={ROUTES_PATH.reports.calls} />,
      },
    ],
  },
  {
    title: "calendar",
    path: ROUTES_PATH.calendar.index,
    icon: <CalendarMonthRounded />,
    element: <CalendarGooglePage />,
    hidden: process.env.REACT_APP_ENVIRONMENT === "production",
  },
  {
    title: "blast",
    path: ROUTES_PATH.blast.index,
    icon: <FlashOnRounded />,
    element: <BlastPage />,
    children: [
      {
        path: ROUTES_PATH.blast.emails,
        element: <BlastEmailsHistory />,
        title: "emails",
        hidden: true,
      },
      {
        path: ROUTES_PATH.blast.whatsapp,
        element: <BlastWhatsappHistory />,
        title: "whatsapp",
        hidden: true,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate replace to={ROUTES_PATH.blast.whatsapp} />,
      },
    ],
  },
  {
    title: "Billing",
    path: ROUTES_PATH.billing.index,
    icon: <AttachMoney />,
    element: <BillingPage />,
  },
  {
    title: "facebook_ads_manager",
    hidden: true,
    path: ROUTES_PATH.facebook.index,
    icon: <Facebook />,
    children: [
      {
        element: <FacebookAdsManagerPage />,
        index: true,
      },
      {
        path: ROUTES_PATH.facebook.createCampaign,
        title: "create_ads_campaign",
        element: <CreateAdsCampaign />,
        hidden: true,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate replace to={ROUTES_PATH.contacts.index} />,
    hidden: true,
  },
];
