import { PaletteMode } from "@mui/material";
import { blueGrey, grey, red } from "@mui/material/colors";
import { unstable_createMuiStrictModeTheme as createTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";

const LightTheme = (mode) => {
  const background =
    mode === "dark"
      ? {
          paper: "#011e3c",
          default: "#173352",
        }
      : {
          paper: "#fff",
          default: "#F7F5F7",
        };
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiBadge: {
        styleOverrides: { badge: { height: 16, minWidth: 16, fontSize: 10 } },
      },
      MuiDialog: {
        styleOverrides: {
          paperFullWidth: {
            "@media (max-width: 600px)": {
              margin: 16,
              maxHeight: "calc(100% - 32px)",
              maxWidth: "calc(100% - 32px)",
              width: "calc(100% - 32px)",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "@media (max-width: 600px)": {
              minHeight: 40,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "@media (max-width: 600px)": {
              padding: "0px 8px",
              minHeight: 40,
            },
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: { padding: 4 },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionStart: {
            marginLeft: 8,
            marginRight: 0,
          },
          positionEnd: {
            marginLeft: 0,
            marginRight: 12,
          },
        },
      },
      MuiTooltip: {
        defaultProps: { arrow: true },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: "small",
        },
      },
      MuiTypography: {
        defaultProps: {
          color: "textPrimary",
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: { height: 1 },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 0,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: { title: "button" },
        styleOverrides: {
          root: {
            "& @media (max-width: 640px) .MuiTab-root": {
              padding: "0px 8px",
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: "primary",
        },
        styleOverrides: {
          root: {
            borderRadius: 4,
            fontSize: 14,
            "@media (max-width: 600px)": {
              display: "flex",
              gap: 2,
              minWidth: "unset",
            },
          },
          contained: {},
          text: {},
          sizeLarge: {
            minHeight: 48,
            minWidth: 48,
          },
          sizeMedium: {
            minHeight: 40,
            minWidth: 40,
          },
          sizeSmall: {
            height: 32,
            minWidth: 32,
          },
          outlined: {
            background: background.paper,
          },
          startIcon: {
            "@media (max-width: 640px)": {
              margin: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          sizeLarge: {
            minHeight: 40,
          },
          sizeMedium: {
            minHeight: 32,
          },
          sizeSmall: {
            height: 28,
            width: 28,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: { height: 24 },
          icon: {
            right: 8,
          },
        },
      },
      MuiToolbar: {
        defaultProps: {
          style: {
            backgroundColor: "inherit",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // borderRadius: 8,
            backgroundImage: "none",
          },
        },
      },
      MuiFormLabel: {
        defaultProps: { sx: { color: "text.primary" } },
        styleOverrides: {
          root: {
            fontSize: 12,
            paddingBottom: 8,
            lineHeight: 1,
            margin: 0,
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          root: {
            paddingBottom: 8,
            "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
              transform: "unset",
              position: "relative",
            },
            "&.MuiInputLabel-shrink": {
              transform: "unset",
              position: "relative",
            },
          },
          asterisk: {
            color: red[600],
            "&::before": {
              color: blueGrey[400],
              content: '" ("',
            },
            "&::after": {
              color: blueGrey[400],
              content: '" )"',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: { height: 8 },
          contained: { marginLeft: 0 },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { padding: 0 },
          multiline: { padding: 0 },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              padding: "4px 8px",
            },
            "& .MuiInputBase-readOnly": {
              background: grey[200],
              cursor: "not-allowed",
            },
          },
          inputRoot: {
            padding: 0,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-readOnly": {
              background: mode === "dark" ? grey[800] : grey[200],
              cursor: "not-allowed",
            },
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          sx: { backgroundColor: "background.paper" },
        },
        styleOverrides: {
          input: {
            minHeight: 24,
          },
          root: {
            borderRadius: 4,
            fontSize: 14,
            padding: 0,
            "& .MuiInputBase-input": { padding: "8px 12px" },
            "& .MuiAutocomplete-input": { padding: "8px 12px", height: 32 },
            "& fieldset": {
              top: 0,
              "& legend": {
                display: "none",
              },
            },
            "& .MuiAutocomplete-popper.MuiAutocomplete-paper": {
              padding: 0,
            },
          },
        },
      },
    },
    typography: {
      htmlFontSize: 14,
      fontSize: 14,
      button: { textTransform: "none" },
      fontFamily: "Inter",
      h1: { fontSize: 60, lineHeight: "72px", fontWeight: 600 },
      h2: { fontSize: 48, lineHeight: "56px", fontWeight: 600 },
      h3: { fontSize: 32, lineHeight: "44px", fontWeight: 600 },
      h4: { fontSize: 24, lineHeight: "34px", fontWeight: 600 },
      h5: { fontSize: 20, lineHeight: "30px", fontWeight: 600 },
      h6: { fontSize: 16, lineHeight: "24px", fontWeight: 600 },
      subtitle1: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 600,
      },
      body1: {
        fontSize: "14px",
        lineHeight: "22px",
      },
      body2: {
        fontSize: "12px",
        lineHeight: "18px",
      },
      caption: {
        fontSize: "10px",
        lineHeight: "14px",
      },
      fontWeightBold: 600,
    },
    shadows: [
      "none",
      "2px 4px 10px rgba(0, 0, 0, 0.05)",
      "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px",
      "0px 10px 16px rgba(0, 0, 0, 0.1)",
      "0px 16px 22px rgba(0, 0, 0, 0.08)",
      "0px 28px 32px rgba(0, 0, 0, 0.08)",
      "0px 32px 64px rgba(0, 0, 0, 0.08)",
      ...shadows.slice(7),
    ] as any,
    palette: {
      mode: mode,
      text:
        mode === "dark"
          ? {
              primary: "#fff",
              secondary: "#888993",
              disabled: "rgba(255, 255, 255, 0.5)",
            }
          : {
              primary: "#16191D",
              secondary: "#777F88",
            },
      background: background,
      primary: {
        dark: "#6536BF",
        main: "#834ADF",
        light: "#A775EB",
        900: "#230E6B",
        800: "#331781",
        700: "#4A25A0",
        600: "#6536BF",
        500: "#834ADF",
        400: "#A775EB",
        300: "#C093F5",
        200: "#DAB8FB",
        100: "#EDDBFD",
        50: "#F3E7FE",
      },
      secondary: {
        dark: "#D9A32C",
        main: "#FDC73D",
        light: "#FDD96D",
        900: "#794D0B",
        800: "#926313",
        700: "#B6821E",
        600: "#B6821E",
        500: "#FDC73D",
        400: "#FDD96D",
        300: "#FEE48A",
        200: "#FEEFB1",
        100: "#FEF8D8",
        50: "#FEFBE6",
      },
      grey: {
        900: "#16191D",
        800: "#2E3338",
        700: "#464C53",
        600: "#5E656E",
        500: "#777F88",
        400: "#93999F",
        300: "#AFB2B6",
        200: "#CACCCE",
        100: "#E5E5E6",
        50: "#F2F2F3",
      },
      success: {
        dark: "#027A48",
        main: "#7DE021",
        700: "#027A48",
        500: "#7DE021",
        400: "#A5EC55",
        300: "#C1F577",
        200: "#DCFBA5",
        100: "#EFFDD2",
        50: "#ECFDF3",
      },
      info: {
        main: "#00ADED",
        400: "#3DCFF4",
        300: "#63E5F9",
        200: "#97F6FD",
        100: "#CBFEFD",
      },
      error: {
        main: "#FC4C46",
        400: "#FD8474",
        300: "#FEA68F",
        200: "#FECAB5",
        100: "#FEE7DA",
      },
    },
  });
};

export const MUITheme = (mode?: PaletteMode) => {
  return LightTheme(mode);
};
