import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useMatch, useRoutes } from "react-router";
import { NavLink, useResolvedPath } from "react-router-dom";
import { IRouteObject } from "../../common/utils";
import { setOpenAsideBar } from "../../redux/cacheReducer";
import { AppState } from "../../redux/store";
import { PROJECT_LIST_ROUTES } from "../routes";

interface Props {
  hidden?: boolean;
}

function BreadcrumbsItem(props) {
  const {
    title,
    disabled,
    portalId = "",
    hidden,
    collapseWhenIn,
    path,
    breadcrumb,
  } = props;
  const breadcrumbText = useSelector(
    (state: AppState) => state.cache.breadcrumb
  );
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const resolvedLocation = useResolvedPath("");
  const matchRoutes = useMatch(path) as any;
  const isActive = !!matchRoutes;

  React.useEffect(() => {
    if (isActive) {
      document.title =
        intl.formatMessage({ id: "gogo" }) +
        (breadcrumbText?.[portalId]
          ? " | " + breadcrumbText?.[portalId]
          : title
          ? " | " + intl.formatMessage({ id: title })
          : "");
      if (isActive && collapseWhenIn) {
        dispatch(setOpenAsideBar(false));
      }
    }
  }, [
    breadcrumbText,
    collapseWhenIn,
    dispatch,
    intl,
    isActive,
    location.pathname,
    matchRoutes,
    path,
    portalId,
    resolvedLocation.pathname,
    title,
  ]);

  if (breadcrumb) {
    if (isActive)
      return (
        <>
          {breadcrumb}
          <Outlet />
        </>
      );
    return (
      <>
        {breadcrumb} &nbsp;
        <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />
        &nbsp;
        <Outlet />
      </>
    );
  }
  if (breadcrumbText)
    if (hidden) {
      return <Outlet />;
    }

  if (portalId) {
    return (
      <>
        {isActive ? (
          <Typography variant="subtitle2" color="textSecondary">
            {breadcrumbText[portalId] ||
              (title ? (
                <FormattedMessage id={title} />
              ) : (
                <Skeleton width={70} />
              ))}
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle2" color="textSecondary">
              {breadcrumbText[portalId] ||
                (title ? (
                  <FormattedMessage id={title} />
                ) : (
                  <Skeleton width={70} />
                ))}
            </Typography>
            &nbsp;
            <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />
            &nbsp;
            <Outlet />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {isActive ? (
        <Typography variant="subtitle2" color="textSecondary">
          {title && <FormattedMessage id={title} />}
        </Typography>
      ) : (
        <>
          {disabled ? (
            <Typography variant="subtitle2" color="textSecondary">
              {title && <FormattedMessage id={title} />}
            </Typography>
          ) : (
            <NavLink to={path || "/"}>
              <Typography variant="subtitle2">
                {title && <FormattedMessage id={title} />}
              </Typography>
            </NavLink>
          )}
          &nbsp;
          <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />
          &nbsp;
        </>
      )}
      <Outlet />
    </>
  );
}

const DefaultBreadcrumb: React.FunctionComponent<Props> = (props) => {
  const { hidden } = props;

  const mappedRoutes = (list: IRouteObject[], parent?: IRouteObject) => {
    return list.map((one) => {
      let absolutePath = one.index
        ? parent?.absolutePath?.replace("/*", "")
        : (parent?.absolutePath?.replace("/*", "") || "") +
          "/" +
          one?.path?.replace("/*", "");

      if (one.blockBreadcrumb) {
        absolutePath = absolutePath + "/*";
      }

      return {
        ...one,
        path: one.index ? parent?.path : one?.path,
        element: (
          <BreadcrumbsItem
            title={one.title}
            portalId={one.portalId}
            disabled={
              (one?.children && one?.children?.length === 0) ||
              !one.children?.find((v) => v.index)
            }
            hidden={hidden}
            collapseWhenIn={one.collapseWhenIn}
            path={absolutePath}
            breadcrumb={one.breadcrumb}
          />
        ),
        children:
          one.children &&
          !one.blockBreadcrumb &&
          mappedRoutes(one.children, {
            ...one,
            absolutePath,
          }),
      };
    });
  };

  const breadcrumbsElements = useRoutes(mappedRoutes(PROJECT_LIST_ROUTES));

  if (hidden) {
    return breadcrumbsElements;
  }

  return (
    <Box display="flex" alignItems="center" sx={{ color: "text.primary" }}>
      <>
        <NavLink
          to={"/"}
          style={{ color: "inherit" }}
          aria-label="Link to HomePage"
        >
          <HomeRoundedIcon />
        </NavLink>
        &nbsp;
        <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />
        &nbsp;
        {breadcrumbsElements}
      </>
    </Box>
  );
};

export default React.memo(DefaultBreadcrumb, () => false);
